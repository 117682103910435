import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDeleteForever } from "react-icons/md";
import RegisterMd from "./registermandd";
import UserProfile from "./userProfile";
import { Pagination, Form, Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { FaRegUserCircle } from "react-icons/fa";

function Deliveryagentdetails() {
  const [Deliveryagentdetails, setDeliveryagentdetails] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [userProfileDetails, setUserProfileDetails] = useState("");
  const [searchResults, setSearchResults] = useState([]);
const [searchQuery, setSearchQuery] = useState("");
const [isSearchPerformed, setIsSearchPerformed] = useState(false);
const [currentPage, setCurrentPage] = useState(0);
const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();

 
  const getData = async (pageNumber = 0) => {
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
  
      const trans = await axios.get(`/api/accounts/staff/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`, {
        params: { page: pageNumber }, // Pass page number as a query parameter
        headers: { Authorization: `Bearer ${token}` },
      });
  
      console.log(trans);
  
      setDeliveryagentdetails(trans.data.content);
      setCurrentPage(trans.data.number);
      setTotalPages(trans.data.totalPages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const handlePageChange = (pageNumber) => {
    getData(pageNumber);
  };
  function onClickhandler(e, id, disabled) {
    // setLoading(id);
    setLoading(true)
    const checked = e.target.checked;
   console.log(checked);
    const token = JSON.parse(localStorage.getItem("data")).token;
   
    console.log(token);
    axios.post(`/api/accounts/update`, {
      id: id,
      disabled: disabled
    }, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false);
        toast.success(" status updated");
      })
      .catch((error)=>{
        setLoading(false);

        handleError(error);
      })
  }
  const handleregistermd = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();

  };

  const handleSearch = async (e) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
  
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
  
      let searchResultsData = [];

      if (searchQuery.includes("@")) {
        const body = {
          orgid: orgcode,
          username: searchQuery,
        };

        const resByEmail = await axios.post('/api/accounts/staff/search-by-username', body, {
          headers: headers,
        });

        searchResultsData = resByEmail.data;
      } else {
        const body = {
          orgid: orgcode,
          name: searchQuery,
        };

        const resByName = await axios.post('/api/accounts/staff/search-by-name', body, {
          headers: headers,
        });

        searchResultsData = resByName.data;
      }

      setSearchResults(searchResultsData);
      setIsSearchPerformed(true);
    } catch (error) {
      console.error("Error during search:", error);
      handleError(error);
    }
  };

  

  const openUserProfileModal = async (userid) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try{
    const bio = await axios.get(
      `/api/account-bio/userid=${userid}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setUserProfileDetails(bio.data);
    navigate(`/users/userProfile/${userid}`);
  }catch(error){
    handleError(error);
  }

  };

 

  const handleDeleteAccount = async (id) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      await axios.delete(`/api/accounts/delete/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      toast.success("Account Deleted successfully");
      getData();
    } catch (error) {
      handleError(error);
    }
  };

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } 
    else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

 
  
 
  return (
    <div>
      <div>
        <Dashboard />
      </div>
      <div>
        <div className="col-13  mx-xl-auto ps-xl-10">
          <div className="card card-raised " style={{marginTop:"10%"}}>
            <div className="card-body " >
              <div className="card-title">Staff Details
              <button className="btn btn-primary float-end" onClick={handleregistermd}>
               ADD
            </button>
            </div>
            <div className="row">
      <div style={{ position: "relative", display: "flex", alignItems: "center",  left:"380px" }}>
 
             
 
        <input type="text" 
       // value={searchTruckNumber} 
        placeholder="Search By Name or Email"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        />
  
        <button
        onClick={handleSearch}
           //className="btn btn-primary" style={{ border:"2px solid #6200ea",background: "#6200ea", borderRadius:"5px", width:"50px"}}
        ><search><FaSearch 
       // style={{background:"#6200ea", color:"white"}}
        /></search>
        </button>
       
       
      </div>
      </div>
            <br/>
            <Row>
            {Deliveryagentdetails.map((accd) => (
              
            <Col key={accd.id} md={4} className="mb-3">
<div className="card card-raised h-100" onClick={() => openUserProfileModal(accd.id)} style={{cursor:"pointer"}} title="View Profile">                 <div className="card-body">
<Card>
                <Card.Body>
                <Card.Text className="d-flex justify-content-end">
                          {loading == accd.id && (
                            <div
                              className="loader"
                              style={{
                                position: "relative",
                                left: "55px",
                                top: "8px",
                              }}
                            ></div>
                          )}{" "}
                          <input
                            className="form-check-input"
                            id="flexSwitchCheckDefault"
                            type="checkbox"
                            defaultChecked={!accd.disabled}
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              onClickhandler(e, accd.id, !accd.disabled);
                            }}
                            title="Update Status"
                            value={accd.id}
                          />
                         
                    <MdDeleteForever
                      style={{ fontSize: "20px", cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteAccount(accd.id);
                      }}
                      title="Delete Account"
                    />
                                           </Card.Text>
                {/* <Card.Text>Id: {accd.id}</Card.Text> */}
             
                <div className="card-title text-center">
        {accd.bio && accd.bio.image ? (
          <img 
            src={`${accd.bio.imageMeta},${accd.bio.image}`} 
            alt="User Profile" 
            style={{ width: '100px', height: '100px', borderRadius: '50%' }} 
          />
        ) : (
          <FaRegUserCircle />
        )}
      </div>
                   <div className="card-title text-center">
 
                <Card.Text>{accd.name}</Card.Text>
                </div>
                  <br/>
                  <Card.Text>Email: {accd.username}</Card.Text>
                  <Card.Text>Contact: {accd.phNo}</Card.Text>
                  {/* <button className="btn btn-link" onClick={() => openUserProfileModal(accd.id)}>
                      View Profile
                    </button> */}
                  <Card.Text className="card-footer bg-light">{accd.role}</Card.Text>
                 
               
                </Card.Body>
              </Card>
              </div>
              </div>
             
              </Col>
             
          ))}
                </Row>
                <div className="d-flex justify-content-center">
  <Pagination>
    {[...Array(totalPages).keys()].map((number) => (
      <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
        {number + 1}
      </Pagination.Item>
    ))}
  </Pagination>
</div>
            </div>
          </div>
          <br />
          <RegisterMd isOpen={isModalOpen} onRequestClose={handleCloseModal} />
        </div>
      </div>
      {loading && <div className="loading"></div>}
    </div>
  );
}

export default Deliveryagentdetails;
