import React, { useEffect, useState, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import moment from "moment";
import ManagerSidebar from "./managerSidebar";
import { useNavigate, useParams } from "react-router-dom";

import "./Spinner.css";
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}
function UpdateExceptionOpenstock(props) {
  const date = new Date();

  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const searchDate = props.params.searchDate
  

  const [openstock, setOpenstock] = useState({
    date: searchDate,

    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    prodDo: [{ pid: "", qty: "", status: "", productname:"" }],
  });
  const [openingstock, setOpeningstock] = useState({
    date: currentDate,

    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    prodDo: [{ pid: "", qty: "", status: "",productname:""}],
  });
  const [product, setProduct] = useState([]);

  const [productstate, setProductstatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [quantity, setQuantity] = useState([
    {
      quantity1: "",
      quantity2: "",
      quantity3: "",
    },
  ]);
  
  const navigate = useNavigate();
  const url = "/api/Openingstock/update";
  const getData = async () => {
    const date = new Date();

    let day = date.getDate();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${day}-${month}-${year}`;
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    const trans = await axios.get(
      `/api/product/productstatus=true/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(trans);
    setProduct(trans.data);
    setLoader(false);
    if (trans.data.length === 0) {
      console.log("No products found");
      toast.error("Please add products before updating stock");
    }

    const ps = await axios.get("/api/entitytype", {
      headers: { Authorization: `Bearer ${token}` },
    });

    console.log(ps);

    setProductstatus(ps.data);



  
    
    const proddo = [];
    trans.data.forEach((element) => {
      const item = { pid: element.productcode, productname: element.productname };
      ps.data.forEach((psitem) => {
        item[psitem.producttype] = 0;
      });
      proddo.push(item);
    });
    setOpenstock({
      ...openstock,
      // prodDo: trans.data.map((trans1) =>
      //   ps.data.map((ps1) => ({
      //     pid: trans1.id,
      //     qty: 0,
      //     status: ps1.producttype,
      //   }))
      prodDo: proddo,
    });

    const trans1 = await axios.get("/api/Openingstock", {
      headers: { Authorization: `Bearer ${token}` },
    });

    console.log(trans1);

    setOpeningstock(trans1.data);
  };

  useEffect(() => {
    getData();

    

  }, []);
  
  function onClickUpdate(e, id) {
    e.preventDefault();
    console.log("openstock", openstock);
    const token = JSON.parse(localStorage.getItem("data")).token;
  
    const newpro = [];
    openstock.prodDo.forEach((element) => {
      productstate.forEach((psitem) => {
        const item = {
          pid: element.pid,
          status: psitem.producttype,
          qty: parseInt(element[psitem.producttype]),
          productname:element.productname
        };

        newpro.push(item);
      });
    });

    const reqbody = {
      datetime: openstock.date,
      //  moment(openstock.date).format("YYYY-MM-DD"),

      orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
      prodDo: newpro,
    };
    axios
      .post(
        "/api/Openingstock/update",
        reqbody,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        toast.success("openingstock updated successfully");
      
      const exceptionData = {
        expdate: openstock.date,
        orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
      };

      axios
        .post("/api/exceptiondate/create", exceptionData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log(res.data);
          navigate("/users/stock");
        })
        .catch((error) => {
          handleError(error);
        });
    })
    .catch((error) => {
     handleError(error);
    });
}

  function rhandle(e, field, index) {
    let actualValue = e.target.value;
    if (e.key === 'Backspace' || e.key === 'Delete') {
      actualValue = '0';
    } else if (actualValue.trim() === '') {
      actualValue = '0'; 
    }

    const newproddo = [...openstock.prodDo];
    newproddo[index] = { ...openstock.prodDo[index], [field]: actualValue };
    const newos = { ...openstock, prodDo: newproddo };
    if (field == "date") {
      newos.date = actualValue;
    }
    setOpenstock(newos);
  }

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }


  return (
    <div>
      <div>
        <AdminDashboard />
      </div>{" "}
     
      <br /> 
      <div
        style={{ position: "relative", top: "100px", left: "150px" }}
        className="col-8  mx-xl-auto ps-xl-6"
      >
        <div className="card card-raised ">
          <div className="card-body ">
            <div className="d-flex">
              <div className="card-title">OpenStock Update</div>
              {loader && <div className="loading"></div>} 
            </div>
            <br />
            <div>
                      <label for="date">Date : </label>
                      <input
                        id="date"
                        type="text"
                        value={openstock.date}
                        //onChange={(e) => rhandle(e, "date")}
                      />{" "}
                      </div>
            <br />
            <br /> 
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th id="date">Product</th>

                  <th id="full">Full</th>

                  <th id="empty">Empty</th>
                  <th id="empty">Defective</th>
                </tr>{" "}
              </thead>
              <tbody>
                {openstock.prodDo.map((products, index) => {
                  return (
                    <tr>
                      <td>{products.productname}</td>

                      <td key={index}>
                        <input
                          id="quantity1"
                          type="text"
                          placeholder="0"
                         // value={products.full}
                          style={{ border: "none" }}
                          onChange={(e) => rhandle(e, "full", index)}
                        />
                      </td>
                      <td key={index}>
                        <input
                          id="quantity2"
                          type="text"
                          placeholder="0"
                         // value={products.empty}
                          style={{ border: "none" }}
                          onChange={(e) => rhandle(e, "empty", index)}
                        />
                      </td>
                      <td key={index}>
                        <input
                          id="quantity3"
                          type="text"
                          placeholder="0"
                          //value={products.defective}
                          style={{ border: "none" }}
                          onChange={(e) => rhandle(e, "defective", index)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <button
              className="btn btn-primary"
              type="submit"
              onClick={(e) => onClickUpdate(e, product.id)}
            >
              update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(UpdateExceptionOpenstock);
