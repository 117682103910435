import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import { BiEditAlt } from "react-icons/bi";
import "./Spinner.css";
import TeritorydetailsModal from "./Addteritory";
import SalesareadetailsModal from "./Createsalesarea";
import Salesareacomponent from "./Salesarea";

function TeritoryComponent() {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [issalesModalOpen, setIssalesModalOpen] = useState(false);

;   const [product1, setProduct1] = useState([]);

  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [selectedTerritoryId, setSelectedTerritoryId] = useState(null); // State to store selected territory ID

  const navigate = useNavigate();
 

  

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);

    try {
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const url = `/api/territory`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProduct([...response.data]);
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };
  const getData1= async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);

    try {
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const url = `/api/salesarea`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProduct1(response.data);
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
    }
    setLoader(false);
  };
  useEffect(() => {
    getData();
    getData1();
  }, [isModalOpen]);
 
 

  
 
 
  const handleAddProduct = () => {
    setIsModalOpen(true);
    setProduct([]);


  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleTerritoryClick = (Id) => {
    setSelectedTerritoryId(Id);
    navigate(`/users/Salesarea/${Id}`);
  };
  

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />
      
      {loader && <div className="loading"></div>} 
      <div className="col-12  mx-xl-auto ps-xl-10 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title form-group d-flex align-items-center justify-content-between">Teritory Details
             
              <button className="btn btn-primary"onClick={handleAddProduct}>
               ADD
            </button>
            </div>
  
            <br />
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >

                  <th>Teritory Name</th>
                 

                </tr>
              </thead>
              <tbody>
              {product.length === 0 ? (
            <tr>
              <td colSpan="8" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (

                product.map((product) => {
                  return (
                    <tr key={product.id}>
 <td
                          onClick={() => handleTerritoryClick(product.id)}
                          style={{ cursor: "pointer" }}
                        >
                          {product.territoryname}
                        </td>
                     
                     
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>
           
            <div>
           
           <TeritorydetailsModal isOpen={isModalOpen} onRequestClose={handleCloseModal} />
         

      </div>


          </div>
        </div>{" "}
      </div>
      <br/>
      
    </div>   
  );
}

export default TeritoryComponent;
