import React, { useState, useEffect, useReducer } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";

import { Link, useNavigate } from "react-router-dom";
import "./Spinner.css";
  
    const AddExemptedcustomer= ({ isOpen, onRequestClose }) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const date = new Date();
    let day = date.getDate().toString().padStart(2, "0");
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${year}-${month}-${day}`;
    <div className="card card-raised mb-5"></div>;
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
  
    const [exemptedcustomercategory, setExemptedcustomercategory] = useState([]);
    const [exemptedcustomer, setExemptedcustomer] = useState([]);
  
    const [exemptedcustomercategory1, setexemptedcustomercategory1] = useState({
      customername: "",
      customercategory: "",
      deliverydate:currentDate,
      orgcode:JSON.parse(localStorage.getItem("data")).orgcode,
      distance: "",
      tariff: "",
      disable: false,
    });
  
    const navigate = useNavigate();
  
    const handleChange = (event, field) => {
      let actualValue = event.target.value;
      setexemptedcustomercategory1({
        ...exemptedcustomercategory1,
        [field]: actualValue,
      });
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      setLoading(true);
  
      axios
        .post("/api/exemptedcustomer/create", exemptedcustomercategory1, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          //    navigate("/users/Deviceusermapping");
          toast.success("Exempted Customer added Successfully");
          onRequestClose(); 
          setLoading(false);
          getData();
        })
        .catch((error) => {
          setLoading(false);
          handleError(error);
        });
    };
    const getData = async () => {
      try {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("data")).token;
    
        const transResponse = await axios.get(
          `/api/exemptedcustomercategories/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setExemptedcustomercategory(transResponse.data);
    
        const customerResponse = await axios.get("/api/exemptedcustomer", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setExemptedcustomer(customerResponse.data);
    
        setLoader(false);
      } catch (error) {
        handleError(error);
        setLoader(false);
      }
    };

    function handleError(error) {
      if (error.response && error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else {
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error('An error occurred.');
        }
      }
    }
  
    useEffect(() => {
      getData();
    }, []);
  
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Exempted Customer Creation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
            <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label for="customername ">Customer Name </label>
                      <input
                        type="text"
                        className="form-control"
                        id="customername"
                        value={exemptedcustomercategory1.customername}
                        onChange={(e) => handleChange(e, "customername")}
                      />
                    </div>

                    <div className="form-floating-sm mb-2">
                      <label for="customercategory ">Customer Category </label>
                      <select
                        id="customercategory"
                        type="text"
                        className="form-control"
                        placeholder="customercategory"
                        onChange={(e) => handleChange(e, "customercategory")}
                      >
                        <option>select customercategory</option>
                        {exemptedcustomercategory.map((ec) => {
                          return (
                            <option value={ec.categoryname}>
                              {ec.categoryname}
                            </option>
                          );
                        })}
                      </select>{" "}
                    </div>
                    <div className="form-floating-sm mb-2">
                      <label for="distance ">Distance </label>
                      <input
                        type="text"
                        className="form-control"
                        id="distance"
                        value={exemptedcustomercategory1.distance}
                        onChange={(e) => handleChange(e, "distance")}
                      />{" "}
                    </div>
                   
                    <div className="form-floating-sm mb-2">
                      <label for="tariff ">Tariff</label>
                      <input
                        type="text"
                        className="form-control"
                        id="tariff"
                        value={exemptedcustomercategory1.tariff}
                        onChange={(e) => handleChange(e, "tariff")}
                      />{" "}
                    </div>
                  </div>
                  <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}

                />
                 &nbsp;&nbsp;&nbsp;&nbsp;

                <Button variant="secondary" onClick={onRequestClose}>
               Close
               </Button>  

              </form>
                 
            </div>
          </div>
        </div>

      </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
};

export default AddExemptedcustomer;