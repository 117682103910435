import React, { useEffect, useState } from "react";
import { toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import "./Spinner.css";
import { useNavigate,useParams } from "react-router-dom";


function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}

function UpdateLoadout(props) {
  const date = new Date();

  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const navigate = useNavigate();

const searchDate = props.params.searchDate;

  const [transaction, setTransaction] = useState({
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    truckinfo: "",
    date: searchDate,
    status: "empty",
    approvalstatus: "",
    ervid:"",
    file: null,
    fileSize: "",
    drivername:"",
    contact:"",
    products: [
      {
        productcode: "",
        productname: "",
        quantity: "",
      },
    ],
  });
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vehicle, setVehicle] = useState([]);
  const [vehiclesLoadin, setVehiclesLoadin] = useState("");
  const url = "/api/loadout/create";

  const token = JSON.parse(localStorage.getItem("data")).token;
  const getData = async () => {
    try{
    const date = new Date();
    setLoading(true);
    let day = date.getDate();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${day}-${month}-${year}`;
    const token = JSON.parse(localStorage.getItem("data")).token;

    const trans = await axios.get(
      `/api/product/productstatus=true/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(trans);

    setProduct(trans.data);
    setTransaction({
      ...transaction,
      products: trans.data.map((item) => ({
        productcode: item.productcode,
        productname: item.productname,
        quantity: 0,
      })),
    });
    const vehicles = await axios.get(
      `/api/LoadinDo/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }/date=${searchDate}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    setVehicle(vehicles.data);
    setLoading(false);
  }catch(error){
    handleError(error);
  }
  };

  const handleVehicle = (vehicle) => {
 
    axios
   .get(
     `/api/LoadinDo/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${transaction.date}/truckinfo=${vehicle}
     `,
     {
       headers: { Authorization: `Bearer ${token}` },
     })
     .then((res) => {
       setVehiclesLoadin(res.data);

       const vehi = {
        ...transaction,
        truckinfo:vehicle,
        drivername: res.data.driverName,
        contact: res.data.contact,
      };
  
      setTransaction(vehi);
     });
     
   }

  useEffect(() => {
    getData();
  }, []);

  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);

    const token = JSON.parse(localStorage.getItem("data")).token;
    axios

      .post(url, transaction, { headers: { Authorization: `Bearer ${token}` } })

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Loadout updated successfully");
        navigate("/users/loaddetails");

      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  }
  function rhandle(e, field, index) {
    let actualValue = e.target.value;

    if (field == "quantity") {
      const newproduct = [...transaction.products];
      newproduct[index] = { ...newproduct[index], quantity: actualValue };

      setTransaction({ ...transaction, products: newproduct });
    } else {
      setTransaction({ ...transaction, [field]: actualValue });
    }
  }
  console.log(transaction);
  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {
        const [metadata, content] = reader.result.split(",");

        resolve({ metadata, content });
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }
  const handlePdfFileChange = async (e) => {
    const file = e.target.files[0];
    const convertedPdf = await getBase64FromFile(file);
    const pdfObject = {
      ...transaction,

      file: convertedPdf.content,
      fileSize: file.size,
      fileContentType:convertedPdf.metadata
    };

    setTransaction(pdfObject);
  };

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br /> <br /> <br /> <br /> <br />
      {loading && <div className="loading"></div>}
      <div
        className="col-8  mx-xl-auto px-xl-5 "
        style={{ position: "relative", left: "150px" }}
      >
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title">Load Outgoing Updates</div>
            <br />

            <div className="col-xl-12 mx-xl-auto ">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form>
                    <div>
                      <label for="date">Date:</label>
                      <input
                        id="date"
                        type="date"
                        placeholder="Date"
                        value={transaction.date}
                        onChange={(e) => rhandle(e, "date")}
                      />{" "}
                      &nbsp;&nbsp;&nbsp; &nbsp;
                      {/* <label for="truckinfo">Vehicle Id:</label>
                      <input
                        id="truckinfo"
                        type="text"
                        placeholder="Truck info"
                        value={transaction.truckinfo}
                        onChange={(e) => {
                          const value = e.target.value.toUpperCase(); // Convert value to uppercase
                          rhandle({ ...e, target: { ...e.target, value } }, "truckinfo");
                        }}
                      />{" "} */}
                       <label for="truckinfo">Vehicle Id:</label>
                      <select
                        id="truckinfo"
                        type="text"
                        placeholder="vehicle"
                        value={transaction.truckinfo}
                        onChange={(e) => {
                          const value = e.target.value.toUpperCase(); // Convert value to uppercase
                          rhandle({ ...e, target: { ...e.target, value } }, "truckinfo");
                          handleVehicle(e.target.value);
                        }}
                      >
                        <option>select vehicle</option>
                        {vehicle.map((vehicle) => {
                          return (
                            <option value={vehicle.truckinfo}>
                              {vehicle.truckinfo}
                            </option>
                          );
                        })}
                      </select>
                      &nbsp;&nbsp;&nbsp; &nbsp;
              
                       <label for="ervid">ERV Id:</label>
                       <input
                        id="ervid"
                        type="text"
                        placeholder="ERV Id"
                        value={transaction.ervid}
                        onChange={(e) => rhandle(e, "ervid")}
                      ></input>
                      
                       </div>
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;

                      <div className="row">
                      <div className="form-floating-sm mb-2 d-flex align-items-center">
                        <label htmlFor="file" className="me-1">Upload Document:</label>
                        <input
                          className="form-control smaller-input flex-grow-1"
                          id="file"
                          type="file"
                          placeholder="Upload document"
                          onChange={(e) => handlePdfFileChange(e)}
                        />
                        <label htmlFor="drivername" className="mx-3">Driver Name:</label>
                        <input
                          id="drivername"
                          type="text"
                          placeholder="Driver Name"
                          value={transaction.drivername}
                          onChange={(e) => rhandle(e, "drivername")}
                        />
                        <label htmlFor="contact" className="mx-3">Contact:</label>
                        <input
                          id="contact"
                          type="text"
                          placeholder="Contact Number"
                          value={transaction.contact}
                          onChange={(e) => {
                            const inputText = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                            const limitedText = inputText.slice(0, 10); // Limit to 10 characters
                            setTransaction({ ...transaction, contact: limitedText }); // Update the state with limited text
                          }}
                        />
                      </div>
                    </div>
                    <br />
                    <br />
                    <div>
                      <div>
                        <table className="table table-bordered ">
                          <thead>
                            <tr>
                              <th>Product Code</th>
                              <th>Product Name</th>
                              <th>Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transaction.products.map((product, index) => {
                              return (
                                <>
                                  <tr key={product.productcode}>
                                    <td>{product.productcode}</td>
                                    <td>{product.productname}</td>
                                    <td>
                                      <input
                                        id="id"
                                        type="text"
                                        placeholder="0"
                                        style={{ border: "none" }}
                                        onKeyPress={(e) => {
                                          // Allow only numeric characters
                                          const isValidChar = /^\d+$/.test(e.key);
                                          if (!isValidChar) {
                                            e.preventDefault();
                                          }
                                        }}
                                        onChange={(e) => rhandle(e, "quantity", index)}
                                      />
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>{" "}
                    </div>

                    <button
                      type="submit"
                      value="Save"
                      className="btn btn-primary"
                      onClick={(e) =>
                        rsubmit(e)
                      }
                    >
                      Save
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      {loading && <div className="loading"></div>}
    </div>
  );
}

export default withRouter(UpdateLoadout);
