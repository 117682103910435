import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./Loader.css";
import { BiEditAlt } from "react-icons/bi";
import "./Spinner.css";
import SQCeditModalPopup from "./Sqcedit";
import { MdDeleteForever } from "react-icons/md";
import Search from "./search";
import {FaSearch } from "react-icons/fa";



function SQCdetails({ onFreezeChange }) {
  const date = new Date();
  date.setDate(date.getDate() );
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProductEditModalOpen, setIsProductEditModalOpen] = useState(false);
  const [freeze, setFreeze] = useState(false);
  const [freezeStates, setFreezeStates] = useState(() => {
    const storedFreezeStates = localStorage.getItem("freezeStates");
    return storedFreezeStates ? JSON.parse(storedFreezeStates) : {};
  });
  const [message, setMessage] = useState("");
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchstate, setSearchstate] = useState(true);
  const [searchbydate, setSearchbydate] = useState([]);
  const [searchtruck, setSearchtruck] = useState([]);
  const [searchItem, setsearchItem] = useState([]);
  const [searchTruckNumber, setSearchTruckNumber] = useState(""); // Step 1
  const [searchInvoicekNumber, setSearchInvoiceNumber] = useState("");
  const [searchSqcNumber, setSearchSqcNumber] = useState("");
  const navigate = useNavigate();


 
  function searchName(text) {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchbydate(false);
    setLoader(true);
    axios
    .get(`/api/sqcform/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${text}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setProduct(res.data);
        setLoader(false);
      })
      
  }
  function searchByTruckNumber(truckNumber) {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchtruck(false);
    setLoader(true);
    axios
      .get(`/api/sqcform/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/trucknumber=${truckNumber}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setProduct(res.data);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error searching by truck number:", error);
        setLoader(false);
      });
  }
 
 
  function searchByInvoiceNumber(invoiceNumber) {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchInvoiceNumber(false);
    setLoader(true);
    axios
      .get(`/api/sqcform/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/invoicenumber=${invoiceNumber}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setProduct(res.data);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error searching by invoice number:", error);
        setLoader(false);
      });
  }
 
  function searchBySqcNumber(id) {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchSqcNumber(false);
    setLoader(true);
    axios
    .get(`api/sqcform/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const responseData = res.data; // Assuming response data is an object
        // Extracting values from the response and storing in an array
        const dataArray = [
          { id: responseData.id, invoiceNumber: responseData.invoiceNumber, truckNumber: responseData.truckNumber, date: responseData.date }
          // Add more properties if needed
        ];
        // Set the product state with the array containing fetched data
        setProduct(dataArray);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error searching by Sqc number:", error);
        toast.error(" No data on this Sqcno");
        setLoader(false);
      });
  }
  
  const onClickhandler = async (e, id, truckNumber, invoiceNumber) => {
    if (freezeStates[id]) return;
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(id);
    const vehicles = await axios({
      url: "/api/sqcform",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`,
        truckinfo: `${truckNumber}`,
        licenceNo: `${invoiceNumber}`,
        orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
      },
    }).then((res) => {
      toast.success("deleted successfully");
      getData();
      setLoading(null);
    });
  };

  

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);

    try {
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const url = `/api/sqcform/orgcode=${orgCode}/date=${currentDate}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProduct(response.data);
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching  SQC details");
    }
    setLoader(false);
  };
  
  useEffect(() => {

    getData();
  }, [isModalOpen]);
 
 


 
 
  const handleEdit = (productId) => {
    if (freezeStates[productId]) return;
    setSelectedProductId(productId);
    navigate(`/users/Sqcedit/${productId}`);  };

  const closeEditModal = () => {
    setIsProductEditModalOpen(false);
    getData(); 
  };
  
 
  
  const handleFreeze = async (id) => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.post(
        "/api/sqcform/freeze",
        { id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        setFreezeStates((prevStates) => ({
          ...prevStates,
          [id]: true,
        }));
        toast.success(" frozen successfully");
        localStorage.setItem("freezeStates", JSON.stringify({ ...freezeStates, [id]: true }));
        onFreezeChange(true);
      }
    } catch (error) {
      // toast.error("Error freezing product");
      console.error("Error freezing product:", error);
    }
  };
  
  function handlePrint(e, id) {
    navigate(`/users/printsqc/${id}`);
  }

 

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      
      <div className="row md-9">
        <div className="col-9 md-2">
      <div style={{ position: "relative", left: "290px", display: "flex", alignItems: "center" }}>
 
             
 
        <input type="text" value={searchTruckNumber} placeholder="Search Truck Number..."
         onChange={(e) => setSearchTruckNumber(e.target.value)} list="truckNumbers"  style={{ marginRight: '10px' }}></input>
         <datalist id="truckNumbers" >
          {product.map((item) => (
          <option key={item.truckNumber} value={item.truckNumber} />
        ))}
        </datalist>
        <button onClick={() => searchByTruckNumber(searchTruckNumber)}
           className="btn btn-primary ml-2" style={{ border:"2px solid #6200ea",background: "#6200ea", size: "150px", borderRadius:"5px", width:"50px"}}
        ><search><FaSearch style={{background:"#6200ea", color:"white"}}/></search></button>
       
 
 
       
     
        <input type="number" value={searchInvoicekNumber} placeholder="Search Invoice Number..."
        onChange={(e) => setSearchInvoiceNumber(e.target.value)} list="invoiceNumbers" style={{ marginRight: '10px', marginLeft: '10px' }}></input>
        <datalist id="invoiceNumbers">
  {product.map((item) => (
    <option key={item.invoiceNumber} value={item.invoiceNumber} />
  ))}
</datalist>
        <button onClick={() => searchByInvoiceNumber(searchInvoicekNumber)}
          className="btn btn-primary ml-2"  style={{ border: "2px solid #6200ea", background: "#6200ea", size: "150px", borderRadius:"5px", width:"50px"}}>
              <search><FaSearch style={{background:"#6200ea", color:"white"}}/></search></button>
 
       
           
        <input type="number" value={searchSqcNumber} placeholder="Search Sqc Number..."
        onChange={(e) => setSearchSqcNumber(e.target.value)} list="sqcNumbers" style={{ marginRight: '10px', marginLeft: '10px' }}></input>
        <datalist id="id">
        {product.map((item) => (
    <option key={item.id} value={item.id} />
  ))}
</datalist>
        <button onClick={() => searchBySqcNumber(searchSqcNumber)}
          className="btn btn-primary ml-2"  style={{ border: "2px solid #6200ea", background: "#6200ea", size: "150px", borderRadius:"5px", width:"50px" }}>
              <search><FaSearch style={{background:"#6200ea", color:"white"}}/></search></button>
      </div>
      <div style={{position: "relative",left:"1060px" , display: "flex",bottom:"30px" }}>
        <Search searchName={searchName} />
      </div>
      </div>
      </div>
 
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

      <div className="col-12  mx-xl-auto ps-xl-10 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="d-flex">
              <div className="card-title">SQC Details{""}
              {/* {loader && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )} */}
                   </div>
              {""}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


              <Link to="/users/addsqcnumber">
                    <button className="btn btn-primary"> ADD</button>
                  </Link>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                 
                 
             
            </div>
            <br />
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >
                  <th>SQC Number</th>
                  <th>Invoice Number</th>
                  <th>Truck Number</th>
                  <th>Date</th>
                  <th>Approval Status</th>

                  <th>Action</th>
                 
                </tr>
              </thead>
              <tbody>
     {searchbydate && product.length === 0 ? (
    <tr>
      <td colSpan="5" className="text-center">
        No Data Available
      </td>
    </tr>
  ) : (
    searchbydate &&
    product.map((searchItem) => { 
      return (  
      <tr key={searchItem.id}>
      <td>{searchItem.id}</td>
        <td>{searchItem.invoiceNumber}</td>
        <td>{searchItem.truckNumber}</td>
        <td>{searchItem.date}</td>
                <td>{searchItem.approvalStatus}</td>

        
        <td>
          <button
            onClick={() => handleEdit(searchItem.id)}
            style={{ border: "none", background: "white", size: "150px" }}
            disabled={freezeStates[searchItem.id]}
          >
            <BiEditAlt />
          </button>
          <button
            style={{
              border: "none",
              background: "white",
              color: "grey",
              position: "relative",
              left: "20px",
              fontSize: "30px",
            }}
            onClick={(e) => {
              onClickhandler(
                e,
                searchItem.id,
                searchItem.invoiceNumber,
                searchItem.truckNumber
              );
            }}
            disabled={freezeStates[searchItem.id]}
          >
            <MdDeleteForever />
          </button>{" "}
          {loading == searchItem.id && (
            <div
              className="loader"
              style={{ position: "relative", left: "40px", top: "8px" }}
            ></div>
          )}{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          
<button
  className="btn btn-primary"
  onClick={() => handleFreeze(searchItem.id)}
  style={{ backgroundColor: freezeStates[searchItem.id] ? "red" : "green" }}
  disabled={freezeStates[searchItem.id]}
>
  Freeze
</button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button   className="btn btn-primary"
                          onClick={(e) => handlePrint(e, searchItem.id)}
                         
                        >
                          Print
                        </button>

         
        </td>
      </tr>
   );
  }))}
   {!searchbydate && product.length === 0 ? (
            <tr>
              <td colSpan="4" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
   !searchbydate  &&  product.map((searchItem) => {
     return (
      <tr key={searchItem.id}>
        <td>{searchItem.id}</td>
        <td>{searchItem.invoiceNumber}</td>
        <td>{searchItem.truckNumber}</td>
        <td>{searchItem.date}</td>
        <td>{searchItem.approvalStatus}</td>

        <td>
          <button
            onClick={() => handleEdit(searchItem.id)}
            style={{ border: "none", background: "white", size: "150px" }}
            disabled={freezeStates[searchItem.id]}
          >
            <BiEditAlt />
          </button>
          <button
            style={{
              border: "none",
              background: "white",
              color: "grey",
              position: "relative",
              left: "20px",
              fontSize: "30px",
            }}
            onClick={(e) => {
              onClickhandler(
                e,
                searchItem.id,
                searchItem.invoiceNumber,
                searchItem.truckNumber
              );
            }}
            disabled={freezeStates[searchItem.id]}
          >
            <MdDeleteForever />
          </button>{" "}
          {loading == searchItem.id && (
            <div
              className="loader"
              style={{ position: "relative", left: "40px", top: "8px" }}
            ></div>
          )}{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button
  className="btn btn-primary"
  onClick={() => handleFreeze(searchItem.id)}
  style={{ backgroundColor: freezeStates[searchItem.id] ? "red" : "green" }}
  disabled={freezeStates[searchItem.id]}
>
  Freeze
</button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

          <button   className="btn btn-primary"
                          onClick={(e) => handlePrint(e, searchItem.id)}
                         
                        >
                           Print
                        </button>
                        
        </td>
      </tr>
);
}))}
</tbody>

            </table>
            <div>
            
      </div>


          </div>
        </div>{" "}
      </div>
      {isProductEditModalOpen && (
              <SQCeditModalPopup
                isOpen={isProductEditModalOpen}
                onRequestClose={closeEditModal}
                productId={selectedProductId}
              />
            )}
    </div>  
    
  );
}

export default SQCdetails;
