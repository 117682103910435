import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form';
import "./Spinner.css";
import AreamasterdetailsModal from "./Addareamaster";
import { FiInfo } from "react-icons/fi";
import AreaeditModalPopup from "./Updateareamaster";
import { Button } from 'react-bootstrap';
import AddFundsModal from "./Addfunds";
import PlansModal from "./plans";

function Wallet() {
  const [walletAmount, setWalletAmount] = useState(null);
  const [showAddFundsModal, setShowAddFundsModal] = useState(false);
  const [showPlansModal, setShowPlansModal] = useState(false);

  useEffect(() => {
    const fetchWalletAmount = async () => {
      try {
        const response = await axios.get('api/walletamount');
        setWalletAmount(response.data.amount); // Assuming the API returns { amount: number }
      } catch (error) {
        // toast.error('Failed to fetch wallet amount');
      }
    };

    fetchWalletAmount();
  }, []);

  const walletAmountBoxStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px', 
    border: '2px solid black',
    borderRadius: '5px',
    backgroundColor: '#f8f9fa',
    marginBottom: '1rem',
    maxWidth: '200px', 
    margin: '0 auto' 
  };

  const walletAmountStyle = {
    fontSize: '21px',
    fontWeight: 'bold',
    color: 'black'
  };

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />
      
      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between"> Wallet
              <Button variant="primary" onClick={() => setShowAddFundsModal(true)}>
                Add Funds
              </Button>
            </div>
            <br />
            <div style={walletAmountBoxStyle}>
              <div style={walletAmountStyle}>
                ₹ {walletAmount !== null ? walletAmount : 2000} 
              </div>
            </div>
            <Button variant="primary" onClick={() => setShowPlansModal(true)}>
              Plans
            </Button>
          </div>   
        </div>
      </div>

      {/* Add the AddFundsModal component */}
      <AddFundsModal
        show={showAddFundsModal}
        handleClose={() => setShowAddFundsModal(false)}
       
      />

      <PlansModal
        show={showPlansModal}
        handleClose={() => setShowPlansModal(false)}
      />
    </div>
  );
}

export default Wallet;
