import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import { Link } from "react-router-dom";
import axios from "../api/baseurl";
import Search from "./search";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./transaction.css";
import { useNavigate } from "react-router-dom";
import LoadIncoming from "./loadincoming";
import { MdDeleteForever } from "react-icons/md";
import { GrFormView } from "react-icons/gr";
import LoadOutgoing from "./Loadoutgoing";


function Loaddetails() {
  const [Loadin, setLoadin] = useState([]);
  const [Loadout, setLoadout] = useState([]);
  const [searchbydate, setSearchbydate] = useState(true);
  
  const [transactions1, setTransaction1] = useState([]);
  const [transactionInModalIsOpen, setTransactionInModalIsOpen] = useState(false);
const [loadinModalIsOpen, setloadinModalIsOpen] = useState(false);
const [loadoutModalIsOpen, setloadoutModalIsOpen] = useState(false);

const [selectedProductName, setSelectedProductName] = useState(null);
const [selectedVehicleId, setSelectedVehicleId] = useState(null);
const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

const [loadinDetails, setloadinDetails] = useState([]);
const [transactionInDetails, setTransactionInDetails] = useState([]);
const [ttinDate, setTtinDate] =useState(null);
const [selectedVehicleId1, setSelectedVehicleId1] = useState(null);
const [selectedervId, setSelectedervId] = useState(null);

const [loadoutDetails, setloadoutDetails] = useState([]);
const [ttoutDate, setTtoutDate] =useState(null);
const [loadinLoader, setLoadinLoader] = useState(false); // Loader for Load Incoming section
const [loadoutLoader, setLoadoutLoader] = useState(false); // Loader for Load Outgoing section

  const date = new Date();
  date.setDate(date.getDate() );
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [searchDate, setSearchDate]= useState(currentDate);
  const today = new Date();
  async function searchName(text) {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      setSearchbydate(false);
      setSearchDate(text);

      const productResponse = await axios.get(
        `/api/product/productstatus=true/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setProduct(productResponse.data);
  
      const loadInResponse = await axios.get(
        `/api/LoadinDo/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${text}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setLoadin(loadInResponse.data);

      const loadOutResponse = await axios.get(
        `/api/LoadOutDo/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${text}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setLoadout(loadOutResponse.data);
  
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
    setLoader(false);
  }
  const [transactions, setTransaction] = useState([]);
  const [loader, setLoader] = useState(false);

  const [product, setProduct] = useState([]);

  const url = "/api/loadout";

  const getData = async () => {
    const date = new Date();

    let day = date.getDate().toString().padStart(2, "0");
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${year}-${month}-${day}`;
    const token = JSON.parse(localStorage.getItem("data")).token;
    try{
    setLoader(true);

    const prod = await axios.get(
      `/api/product/productstatus=true/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    
      setProduct(prod.data);
   
    const trans1 = await axios.get(
      `/api/LoadinDo/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }/date=${currentDate}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    setTransaction1(trans1.data);

    const trans = await axios.get(
      `/api/LoadOutDo/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }/date=${currentDate}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    setTransaction(trans.data);
    setLoader(false);
  }catch(error){
    setLoader(false);
    handleError(error);
  }
  };

  const onClickdownloadLoadIn = async (e, id) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("data")).token;
    await axios({
      url: `/api/loadin/download/id=${id}`,
      method: "GET",
      responseType: "blob",
      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      console.log("filedata;", response);
      const contentType = response.headers["content-type"];
      let extension = "";
  
      // Determine the file extension based on content type
      if (contentType.includes("application/pdf")) {
        extension = "pdf";
      } else if (contentType.includes("image/jpeg")) {
        extension = "jpg";
      } else if (contentType.includes("image/png")) {
        extension = "png";
      } else {
        console.error("Unsupported file type:", contentType);
        return;
      }
  
      // Create a blob URL and download link for the file
      const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `file.${extension}`);
      document.body.appendChild(link);
      link.click();
  
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
  };

  const onClickdownloadLoadOut= async (e, id) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("data")).token;
    await axios({
      url: `/api/loadout/download/id=${id}`,
      method: "GET",
      responseType: "blob",
       headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      console.log("filedata;", response);
      const contentType = response.headers["content-type"];
      let extension = "";
  
      // Determine the file extension based on content type
      if (contentType.includes("application/pdf")) {
        extension = "pdf";
      } else if (contentType.includes("image/jpeg")) {
        extension = "jpg";
      } else if (contentType.includes("image/png")) {
        extension = "png";
      } else {
        console.error("Unsupported file type:", contentType);
        return;
      }
  
      // Create a blob URL and download link for the file
      const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `file.${extension}`);
      document.body.appendChild(link);
      link.click();
  
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    getData();
  }, []);
  const handleViewButtonClick = async (invoiceid) => {
    console.log("invoiceid:",invoiceid  );
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      setLoader(true);
  
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const url = `/api/sqcform/orgcode=${orgCode}/date=${currentDate}`;
      console.log("URL:", url); 
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log("API Response:", response.data); 
  
      setLoader(false);
  
      const invoiceNumber = response.data.map((item) => item.invoiceNumber);
      console.log("Invoice Numbers:", invoiceNumber); 
  
      if (invoiceNumber.includes(invoiceid.toString())) {
        navigate(`/users/addsqcnumber`);
      } else {
        navigate(`/users/sqcnumber`);
      }
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };
  
  const openLoadinModal = async (truckinfo, invoiceid, date) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
   setSelectedVehicleId(truckinfo);
   setSelectedInvoiceId(invoiceid);
   setTtinDate(date);
    const trans = await axios.get(
      `/api/loadin/orgcode=${ JSON.parse(localStorage.getItem("data")).orgcode}/date=${date}/truckinfo=${truckinfo}/invoiceid=${invoiceid}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(trans);

    setloadinDetails(trans.data);
    console.log( trans.data);
    // setTtinDate(trans.data.date);
    
    const pro = await axios.get(
      `/api/product/productstatus=true/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    setProduct(pro.data);

    setloadinModalIsOpen(true);
  };

  const closeloadinModal = () => {
    setloadinModalIsOpen(false);
    searchName(searchDate);
  };
  
  
  const openLoadoutModal = async (truckinfo, ervid, date) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
   setSelectedVehicleId1(truckinfo);
   setSelectedervId(ervid);
   setTtoutDate(date);
    const trans = await axios.get(
      `/api/loadout/orgcode=${ JSON.parse(localStorage.getItem("data")).orgcode}/date=${date}/truckinfo=${truckinfo}/ervid=${ervid}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(trans);

    setloadoutDetails(trans.data);
    console.log( trans.data);
    // setTtinDate(trans.data.date);
    
    const pro = await axios.get(
      `/api/product/productstatus=true/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    setProduct(pro.data);

    setloadoutModalIsOpen(true);
  };

  const closeloadoutModal = () => {
    setloadoutModalIsOpen(false);
    searchName(searchDate);
  };

  const navigate = useNavigate();
  function updateLoadin(e, searchDate) {
    navigate(`/users/updateloadin/${searchDate}`);
  }

  function updateLoadout(e, searchDate) {
    navigate(`/users/updateloadout/${searchDate}`);
  }
  const handleLoadinRefresh = async () => {
    setLoadinLoader(true); // Set Load Incoming loader to true
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

      const loadinResponse = await axios.get(
        `/api/LoadinDo/orgcode=${orgcode}/date=${searchDate}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setLoadin(loadinResponse.data);
    } catch (error) {
     setLoadin(false);
     handleError(error);
    }
    setLoadinLoader(false); // Set Load Incoming loader to false
  };

  const handleLoadoutRefresh = async () => {
    setLoadoutLoader(true); // Set Load Outgoing loader to true
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

      const loadoutResponse = await axios.get(
        `/api/LoadOutDo/orgcode=${orgcode}/date=${searchDate}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setLoadout(loadoutResponse.data);
    } catch (error) {
      setLoadoutLoader(false);
      handleError(error);
    }
    setLoadoutLoader(false); // Set Load Outgoing loader to false
  };


      function handleError(error) {
        if (error.response && error.response.status === 401) {
          navigate(`/signin`);
          toast.error("Your session has expired. Please login.");
        } else {
          if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          } else {
            toast.error('An error occurred.');
          }
        }
      }
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br /> <br /> <br />
      <div style={{ position: "relative", left: "260px" }}>
        <Search searchName={searchName} />{" "}
      </div>
      <br />
      {loadinLoader && <div className="loadin-loading"></div>}

      <div className="loadin" id="loadin">
        <div>
          <div className="col-12 mx-xl-auto ps-xl-10 ">
            <div className="card card-raised ">
              <div className="card-body ">
                <div className="d-flex">
                  <div
                    className="card-title"
                    style={{
                      fontSize: "20px",
                      // backgroundColor: "blue",
                    }}
                  >
                    Load Incoming Details

                    {loadinLoader && (
                  <div
                    className="loader"
                    style={{
                      position: "relative",
                      left: "20px",
                      top: "10px",
                    }}
                  ></div>
                )}
                  </div>
                  <div className="col-9 d-flex justify-content-end ps-xl-6">
                 
                    <button className="btn btn-primary" onClick={(e) => updateLoadin(e, searchDate)}>Create Invoice</button>
                    <span style={{ marginLeft: '5px' }}></span>
                    <button className="btn btn-primary" onClick={handleLoadinRefresh}>
        Refresh
      </button>

                </div>
                </div>
                <br />
                <table className="table table-bordered ">
                  <thead>
                    <tr
                      style={{
                        fontSize: "17px",
                        backgroundColor: " #6200ea",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      <th>Vehicle Id</th>
                      {product.map((prod) => {
                  return <th>{prod.productname}</th>;
                })}
                      <th>Invoice Id</th>

                      <th>Action</th>

                      <th>Document</th>


                    </tr>
                  </thead>
                  <tbody>
                  {searchbydate && transactions1.length === 0 ? (
            <tr>
              <td colSpan="10" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (searchbydate &&
                      transactions1.map((ttin) => {
                        return (
                          <tr className="text-center">
                            <td><div>{ttin.truckinfo}</div>
                            <div>{ttin.driverName}</div>
                            <div>{ttin.contact}</div>
                            </td>
                            {product.map((ps, pIndex) => {
            const productQty = ttin.pdo.find(item => item.product === ps.productname);
            return (
              <th key={pIndex}>
                {productQty ? productQty.qty : 0}
              </th>
            );
          })}
                            <td>{ttin.invoiceid}</td>
                            <td> 
<GrFormView style={{ marginLeft: '25px',cursor: 'pointer', fontSize:"25px" }}  title="view"
    onClick={() => handleViewButtonClick(ttin.invoiceid)}
    />
                     
    <MdDeleteForever style={{ marginLeft: '25px',cursor: 'pointer', fontSize:"18px" }}  title="Delete"
    onClick={() => openLoadinModal(ttin.truckinfo, ttin.invoiceid, ttin.date)}
    /></td>

                            <th>
                                <button className="btn btn-primary"
                                  onClick={(e) =>
                                    onClickdownloadLoadIn(e, ttin.loadinId)
                                  }
                                >
                                  Download
                                </button>
                              </th>

                          </tr>
                        );
                      }))}
                     {!searchbydate && Loadin.length === 0 ? (
            <tr>
              <td colSpan="10" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (!searchbydate &&
                      Loadin.map((ttin) => {
                        return (
                          <tr className="text-center">
                             <td><div>{ttin.truckinfo}</div>
                            <div>{ttin.driverName}</div>
                            <div>{ttin.contact}</div>
                            </td>
                            {product.map((ps, pIndex) => {
            const productQty = ttin.pdo.find(item => item.product === ps.productname);
            return (
              <th key={pIndex}>
                {productQty ? productQty.qty : 0}
              </th>
            );
          })}
                            <td>{ttin.invoiceid}</td>
                            {/* <td>
  <button onClick={() => handleViewButtonClick(ttin.invoiceid)}>View</button>
  <button onClick={() => openLoadinOutModal(ttin.truckinfo, ttin.invoiceid, ttin.date)}>
  <MdDeleteForever/>
  </button>
</td> */}

<td> 
<GrFormView style={{ marginLeft: '25px',cursor: 'pointer', fontSize:"25px" }}  title="view"
    onClick={() => handleViewButtonClick(ttin.invoiceid)}
    />
                     
    <MdDeleteForever style={{ marginLeft: '25px',cursor: 'pointer', fontSize:"18px" }}  title="Delete"
    onClick={() => openLoadinModal(ttin.truckinfo, ttin.invoiceid, ttin.date)}
    /></td>
                            <th>
                                  <button className="btn btn-primary"
                                  onClick={(e) =>
                                    onClickdownloadLoadIn(e, ttin.loadinId)
                                  }
                                >
                                  Download
                                </button>
                              </th>


                          </tr>
                      );
                    }))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <LoadIncoming
        isOpen={loadinModalIsOpen} 
        onRequestClose={closeloadinModal}
        loadinDetails={loadinDetails}
        ttinDate={ttinDate}
        vehicleId={selectedVehicleId}
        InvoiceId={selectedInvoiceId}

        products={product}
      />


        </div>
      </div>
      <br/>
      <br/>
      {loadoutLoader && <div className="loadout-loading"></div>}

      <div className="loadout" id="loadout">
        <div>
          <div className="col-12 mx-xl-auto ps-xl-10 ">
            <div className="card card-raised ">
              <div className="card-body ">
                <div className="d-flex">
                  <div className="card-title">Load Outgoing Details
                  {loadoutLoader && (
                  <div
                    className="loader"
                    style={{
                      position: "relative",
                      left: "20px",
                      top: "10px",
                    }}
                  ></div>
                )}
                  </div>
                 <div className="col-9 d-flex justify-content-end ps-xl-6">
                
                    <button className="btn btn-primary" onClick={(e) => updateLoadout(e, searchDate)}>Create ERV</button>
                 
                    <span style={{ marginLeft: '5px' }}></span>
   <button className="btn btn-primary" onClick={handleLoadoutRefresh}>
        Refresh 
      </button>
                  </div>
                </div>
                <br />
                <table className="table table-bordered ">
                  <thead>
                    <tr
                      style={{
                        fontSize: "17px",
                        backgroundColor: " #6200ea",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      <th>Vehicle Id</th>
                      {product.map((pro)=>{
                      return <th>{pro.productname}</th>;
                      })}
                      <th>ERV Id</th>
                      <th> Actions</th>
                      <th>Document</th>
                    </tr>
                  </thead>
                  <tbody>
                  {searchbydate && transactions.length === 0 ? (
            <tr>
              <td colSpan="10" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (searchbydate &&
                      transactions.map((tout) => {
                        return (
                          <tr className="text-center">
                            <td><div>{tout.truckinfo}</div>
                            <div>{tout.driverName}</div>
                            <div>{tout.contact}</div>
                            </td>
                            {product.map((ps, pIndex) => {
            const productQty = tout.pdo.find(item => item.product === ps.productname);
            return (
              <th key={pIndex}>
                {productQty ? productQty.qty : 0}
              </th>
            );
          })}
                            <td>{tout.ervid}</td>
                            <td> 

                     
    <MdDeleteForever style={{ marginLeft: '25px',cursor: 'pointer', fontSize:"18px" }}  title="Delete"
    onClick={() => openLoadoutModal(tout.truckinfo, tout.ervid, tout.date)}
    /></td>
                            <th>
                                <button className="btn btn-primary"
                                  onClick={(e) =>
                                    onClickdownloadLoadOut(e, tout.loadoutId)
                                  }
                                >
                                  Download
                                </button>
                              </th>


                          </tr>
                        );
                      }))}
                     {!searchbydate && Loadout.length === 0 ? (
            <tr>
              <td colSpan="10" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (!searchbydate &&
                      Loadout.map((tout) => {
                        return (
                          <tr className="text-center">
                            <td><div>{tout.truckinfo}</div>
                            <div>{tout.driverName}</div>
                            <div>{tout.contact}</div>
                            </td>
                            {product.map((ps, pIndex) => {
            const productQty = tout.pdo.find(item => item.product === ps.productname);
            return (
              <th key={pIndex}>
                {productQty ? productQty.qty : 0}
              </th>
            );
          })}
                            <td>{tout.ervid}</td>
                            <td> 

                     
<MdDeleteForever style={{ marginLeft: '25px',cursor: 'pointer', fontSize:"18px" }}  title="Delete"
onClick={() => openLoadoutModal(tout.truckinfo, tout.ervid, tout.date)}
/></td>
                            <th>
                                <button className="btn btn-primary"
                                  onClick={(e) =>
                                    onClickdownloadLoadOut(e, tout.loadoutId)
                                  }
                                >
                                  Download
                                </button>
                              </th>
                           
                          </tr>
                        );
                      }))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
         

          {loader && <div className="loading"></div>}
        </div>
        <LoadOutgoing
        isOpen={loadoutModalIsOpen} 
        onRequestClose={closeloadoutModal}
        loadoutDetails={loadoutDetails}
        ttinDate={ttoutDate}
        vehicleId={selectedVehicleId1}
        ervId={selectedervId}

        products={product}
      />
      </div>
    </div>
  );
}

export default Loaddetails;
