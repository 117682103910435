import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import { Link, useNavigate } from "react-router-dom";
import CreateAdminAccount from "./createAdminAccount";
import ViewAdminAccount from "./Viewadminpopup";
import "./Spinner.css";
import "./Loader.css";
import { useParams } from "react-router-dom";
import ExpirydateModal from "./Expirydate";
import HistoryModal from "./historymodal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Detailsofsubscription() {
    const { orgcode } = useParams(); 
    console.log(orgcode); 

  const [organizationdetails, setOrganizationdetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isorgModalOpen, setIsorgModalOpen] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [loader, setLoader] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [ishistoryModalOpen, setIshistoryModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [orgDetails, setOrgDetails] = useState({});
  const [paymentDetails, setPaymentDetails] = useState({});


  const getData = async () => {
    try {
    const token = JSON.parse(localStorage.getItem("data")).token;

    const trans = await axios.get(`/api/user/subscription/orgcode=${orgcode}`, {
      headers: { Authorization: `Bearer ${token}` },
    }
    );

    console.log(trans);

    setOrganizationdetails(trans.data);


  const orgResponse = await axios.get(
        `/api/organizationprofiles/Orgcode=${orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setOrgDetails(orgResponse.data);

      const paymentResponse = await axios.get(`/api/user/userpayment/username=${orgResponse.data.username}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (paymentResponse.data.length > 0) {
        setPaymentDetails(paymentResponse.data);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
     handleError(error);
    }
  };

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } 
    else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }
  const handleHistory = (subModelId) => {
    
    setSelectedId(subModelId);
    setIshistoryModalOpen(true);
};

const handleClosehistoryModal = () => {
    setIshistoryModalOpen(false);
};
  
  const handleUpdate = (expires,id) => {
    console.log("Expires:", expires);
    setSelectedProductId(expires);
    setSelectedUserId(id);
    setIsModalOpen (true);

  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };
  
  

  useEffect(() => {
    getData();
  }, [orgcode]);


  const [itemsPerPage, setItemsPerPage] = useState(10); 
  const [currentPage, setCurrentPage] = useState(1);

  const changePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const changeItemsPerPage = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1); 
  };

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;

  
  const Currentorganizationdetails = organizationdetails.slice(firstIndex, lastIndex);

  
  const totalPages = Math.ceil(organizationdetails.length / itemsPerPage);

  const [itemsPerPage1, setItemsPerPage1] = useState(10); 
  const [currentPage1, setCurrentPage1] = useState(1);

  const changePage1 = (pageNumber1) => {
    setCurrentPage1(pageNumber1);
  };

  const changeItemsPerPage1 = (e) => {
    setItemsPerPage1(parseInt(e.target.value));
    setCurrentPage1(1); 
  };

  const lastIndex1 = currentPage1 * itemsPerPage1;
  const firstIndex1 = lastIndex1 - itemsPerPage1;

  const CurrentpaymentDetails = Array.isArray(paymentDetails) ? paymentDetails.slice(firstIndex1, lastIndex1) : [];

  const totalPages1 = Math.ceil((Array.isArray(paymentDetails) ? paymentDetails.length : 0) / itemsPerPage1); 
  
  
  return (
    <div className="attendance-page" style={{marginTop:'100px'}}>
    <div className="dashboard-container">
        <Dashboard />
      </div>
     
     
      <div className="content-container">
        <div className="col-12  mx-xl-auto ps-xl-30" style={{marginTop:"10%"}}
        >
          <div className="card card-raised ">
            <div className="card-body ">

            <div className="card-title">Profile Details
                            {loader && (
                                <div
                                    className="loader"
                                    style={{ position: "absolute", left: "210px", top: "15px" }}
                                ></div>
                            )}
                        </div>
                        <br />
                        <div>
                        {orgDetails && (
    <table style={{ fontSize: '17px', marginBottom: '10px' }}>
        <tbody>
            <tr>
                <td style={{ paddingRight: '20px',paddingBottom:"10px" }}>Organization Name:</td>
                <td>{orgDetails.name}</td>
                <td style={{ paddingLeft: '300px',paddingBottom:"10px" }}>Orgcode:</td>
                <td>{orgDetails.orgcode}</td>
            </tr>
            <tr>
                <td style={{ paddingRight: '20px',paddingBottom:"10px" }}>Contact:</td>
                <td>{orgDetails.contact}</td>
                <td style={{ paddingLeft: '300px',paddingBottom:"10px" }}>PAN:</td>
                <td>{orgDetails.pan}</td>
            </tr>
            <tr>
                <td style={{ paddingRight: '20px',paddingBottom:"10px" }}>GST:</td>
                <td>{orgDetails.gstin}</td>
                <td style={{ paddingLeft: '300px',paddingBottom:"10px" }}>TAN:</td>
                <td>{orgDetails.tan}</td>
            </tr>
            <tr>
                <td style={{ paddingRight: '20px',paddingBottom:"10px" }}>City:</td>
                <td>{orgDetails.city}</td>
                <td style={{ paddingLeft: '300px',paddingBottom:"10px" }}>State:</td>
                <td>{orgDetails.state}</td>
            </tr>
            <tr>
                <td style={{ paddingRight: '20px',paddingBottom:"10px" }}>Pincode:</td>
                <td>{orgDetails.pincode}</td>
                <td style={{ paddingLeft: '300px',paddingBottom:"10px" }}></td>
                <td></td>
            </tr>
        </tbody>
    </table>
)}


</div>
</div>
</div>
</div>

<div className="col-12  mx-xl-auto ps-xl-30" style={{marginTop:"2%"}}>
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="card-title">Subscription Details
              {""}
              {loader && (
        <div
          className="loader"
          style={{ position: "absolute", left: "210px", top: "15px" }}
        ></div>
      )}
             
      
          
              </div>

              <br />
              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: "blue",
                      color: "white",
                    }}
                  >
                    <th> Name </th>

                    <th>Submodel Id</th>
                    <th>Subscription Date</th>

                    <th>Expiry Date</th>
                    <th>Actions</th>

                  </tr>
                </thead>
                <tbody>
                  {Currentorganizationdetails.map((accd) => {
                    return (
                      <tr key={accd.id}>
                        <td>{accd.username}</td>

                        <td>{accd.subModelId}</td>
                        <td>{accd.subdate}</td>
                        <td>{accd.expires}</td>
                        <td>
        <button onClick={() => handleHistory(accd.subModelId)}>History</button>
        <span style={{ marginRight: '5px' }}></span>
      
        <button onClick={() => handleUpdate(accd.expires,accd.id)}>Update</button>
      </td>


                       
                       
                        
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div>

              </div>
            </div>
          </div>
          <div className="pagination-container">
        {currentPage > 1 && (
          <button className="btn btn-primary" onClick={() => changePage(currentPage - 1)}>
            Previous
          </button>
        )}

        <div className="pagination-current">
          &lt;&lt;{currentPage}&gt;&gt;
        </div>

        {currentPage < Math.ceil(organizationdetails.length / itemsPerPage) && (
          <button className="btn btn-primary" onClick={() => changePage(currentPage + 1)}>
            Next
          </button>
        )}
        <div style={{marginLeft:"5%"}}>
        <label>Rows per page</label>
        <select value={itemsPerPage} onChange={changeItemsPerPage}>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20 </option>
          <option value={25}>25 </option>
          <option value={30}>30 </option>
        </select>
        </div>
        </div>
        </div>
        <ExpirydateModal isOpen={isModalOpen} onRequestClose={handleCloseModal}  expires={selectedProductId}   id={selectedUserId} />
        <HistoryModal isOpen={ishistoryModalOpen} onRequestClose={handleClosehistoryModal}  subModelId={selectedId} />


        <div className="col-12  mx-xl-auto ps-xl-30" style={{marginTop:"2%"}}>
          <div className="card card-raised ">
            <div className="card-body ">
        
                                <div className="card-title">Payment Details</div>
                                <table className="table table-bordered ">
                                    <thead>
                                        <tr   style={{
                      fontSize: "17px",
                      backgroundColor: "blue",
                      color: "white",
                    }}>
                                            <th>User Name</th>
                                            <th>paymentCurrency</th>
                                            <th>amount</th>
                                            <th>paymentStatus</th>
                                            <th>paymentDate</th>
                                            <th>razorpay_payment_id</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                {CurrentpaymentDetails.map((payment) => (
                  <tr key={payment.username}>
                    <td>{payment.username}</td>
                    <td>{payment.paymentCurrency}</td>
                    <td>{payment.amount}</td>
                    <td>{payment.paymentStatus}</td>
                    <td>{payment.paymentDate}</td>
                    <td>{payment.razorpay_payment_id}</td>
                  </tr>
                ))}
              </tbody>
            </table>
                            </div>
                            </div>

                            <div className="pagination-container">
        {currentPage1 > 1 && (
          <button className="btn btn-primary" onClick={() => changePage1(currentPage1 - 1)}>
            Previous
          </button>
        )}

        <div className="pagination-current">
          &lt;&lt;{currentPage1}&gt;&gt;
        </div>

        {currentPage1 < Math.ceil(paymentDetails.length / itemsPerPage1) && (
          <button className="btn btn-primary" onClick={() => changePage1(currentPage1 + 1)}>
            Next
          </button>
        )}
        <div style={{marginLeft:"5%"}}>
        <label>Rows per page</label>
        <select value={itemsPerPage1} onChange={changeItemsPerPage1}>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20 </option>
          <option value={25}>25 </option>
          <option value={30}>30 </option>
        </select>
        </div>
        </div>
        </div>
          </div>
        </div>
   
  );
}


export default Detailsofsubscription;
 
