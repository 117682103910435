import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Search from "./search";
import AdminDashboard from "./admindashboard";
import "./Spinner.css";
import Financialmanagment from "./financialManagment";
import { error } from "jquery";
import OfficeFinancialCreate from "./officeFinancialCreate";
function OfficeFinancials() {
  const [uproductcost, setUproductcost] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchstate, setSearchstate] = useState(true);
  const [searchbydate, setSearchbydate] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [product, setProduct] = useState([]);
  const [vehicle, setVehicle] = useState([]);

  const date = new Date();
  const token = JSON.parse(localStorage.getItem("data")).token;


  let day = date.getDate();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const searchName = async (text) => {
    setSearchstate(!searchstate);
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    const res = await axios
    .get(
      `/api/officefinancial/orgid=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }/date=${text}`,

      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((response) => {
        console.log(response);

        setUproductcost(response.data);
        setLoading(false);
      })
      .catch((error)=>{
        setLoading(false);
        handleError(error);
      });
  };
  const getData = async () => {
    setLoading(true);

    axios

      .get(
        `/api/officefinancial/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${currentDate}`,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        setUproductcost(response.data);
        setLoading(false);
      })

      .catch((error) => {
       setLoading(false);
       handleError(error);
      });
  };
  
  const getVehicleAndProduct = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try{
    setLoading(true);
    const pro = await axios.get(
      `/api/product/productstatus=true/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    setProduct(pro.data);
  
    setLoading(false);
  }catch(error){
    setLoading(false);
    handleError(error);
  }
  };

  const [selectedProductRefill, setSelectedProductRefill] = useState('');
  const [selectedProductConsumable, setSelectedProductConsumable] = useState('');

  // Filter function
  const filteredRefillSales = uproductcost.filter((cost) => {
    const productMatches = selectedProductRefill ? cost.productname === selectedProductRefill : true;
    return productMatches && cost.productType === "Refill Sales";
  });

  // Filter function for consumables
  const filteredConsumables = uproductcost.filter((cost) => {
    const productMatches = selectedProductConsumable ? cost.productname === selectedProductConsumable : true;
    return productMatches && cost.productType === "Consumables";
  });


  useEffect(() => {
    getVehicleAndProduct();
    getData();
  }, []);

  const handlefinancialmanagment = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };
 
  const navigate = useNavigate();
      function handleError(error) {
        if (error.response && error.response.status === 401) {
          navigate(`/signin`);
          toast.error("Your session has expired. Please login.");
        } else {
          if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          } else {
            toast.error('An error occurred.');
          }
        }
      }

  return (
  
      <div id="layoutDrawer_content">
      {loading && <div className="loading"></div>} 

      <main>
      <div className="col-12 mx-xl-auto ps-xl-10">
  <div className="card card-raised">
    <div className="card-body">
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="card-title">Office Financials Details</h5>
        <div>
          <Search searchName={searchName} />
        </div>
        <button className="btn btn-primary" onClick={handlefinancialmanagment}>
          ADD
        </button>
              
            </div>   
            <div className="mt-4">
            <h3 className="card-title">Refill Sales :</h3>
            </div>
            <div className="filter-dropdowns mt-2">
<div className="row">

  <div controlId="productFilter">
  <label>FILTER BY PRODUCT NAME :</label>
            <select
              value={selectedProductRefill}
              onChange={(e) => setSelectedProductRefill(e.target.value)}
            >
              <option value="">All</option>
              {product.map((p) =>
                p.productType === "Refill Sales" ? (
                  <option key={p.id} value={p.productname}>
                    {p.productname}
                  </option>
                ) : null
              )}
            </select>
   
  
  </div>
  </div>
</div>
                  <br />
              
                  <table className="table table-bordered ">
                    <thead>
                      <tr
                        style={{
                          fontSize: "17px",
                          backgroundColor: "blue",
                          color: "white",
                          textAlign:"center"
                        }}
                      >
                        <th>Date</th>
                        <th>Product Code</th>
                        <th>Product Name</th>
                        <th>Sales Cost</th>
                        <th>New Conection Cost</th>
                        <th>Additional Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                    {searchstate &&
      filteredRefillSales.length === 0 ? (
        <tr>
          <td colSpan="7" className="text-center">
            No Data Available
          </td>
        </tr>
      ) : (
        searchstate &&
        filteredRefillSales.map((cost) => {
          if (cost.productType === "Refill Sales") {
            return (
              <tr key={cost.id}>
                <td>{cost.date}</td>
                <td>{cost.productcode}</td>
                <td>{cost.productname}</td>
                <td>{cost.salecost}</td>
                <td>{cost.newconection}</td>
                <td>{cost.additionalcost}</td>
              </tr>
            );
          } else {
            return null;
          }
        })
      )}
    {!searchstate &&
      filteredRefillSales.length === 0 ? (
        <tr>
          <td colSpan="7" className="text-center">
            No Data Available
          </td>
        </tr>
      ) : (
        !searchstate &&
        filteredRefillSales.map((cost) => {
          if (cost.productType === "Refill Sales") {
            return (
              <tr key={cost.id}>
                <td>{cost.date}</td>
                <td>{cost.productcode}</td>
                <td>{cost.productname}</td>
                <td>{cost.salecost}</td>
                <td>{cost.newconection}</td>
                <td>{cost.additionalcost}</td>
              </tr>
            );
          } else {
            return null;
          }
        })
      )}
                    </tbody>
                  </table>

                  <br/>
                  <div className="mt-4">
            <h3 className="card-title">Consumables :</h3>
            </div>
            <div className="filter-dropdowns mt-2">
<div className="row">

  <div controlId="productFilter">
  <label>FILTER BY PRODUCT NAME :</label>
            <select
              value={selectedProductConsumable}
              onChange={(e) => setSelectedProductConsumable(e.target.value)}
            >
              <option value="">All</option>
              {product.map((p) =>
                p.productType === "Consumables" ? (
                  <option key={p.id} value={p.productname}>
                    {p.productname}
                  </option>
                ) : null
              )}
            </select>
   
  
  </div>
  </div>
</div>
                  <br />
      
                  <table className="table table-bordered ">
                    <thead>
                      <tr
                        style={{
                          fontSize: "17px",
                          backgroundColor: "blue",
                          color: "white",
                          textAlign:"center"
                        }}
                      >
                        <th>Date</th>
                        <th>Product Code</th>
                        <th>Product Name</th>
                        <th>Sales Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                    {searchstate &&
      filteredConsumables.length === 0 ? (
        <tr>
          <td colSpan="7" className="text-center">
            No Data Available
          </td>
        </tr>
      ) : (
        searchstate &&
        filteredConsumables.map((cost) => {
          if (cost.productType === "Consumables") {
            return (
              <tr key={cost.id}>
                <td>{cost.date}</td>
                <td>{cost.productcode}</td>
                <td>{cost.productname}</td>
                <td>{cost.salecost}</td>
              </tr>
            );
          } else {
            return null;
          }
        })
      )}
    {!searchstate &&
      filteredConsumables.length === 0 ? (
        <tr>
          <td colSpan="7" className="text-center">
            No Data Available
          </td>
        </tr>
      ) : (
        !searchstate &&
        filteredConsumables.map((cost) => {
          if (cost.productType === "Consumables") {
            return (
              <tr key={cost.id}>
                <td>{cost.date}</td>
                <td>{cost.productcode}</td>
                <td>{cost.productname}</td>
                <td>{cost.salecost}</td>
              </tr>
            );
          } else {
            return null;
          }
        })
      )}
                    </tbody>
                  </table>
                  {isModalOpen && (
                  <OfficeFinancialCreate isOpen={isModalOpen} onRequestClose={handleCloseModal}/>
                  )}

                </div>
             
                </div>    
            </div>
      </main>
    </div>
   
  );
}

export default OfficeFinancials;
