import React, { useEffect, useState } from "react";
import Dash from "./dash";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../api/baseurl";
import Footer from "./footer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}
function AnotherSubscription(props) {
  const [checkedmap, setCheckedmap] = useState(false);
  const [sub, setSub] = useState([]);
  const [subfeatur, setSubfeatur] = useState([]);
  const [subfeaturmodel, setSubfeaturmodel] = useState([]);
  const [loading, setLoading] = useState(false);

  const user = JSON.parse(localStorage.getItem("data"));
  const url = "/api/subscription-model";

  const getData = async () => {
    setLoading(true);
    const sf = await axios.get("/api/subscription-features");

    console.log(sf.data);

    setSubfeatur(sf.data);

    const sfmm = await axios.get("/api/SubmodelandfeatureDo");

    console.log(sfmm.data.features);

    setSubfeaturmodel(sfmm.data);
    const prof1 = await axios.get(url);

    console.log(prof1.data);

    setSub(prof1.data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  function handletotalamount(e, subscriptionid) {
    navigate(`/razorpay/${subscriptionid}`);
  }

  const [subdetails, setSubdetails] = useState({
    username: props.params.username,
    orgcode: props.params.orgcode,
  });

 

  function handleAccountSub(e) {
    e.preventDefault();
   
    axios

      .post(
        "/api/user/subscription/freetrial",
        subdetails,

      )

      .then((response) => {
        toast.success("Subscription successfully activated!");
        navigate("/signin");
      })
      .catch((error) => {
      //  handleError(error);
      });
  }

 
  const navigate = useNavigate();

  console.log(subfeaturmodel, "aaa");
  return (
    <div className="bg-primary">
    <div id="layoutAuthentication">
      <div id="layoutAuthentication_content">
    {loading && <div className="loading"></div>}
    <main>
      <div className=" col-10 mx-xl-auto ps-xl-6 mt-6">
        <div className="card card-raised">
          <div className="card-body ">
            <div className="container-xl p-6">
              <div className="text-center mb-5">
                <h3>Subscribe For The Required Plans</h3>
                <p className="mb-0">No contracts, no surprise fees</p>
                <div style={{ display: 'flex', justifyContent: 'center' }} className="mt-2">
                  <button
                    className="btn btn-outline-primary d-grid"
                    onClick={(e) =>
                      handleAccountSub(e)
                    }
                  >
                    Click Here For Free Trial
                  </button>
                </div>
              </div>
              

              <div className="row" style={{ maxWidth: "120%" }}>
                {subfeaturmodel.map((subscription) => {
                  return (
                    <div className="col-sm-4 mb-4" >
    <div className="card card-raised h-100">
      <div className="card-body">
                            <h2 className="overline">
                              {subscription.submodel}
                            </h2>
                            <div className="d-flex align-items-center mb-3">
                              <div className="display-5">
                                {subscription.cost}
                              </div>
                              <div className="text-muted ms-2">
                                {subscription.region == "INDIA" && "INR"}
                                {subscription.region == "USA" && "USD"}
                                &ensp;&nbsp;{subscription.region}
                              </div>
                            </div>
                            <p>
                              For most businesses that want to optimize web
                              queries (trial)
                            </p>

                            {subscription.features.map((sbf) => {
                              return (
                                <ul className="list-group list-group-flush">
                                  <li className="list-group-item px-6">
                                    <div className="d-flex align-items-center">
                                      <i className="material-icons icon-xs me-4">
                                        check
                                      </i>
                                      {sbf.features}
                                    </div>
                                  </li>
                                </ul>
                              );
                            })}
                          </div>
                          <div className="card-footer border-0 bg-transparent pt-0">
                            <button
                              className="btn btn-outline-primary d-grid"
                              onClick={(e) =>
                                handletotalamount(e, subscription.id)
                              }
                            >
                              Choose Plan
                            </button>
                          </div>
                        </div>
                      </div>
                  
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div id="layoutAuthentication_footer">
       <Footer/>
      </div>
      </div>
      </div>
  </div>  );
}

export default withRouter(AnotherSubscription);
