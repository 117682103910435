import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";

import AdminDashboard from "./admindashboard";
import { Link, useNavigate } from "react-router-dom";
import "./Spinner.css";
const TeritorydetailsModal = ({ isOpen, onRequestClose }) => {
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;      
  const initialProductState = {
    territoryname: "",
   
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  };

  const [newProduct, setNewProduct] = useState(initialProductState);
  const [loading, setLoading] = useState(false);
  const [productTypes, setProductTypes] = useState([]); // State to store product types
  const [selectedProductType, setSelectedProductType] = useState("");

  const url = "/api/territory/create";
  const navigate = useNavigate();

 
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.post(url, newProduct, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data);
      setLoading(false);
      toast.success("Teritory created successfully");
      onRequestClose(); 
      setNewProduct(initialProductState);
    } catch (error) {
      handleError(error);
    }
  };
  
  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else if (error.response && error.response.status === 400) {
      toast.error(error.response.data.code);
    } else {
    }
    setLoading(false);
  };
  const rhandle = (e) => {
    const { id, value } = e.target;
    setNewProduct((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add Teritory</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
            <form onSubmit={handleSubmit}>
            
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    
                    <label htmlFor="territoryname">Teritory Name</label>
                    <input
                      className="form-control"
                      id="territoryname"
                      type="text"
                      placeholder="territoryname"
                      value={newProduct.territoryname}
                      onChange={(e) => rhandle(e)}
                    />
                   
                  </div>
                </div>
              
                 
                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}

                />
                 &nbsp;&nbsp;&nbsp;&nbsp;

                <Button variant="secondary" onClick={onRequestClose}>
               Close
               </Button>  

              </form>
            </div>
          </div>
        </div>

      </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
};

export default TeritorydetailsModal;