import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Bar, Pie } from "react-chartjs-2";
import ChartJS from "chart.js/auto";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "./admindashboard";
import CanvasJSReact from "@canvasjs/react-charts";
import Search from "./search";
import { IoSearchOutline } from "react-icons/io5";
import ConfirmationModal from "./endtransactionpopup";
import Monthlytarget from "./Monthlytarget";
import Overheadmodal from "./overheadmodal";
import Overheadeditmodal from "./OverheadEdit";
import { BiDownArrow, BiEditAlt, BiUpArrow } from "react-icons/bi";
import { BsCheckCircle } from "react-icons/bs";
import { BsXCircle } from "react-icons/bs";
import { AiOutlineCheck, AiFillCheckSquare } from "react-icons/ai";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
function Productanalytics() {
  
  const [vehicle, setVehicle] = useState([]);
  const [deliveryagent, setDeliveryaget] = useState([]);
  const navigate = useNavigate();
  const [ClosestockYesterday, setClosestockYesterday] = useState(null);
  const [openstockYesterday, setOpenstockYesterday] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ismonthlyModalOpen, setIsmonthlyModalOpen] = useState(false);
  const [revenueDataLabels, setRevenueDataLabels] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [productTargetData, setProductTargetData] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Define isLoading state variable
  // const [loader, setLoader] = useState(false);
  const [isoverheadModalOpen, setIsoverheadModalOpen] = useState(false);
  const [ monthlyData,  setMonthlyData] = useState([]);

  const [transactionData, setTransactionData] = useState([]);
  const [productDate, setProductDate] = useState({
    date: new Date().toISOString().slice(0, 7)
  });
 

  const date = new Date();
  const date1 = new Date();
  date.setDate(date.getDate());
  date1.setDate(date1.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  let currentDate1 = `${year}-${month}`;

  function getCurrentMonth() {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    return `${year}-${month}`;
  }
  const [isoverheadEditModalOpen, setIsoverheadEditModalOpen] = useState(false);
  const [selectedoverheadId, setSelectedoverheadId] = useState(null);

  const [product, setProduct] = useState([]);
  const [overHead, setOverHead] = useState([]);

  const [targetbymonth, setTargetbymonth] = useState([]);
  const [producttargetbymonth, setProducttargetbymonth] = useState([]);

  const [searchbymonth, setSearchBymonth] = useState(true);

  const [totaltransaction, setTotaltransaction] = useState([]);

  const [productnames, setProductname] = useState([]);
  const [datalabels, setDatalabels] = useState({
    labels: [],

    datasets: [
      {
        label: "Target Sale",
        data: [1000, 2000],
        backgroundColor: "",
      },
    ],
  });
  const [revenuedatalabels, setRevenuedatalabels] = useState();
  const [searchstate, setSearchstate] = useState(true);
  const [searchbydate, setSearchbydate] = useState([]);
  const [searchproductsale, setSearchproductsale] = useState(true);
  const [productsale1, setProductsale1] = useState({
    labels: [],
    datasets: [
      {
        label: "Product Sale",
        data: [],
        backgroundColor: "",
      },
    ],
  });
  const [productdate, setProductdate] = useState({
    date: "",
  });

  const [productsale, setProductsale] = useState({
    labels: [],
    datasets: [
      {
        label: "Product Sale",
        data: [],
        backgroundColor: "",
      },
    ],
  });
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);



  const checkCloseStockAndUpdate = async () => {
    
    try {
        const token = JSON.parse(localStorage.getItem("data")).token;

       
        let OpeningStock = null;
        let allOpeningStock = null;

        while (true) {
            const opRes = await axios.get(`/api/Openingstock/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/datetime=${currentDate}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const openStockres = await axios.get(`/api/Openingstock`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            OpeningStock = opRes.data;
            allOpeningStock = openStockres.data;

            if ((OpeningStock.length === 0) && (allOpeningStock.length > 0)) {
                const shouldCompleteTransaction = await new Promise((resolve) => {
                  setIsModalOpen(true);

                    // resolve(window.confirm("End transaction not completed. Proceed to complete the end transaction?"));
                });

                if (shouldCompleteTransaction) {
                    navigate("/users/stock");
                    break; 
                }
            } else {
                break; 
            }
        }
    } catch (error) {
        handleError(error);
    }
};


const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
const url = `/api/overhead/duedate/${orgCode}`;
const getData = async () => {
  try {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);

    const vehiclesResponse = await axios.get(`/api/vehicle/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setVehicle(vehiclesResponse.data);

    const authorityResponse = await axios.get(`/api/authority/role=ROLE_DELIVERY_AGENTS/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setDeliveryaget(authorityResponse.data);

    const productsResponse = await axios.get(`/api/product/productstatus=true/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setProduct(productsResponse.data);
    const overHeadResponse = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setOverHead(overHeadResponse.data);

    const totalTransactionResponse = await axios.get(`/api/totaltransaction/alltransaactions/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${currentDate}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (Array.isArray(totalTransactionResponse.data)) {
      setTransactionData(totalTransactionResponse.data);
    } else if (typeof totalTransactionResponse.data === 'object') {
      setTransactionData([totalTransactionResponse.data]);
    } else {
      console.error('Unexpected data format returned from API:', totalTransactionResponse.data);
    }

    const productsaleResponse = await axios.get(`/api/totaltransaction/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${currentDate}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setProductsale({
      ...productsale,
      labels: productsaleResponse.data.map((item) => item.productname),
      datasets: [
        {
          label: "Product Sale",
          data: productsaleResponse.data.map((item) => item.totalsale),
        },
      ],
    });

    const productMonthlyTargetResponse = await axios.get(`/api/productmonthlytarget/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${currentDate1}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setProductTargetData(productMonthlyTargetResponse.data);
    console.log(productMonthlyTargetResponse.data);

    const monthlyTargetResponse = await axios.get(`/api/monthlytarget/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${currentDate1}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const productSales = monthlyTargetResponse.data.map(item => ({
      productname: item.productname,
      totalSale: item.totalSale,
    }));
    setProductTargetData(monthlyTargetResponse.data);
    console.log(monthlyTargetResponse.data);
 
    setLoader(false);
  } catch (error) {
    setLoader(false);
    handleError(error); 
  }
};

 
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  useEffect(() => {
    getData();
    checkCloseStockAndUpdate();

    const currentDate = new Date(); 
    const currentDay = currentDate.getDate(); 
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let monthyear = `${year}-${month}`;
    const fetchData = async () => {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/productmonthlytarget/orgcode=cyn/date=${monthyear}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMonthlyData(response.data);
      console.log(response.data);
    
    };
    if (currentDay === 3 && monthlyData===0) { 
        setIsmonthlyModalOpen(true); 
    } else {
        setIsmonthlyModalOpen(false); 
    }
}, []);
  const handleCloseModal = () => {
    setIsmonthlyModalOpen(false);
    getData();
  };

  const searchName = async (text) => {
    setSearchstate(false);

    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    axios
      .get(
        `/api/totaltransaction/alltransaactions/orgcode=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${text}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      .then((response) => {
        if (Array.isArray(response.data)) {
          setTransactionData(response.data);
        } else if (typeof response.data === 'object') {
          setTransactionData([response.data]);
        } else {
          console.error('Unexpected data format returned from API:', response.data);
        }
      })
      .catch((error) => {
        handleError(error);
       });
  };
  const searchName1 = async (text) => {
    setSearchproductsale(false);
    const token = JSON.parse(localStorage.getItem("data")).token;

    axios
      .get(
        `/api/totaltransaction/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${text}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setProductsale1({
          ...productsale1,
          labels: res.data.map((item) => {
            return item.productname;
          }),
          datasets: [
            {
              label: "Product Sale",
              data: res.data.map((item) => {
                return item.totalsale;
              }),
            },
          ],
        });
      })

      .catch((error) => {
       handleError(error);
      });
  };
  const searchByMonth = async (e, text) => {
    setSearchBymonth(false);
    const token = JSON.parse(localStorage.getItem("data")).token;

    axios
      .get(
        `/api/monthlytarget/orgcode=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${text}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setProductTargetData(res.data);
        console.log(res.data);
      })

      .catch((error) => {
       handleError(error);
      });
  };
  function rhandle(e, field) {
    e.preventDefault();
    let actualValue = e.target.value;

    setProductdate(prevState => ({ ...prevState, [field]: actualValue }));
  }
  const handleCancel = () => {
    setIsModalOpen(false); // Assuming you want to close the modal when cancel is clicked
  };
  
  const handleConfirm = () => {
    navigate("/users/stock"); // Assuming you want to navigate somewhere when confirm is clicked
  };
  
  const searchProductName = async (productName) => {
    try {
        const token = JSON.parse(localStorage.getItem("data")).token; // Retrieve token from localStorage
        const response = await axios.get(
            `/api/totaltransaction/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${currentDate}/productname=${productName}`,
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );

        // Check if the response is an object
        if (typeof response.data === 'object' && !Array.isArray(response.data)) {
            // If it's an object, convert it to an array with a single element
            setTransactionData([response.data]);
            console.log(setTransactionData);
        } else {
            // If it's an array, set the transaction data directly
            setTransactionData(response.data);
            console.log(response.data);
        }
    } catch (error) {
        handleError(error);
    }
};


  const handleProductChange = (e) => {
    setSelectedProduct(e.target.value); // Update selected product state
  };

  const searchVehicleName = async (truckinfo) => {
    try {
        const token = JSON.parse(localStorage.getItem("data")).token; // Retrieve token from localStorage
        const response = await axios.get(
            `/api/totaltransaction/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${currentDate}/vehicleid=${truckinfo}`,
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );

        if (typeof response.data === 'object' && !Array.isArray(response.data)) {
            setTransactionData([response.data]);
            console.log(setTransactionData);
        } else {
            setTransactionData(response.data);
            console.log(response.data);
        }
    } catch (error) {
        handleError(error);
    }
};

const handleVehicleChange = (e) => {
  setSelectedVehicle(e.target.value); // Update selected product state
};

const triggerApiRequest = async () => {
  try {
    const token = JSON.parse(localStorage.getItem("data")).token; // Retrieve token from localStorage
    const response = await axios.get(
      `/api/totaltransaction/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${currentDate}/vehicleid=${selectedVehicle}/productname=${selectedProduct}`,
    
      {
        headers: { Authorization: `Bearer ${token}` },
    }
);

if (typeof response.data === 'object' && !Array.isArray(response.data)) {
    setTransactionData([response.data]);
    console.log(setTransactionData);
} else {
    setTransactionData(response.data);
    console.log(response.data);
}
} catch (error) {
handleError(error);
}
};



// useEffect hook to trigger API request when both product and vehicle are selected
useEffect(() => {
  if (selectedProduct && selectedVehicle) {
    triggerApiRequest();
  }
}, [selectedProduct, selectedVehicle]);


const handleAdd = () => {
  setIsoverheadModalOpen(true);


};

const handleoverheadCloseModal = () => {
  setIsoverheadModalOpen(false);
  getData();
};

const handleoverheadedit = async (id) => {
  setSelectedoverheadId(id);
 
  setIsoverheadEditModalOpen(true);
  
};

const closeoverheadEditModal = () => {
  setIsoverheadEditModalOpen(false);
  getData();

};
function rsubmit(e, id, status) {
  e.preventDefault();
  setLoading(true);

  const token = JSON.parse(localStorage.getItem("data")).token;

  axios
    .post(
      "/api/overhead/update",
      {
        id: id,
        completed: status,      },

      { headers: { Authorization: `Bearer ${token}` } }
    )

    .then((response) => {
      console.log(response.data);
      setLoading(false);
      getData();
    })

    .catch((error) => {
      handleError(error);
    });
}
  return (


    <div>
            {loader && <div className="loading"></div>}

            <div
        className="col-11  mx-xl-auto ps-xl-10 "
        style={{ position: "relative", top: "110px" }}
      >
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="d-flex">
              <div className="col-6  mx-xl-auto ps-xl-4 ">
                <div className="card card-raised">
                  <div className="card-body  ">
                    <div className="card-title">Total Number of Vehicles</div>
                    <h1 style={{ textAlign: "center" }}>{vehicle.length}</h1>
                  </div>
                </div>
              </div>
              <div className="col-6  mx-xl-auto ps-xl-4 ">
                <div className="card card-raised">
                  <div className="card-body  ">
                    <div className="card-title">
                      Total Number of Delivery Agents
                    </div>
                    <h1 style={{ textAlign: "center" }}>
                      {deliveryagent.length}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
                <div className="row">

                    <div className="col-md-9">
                    <div
                        className="col-10 mx-xl-auto ps-xl-10 "
                        style={{ position: "relative", top: "130px" }}
                      >
                        <div className="card card-raised">
                          <div className="card-body  ">
                          <div className="d-flex justify-content-between align-items-center">
            <div className="card-title">Finances</div>
            <div className="d-flex align-items-center">
              <div>
                <Search searchName={searchName} />
              </div>
             
            </div>
          </div>
          <br />

          {/* Product name dropdown */}
          <div className="d-flex justify-content-between align-items-center">
  <div className="d-flex align-items-center">
    {/* Product name dropdown */}
    Product :
    <select value={selectedProduct} onChange={handleProductChange}>
      <option value="">Select Product</option>
      {product.map((product) => (
        <option key={product.id} value={product.productname}>
          {product.productname}
        </option>
      ))}
    </select>
    <span style={{ marginRight: '5px' }}></span>
    <button
      className="btn btn-primary"
      onClick={() => searchProductName(selectedProduct)}
    >
      <IoSearchOutline />
    </button>
  </div>

  <div className="d-flex align-items-center">
    {/* Vehicle name dropdown */}
    Vehicle :
    <select value={selectedVehicle} onChange={handleVehicleChange}>
      <option value="">Select Vehicle</option>
      {vehicle.map((vehicle) => (
        <option key={vehicle.id} value={vehicle.truckinfo}>
          {vehicle.truckinfo}
        </option>
      ))}
    </select>
    <span style={{ marginRight: '5px' }}></span>
    <button
      className="btn btn-primary"
      onClick={() => searchVehicleName(selectedVehicle)}
      
    >
      <IoSearchOutline />
    </button>
  </div>
</div>
          <br />
                          <div className="row" style={{ maxWidth: "100%" }}>

                          <div className="col-lg-8 col-xl-12">
                          <div >
                                      <div className="card-body">
                                      {/* <h5 className="card-title">Transaction Summary</h5> */}
                                      {searchstate &&
                                        <Bar
                                          data={{
                                            labels: [
                                              "Total Sale",
                                              "Total Exempted",
                                              "Total New Connection",
                                              "Total Additional",
                                              "Total Online",
                                            ],
                                            datasets: transactionData.map((item, index) => ({
                                              label: `Transaction `,
                                              data: [
                                                item.totalsale,
                                                item.totalexempted,
                                                item.totalneworg,
                                                item.totaladditional,
                                                item.totalonlinepay,
                                              ],
                                                backgroundColor: [
                                                  'rgba(255, 99, 132, 0.6)',
                                                  'rgba(54, 162, 235, 0.6)',
                                                  'rgba(255, 206, 86, 0.6)',
                                                  'rgba(75, 192, 192, 0.6)',
                                                  'rgba(153, 102, 255, 0.6)',
                                                ],
                                                borderWidth: 1,
                                              })),
                                            }}
                                          

                                          height={1000}
                                          width={2500}
                                          // style={{width:" 400px"}}
                                          options={{
                                            scales: {
                                            
                                              y: {
                                                ticks:{
                                                  stepSize:10,
                                                beginAtZero: true,
                                                min: 0,
                                                max: 100,
                      
                                              },
                                            },

                                          },
                                  }}
                                />
                }

                {!searchstate &&  
                  <Bar
                  data={{
                    labels: [
                      "Total Quantity",
                      "Total Exempted",
                      "Total New Org",
                      "Total Additional",
                      "Total Online",
                    ],
                    datasets: transactionData.map((item, index) => ({
                      label: `Transaction `,
                      data: [
                        item.totalquantity,
                        item.totalexempted,
                        item.totalneworg,
                        item.totaladditional,
                        item.totalonlinepay,
                      ],
                        backgroundColor: [
                          'rgba(255, 99, 132, 0.6)',
                          'rgba(54, 162, 235, 0.6)',
                          'rgba(255, 206, 86, 0.6)',
                          'rgba(75, 192, 192, 0.6)',
                          'rgba(153, 102, 255, 0.6)',
                        ],
                        borderWidth: 1,
                      })),
                    }}
                    

                  height={1000}
                  width={2500}
                  // style={{width:" 400px"}}
                  options={{
                    scales: {
                    
                      y: {
                        ticks:{
                          stepSize:10,
                        beginAtZero: true,
                        min: 0,
                        max: 100,

                      },
                    },

                  },
                }}
                />
                }


                                      </div>
                                    </div>
                                  </div>
                          
                            
                          </div>
                        </div>
                      </div>
                        </div>
                    

                      <div
                        className="col-10  mx-xl-auto ps-xl-10 "
                        style={{ position: "relative", top: "150px" }}
                      >
                        <div className="card card-raised">
                          <div className="card-body  ">
                          <div className="d-flex justify-content-between align-items-center">
        <div className="card-title form-group">Product Sale</div>
        <div className="d-flex align-items-center">
        <div>
                <Search searchName={searchName1} />
              </div>
        </div>
</div>
                            {searchproductsale && (
                              <Bar
                                data={productsale}
                                height={1000}
                                width={2500}
                                style={{ width: "400px" }}
                              />
                            )}
                            {!searchproductsale && (
                              <Bar
                                data={productsale1}
                                height={1000}
                                width={2500}
                                style={{ width: "400px" }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3"> 
  <div style={{ position: "relative", top: "130px", right: "70px"}}>
    <div className="card card-raised">
      <div className="card-body">
        <div className="card-title form-group d-flex align-items-center justify-content-between" >
          Overhead
          <button className="btn btn-primary" onClick={handleAdd}>
            ADD
          </button>
          </div>
      </div>
    </div>
    {/* Content */}
    <div className="card card-raised" style={{height: "88vh", overflow: "auto" }}>
      <div className="card-body">
        {overHead.length === 0 ? (
          <div className="text-center">No Data Available</div>
        ) : (
          <>
          {overHead.map((product) => (
            <div key={product.id} className="mb-3">
              <div className="card">
                <div className="card-body">
                <div className="form-group d-flex align-items-center justify-content-between"><div>{product.vehicleid}</div>
                <div>{product.duedate}</div>
                
                </div>
                  <hr/>
            
                    <div style={{ fontWeight: "bold" }}>{product.title}</div>
            
                    <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
            {product.description}
          </div>
                    <br/>
                  
<div style={{cursor:"pointer"}} title="Consumer Number">{product.consumerNumber}</div>
  <div style={{cursor:"pointer"}} title="Consumer Name">{product.consumerName}</div>
  <div style={{cursor:"pointer"}} title="Phone Number">{product.phoneNumber}</div>

                    <div className="form-group d-flex align-items-center justify-content-between">
                    {product.completed === false && (
  <div id="icon" value={product.completed}>
    <div >
      <AiFillCheckSquare style={{ color: 'green',fontSize:"25px", cursor: 'pointer' }}  title="update status" onClick={(e) => rsubmit(e, product.id, true)} />
    </div>
  </div>
)}
  <button
    onClick={() => handleoverheadedit(product.id)}
    style={{ border: "none", background: "white", marginLeft: "10px", fontSize:"20px", cursor: 'pointer' }}  title="Edit" 
  >
    <BiEditAlt />
  </button>
</div>

                  <hr/>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
  <div style={{cursor:"pointer"}} title="Created By">{product.userrole}</div>
  <div style={{cursor:"pointer"}} title="Product">{product.productname}</div>
  <div>
    {product.type === "Stock" ? (
      <>
        <BiUpArrow style={{ color: 'green', fontSize: "20px", cursor:"pointer" }} title="Taken Quantity" /> {product.takenQty}
        <BiDownArrow style={{ color: 'red', fontSize: "20px", cursor:"pointer" }} title="Given Quantity"/> {product.givenQty}
      </>
    ) : product.type === "Finance" ? (
      <div style={{cursor:"pointer" }} title="Pending Amount">{product.amount} ₹</div>
    ) : null}
  </div>
</div>


                </div>
              </div>
            </div>
          ))}
           <div className="card-title form-group d-flex align-items-center justify-content-end">
         <Link to="/users/overheaddetails">
      <button className="btn btn-primary">
            VIEW ALL
          </button>
          </Link>
          </div>
          </>
        )}
        
      </div>
     
    </div>
  </div>
</div>
</div>
        
     
      <br/>
     
      
      
      
      

      <div
        className="col-11  mx-xl-auto ps-xl-10 "
        style={{ position: "relative", top: "130px" }}
      >
        <div className="card card-raised">
          <div className="card-body  ">
          <div className="d-flex justify-content-between align-items-center">
        <div className="card-title form-group">Monthly Target</div>
        <div className="d-flex align-items-center">
                  <input
                    id="date"
                    type="month"
                    value={productdate.date || getCurrentMonth()}
                    onChange={(e) => rhandle(e, "date")}
                  
                    />
                  &nbsp;&nbsp;
                  <button
                    type="submit"
                    value="search"
                    className="btn btn-primary"
                    id="btn"
                    onClick={(e) => searchByMonth(e, productdate.date)}
                  >
                    <IoSearchOutline id="IoSearchOutline" />
                  </button>
              </div>
            </div>
            <div className="row" style={{ maxWidth: "100%" }}>
            <div>
                 {/* Display product target data */}
                 {searchByMonth && producttargetbymonth && (
        
        <Bar
          data={{
            labels: productTargetData.map((item) => item.productname),
            datasets: [
 {
                label: "Total Sale",
                data: productTargetData.map((item) => item.totalSale),
    
                          backgroundColor: 'rgba(75, 192, 192, 0.6)', 
             
               },
              {
                label: "Monthly Target",
                data: productTargetData.map((item) => item.target - item.totalSale),
                
                backgroundColor: 'rgba(255, 99, 132, 0.6)', 
              },
             
            ],
            // borderWidth: 1,
          }}
          height={1000}
          width={2500}
          // style={{width:" 400px"}}
          options={{
            indexAxis: 'y',
                        scales: {
                          x: {
                            stacked: true,
                            
                          },
                          y: {
                            stacked: true,
                            
                          },
                        },
                        barThickness: 40,
                        plugins: {
                          tooltip: {
                            callbacks: {
                              label: function(context) {
                                let label = context.dataset.label || '';
                
                                if (label) {
                                  label += ': ';
                                }
                                label += context.formattedValue;
                
                                if (context.dataset.label === 'Monthly Target') {
                                  const target = context.raw;
                                  const sale = context.chart.data.datasets[0].data[context.dataIndex];
                                  const difference = target + sale; 
                                  label = ` Target: ${difference}`;
                                }
                
                                return label;
                              }
                            }
                          }
                        }
                      }}
                    />
                    )}
                    {!searchByMonth && producttargetbymonth && (
                    
        <Bar
          data={{
            labels: productTargetData.map((item) => item.productname),
            datasets: [
 {
                label: "Total Sale",
                data: productTargetData.map((item) => item.totalSale),
    
                          backgroundColor: 'rgba(75, 192, 192, 0.6)', 
             
               },
              {
                label: "Monthly Target",
                data: productTargetData.map((item) => item.target- item.totalSale),
                
                backgroundColor: 'rgba(255, 99, 132, 0.6)', 
              },
             
            ],
            // borderWidth: 1,
          }}
          height={1000}
          width={2500}
          // style={{width:" 400px"}}
          options={{
            indexAxis: 'y',
                        scales: {
                          x: {
                            stacked: true,
                          },
                          y: {
                            stacked: true,
                          },
                        },
                        barThickness: 40,
                        plugins: {
                          tooltip: {
                            callbacks: {
                              label: function(context) {
                                let label = context.dataset.label || '';
                
                                if (label) {
                                  label += ': ';
                                }
                                label += context.formattedValue;
                
                                if (context.dataset.label === 'Monthly Target') {
                                  const target = context.raw;
                                  const sale = context.chart.data.datasets[0].data[context.dataIndex];
                                  const difference = target +sale; 
                                  label = ` Target: ${difference}`;
                                }
                
                                return label;
                              }
                            }
                          }
                        }
                      }}
                    />
                    
                    
                    )}  
      
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
  <ConfirmationModal
    message="End transaction not completed. Proceed to complete the end transaction?"
    onCancel={handleCancel}
    onConfirm={handleConfirm}
  />
)}
      <Monthlytarget isOpen={ismonthlyModalOpen} onRequestClose={handleCloseModal} />
      <Overheadmodal isOpen={isoverheadModalOpen} onRequestClose={handleoverheadCloseModal} />
      {isoverheadEditModalOpen && (
              <Overheadeditmodal
                isOpen={isoverheadEditModalOpen}
                onRequestClose={closeoverheadEditModal}
                id={selectedoverheadId}
              />
            )}
    </div>
  );
}
export default Productanalytics;