import React, { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import axios from "../api/baseurl";
import { toast, ToastContainer } from "react-toastify";
import { Stepper, Step, StepLabel } from "@mui/material";
import "./Spinner.css";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";
import AdminDashboard from "./admindashboard";
import ProductdetailsModal from "./updateproducts";
import ProducteditModalPopup from "./productedit";
import ProducteditModal from "./Editproductmodal";
import { MdDeleteForever } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import AddVehiclesmodalpopup from "./Addvehicle";
import VehicleeditsModal from "./Editvehiclemodal";
import { Pagination, Form, Card, Row, Col } from "react-bootstrap";
import RegisterMd from "./registermandd";
import AddproductModal from "./Addproduct";
import Register from "./Registertagent";
import AddVehiclesmodal from "./createvehicle";
import Updateopenstockmodal from"./Openinstockupdate";

const Activitycheck = () => {
  const navigate = useNavigate();
  const date = new Date();
  const date1 = new Date();
  date.setDate(date.getDate());
  date1.setDate(date1.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const [activeStep, setActiveStep] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProductEditModalOpen, setIsProductEditModalOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [priceHistory, setPriceHistory] = useState([]); 
  const [product, setProduct] = useState([]);
  const [loader, setLoader] = useState(false);
  const [vehicle, setVehicle] = useState([]);
  const [isvehicleModalOpen, setIsvehicleModalOpen] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);
  const [isVehicleEditModalOpen, setIsVehicleEditModalOpen] = useState(false);
  const [Deliveryagentdetails, setDeliveryagentdetails] = useState([]);
  const [isdeliveryModalOpen, setIsdeliveryModalOpen] = useState(false);
  const [initialSetupData, setInitialSetupData] = useState(""); // State to store initial setup data

  const [openstock, setOpenstock] = useState([]);
  const [searchDate, setSearchDate]= useState(currentDate);
  const [isopenstockModalOpen, setIsopenstockModalOpen] = useState(false);

  const steps = ["PRODUCT", "FLEET", "STAFF", "OPENSTOCK","SUCCESS", ];


  const url = "/api/createAdminAccount";
  const [admindata, setAdata] = useState([
    {
      username: "",
      role: "",
      existOrg: "",
      organization: {
        name: "",
        orgcode: "",
        gstin: "",
        pan: "",
        tan: "",
        contact: "",
        userid: "",
        logo:"",
        imageMeta: "",
        addressLine1:"",
        addressLine2:"",
        city:"",
        pincode:"",
        state:""
      },
    },
  ]);
  const [loading, setLoading] = useState(false);


  const fetchInitialSetupData = async () => {
    const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      // Fetch initial setup data
      const response = await axios.get(`/api/initialsetup/orgcode=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

     setInitialSetupData(response.data);
    } catch (error) {
      handleError(error);
    }
  };

  const onClickhandlerdelete = async (e, vid, vtruckinfo, vlicenceNo) => { 
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(vid);
    try{
      await axios({
      url: "/api/vehicle",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${vid}`,
        truckinfo: `${vtruckinfo}`,
        licenceNo: `${vlicenceNo}`,
        orgid: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
      },
    });
    toast.success("deleted successfully");
    setLoading(false); // Set loading to false after successful delete
    getData();
  } catch (error) {
    console.error("Error deleting vehicle:", error);
    setLoading(false); // Set loading to false even if there's an error
  }
};
const handleDeleteAccount = async (id) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      await axios.delete(`/api/accounts/delete/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      toast.success("Account Deleted successfully");
      getData();
    } catch (error) {
      console.error("Error deleting Account:", error);
      toast.error("Error deleting Account");
    }
  };

  const handleNext = async () => {
    let hasErrors = false; // Variable to track if there are validation errors
  
    if (activeStep === 0) {
      if (product.length === 0) {
        toast.error("Please add a product.");
        hasErrors = true;
      } else {
        const token = JSON.parse(localStorage.getItem("data")).token;
        try {
          const response = await axios.post(`/api/initialsetup/update`, { id: initialSetupData.id,product:true, acctivated: initialSetupData.acctivated,fleet:initialSetupData.fleet,staff:initialSetupData.staff,
            openstock:initialSetupData.openstock}, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setInitialSetupData(response.data);
        } catch (error) {
          handleError(error);
        }
      }
    } else if (activeStep === 1) {
      if (vehicle.length === 0) {
        toast.error("Please add  vehicle.");
        hasErrors = true;
      } else {
        const token = JSON.parse(localStorage.getItem("data")).token;
        try {
          const response = await axios.post(`/api/initialsetup/update`, { id: initialSetupData.id,fleet:true, acctivated: initialSetupData.acctivated,
            product:initialSetupData.product,staff:initialSetupData.staff,
            openstock:initialSetupData.openstock}, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setInitialSetupData(response.data);
        } catch (error) {
          handleError(error);
        }
      }
    } else if (activeStep === 2) {
      if (Deliveryagentdetails.length === 0) {
        toast.error("Please Add Staff.");
        hasErrors = true;
      } else {
        const token = JSON.parse(localStorage.getItem("data")).token;
        try {
          const response = await axios.post(`/api/initialsetup/update`, { id: initialSetupData.id, staff: true,fleet:initialSetupData.fleet,acctivated: initialSetupData.acctivated,
            product:initialSetupData.product,
            openstock:initialSetupData.openstock}, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setInitialSetupData(response.data);
        } catch (error) {
          handleError(error);
        }
      }
  } else if (activeStep === 3) {
    if (openstock.length === 0) {
      toast.error("Please Add Opening Stock.");
      hasErrors = true;
    } else {
      const token = JSON.parse(localStorage.getItem("data")).token;
      try {
        const response = await axios.post(`/api/initialsetup/update`, { id: initialSetupData.id, openstock: true, acctivated: initialSetupData.acctivated,
          product:initialSetupData.product,staff:initialSetupData.staff,fleet:initialSetupData.fleet}, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setInitialSetupData(response.data);
      } catch (error) {
        handleError(error);
      }
    }
  }

    
  
    if (!hasErrors) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }


  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    if (activeStep === 2) {
        if (
          admindata.contact &&
          admindata.addressLine1 &&
          admindata.city &&
          admindata.pincode &&
          admindata.state 
        ) {
    axios
      .post(url, {
        username: admindata.username,
        role: "ROLE_ADMIN",
        organization: {
          name: admindata.name,
          orgcode: admindata.orgcode,
          gstin: admindata.gstin,
          pan: admindata.pan,
          tan: admindata.tan,
          contact: admindata.contact,
          logo: admindata.logo,
          imageMeta:admindata.imageMeta,
          addressLine1: admindata.addressLine1,
          addressLine2: admindata.addressLine2,
          city: admindata.city,
          pincode: admindata.pincode,
          state: admindata.state,
        }
      }, {
          headers: { Authorization: `Bearer ${token}` }
      })
      .then((res) => {
        console.log(res.data.roleRequested);
        setLoading(false);
        const regiteredusername = {
          username: admindata.username,
          orgcode: admindata.orgcode,
        };
        localStorage.setItem(
          "RegistrationData",
          JSON.stringify(regiteredusername)
        );
        setAdata([
          {
            username: "",
            role: "",
            existOrg: "",
            organization: {
              name: "",
              orgcode: "",
              gstin: "",
              pan: "",
              tan: "",
              contact: "",
              userid: "",
              logo:"",
              imageMeta: "",
              addressLine1:"",
              addressLine2:"",
              city:"",
              pincode:"",
              state:""
            },
          },
        ]);
        setAdata([]);
        toast("Admin account created successfully and Password sent to registered email");
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          if (error.response.status === 400) {
            toast.error("Bad Request: " + error.response.data.message);
          } else if (error.response.status === 500) {
            toast.error("Email already Registered");
          } else if (error.response.status === 401) {
            toast.error("Unauthorized: " + error.response.data.message);
            navigate("/signin");
          } else {
            toast.error("Error: " + error.response.data.message);
          }
        } else if (error.message) {
          toast.error("Error: " + error.message);
        } else {
          toast.error("An error occurred.");
        }
        setLoading(false);
        setAdata([]);
      });
    } else {
        setLoading(false);
        // toast.error("Please fill out all the required fields.");
    }
} else {
    setLoading(false);
    // Handle other steps if needed
}
}
  

  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {
        const [metadata, content] = reader.result.split(",");

        resolve({ metadata, content });
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  const handleImageChange = async (event) => {
    const convertedImage = await getBase64FromFile(event.target.files[0]);

    const imageObject = {
      ...admindata,

      imageMeta: convertedImage.metadata,

      logo: convertedImage.content,
    };

    setAdata(imageObject);
  };
  function rhandle(e) {
    const newdata = { ...admindata };
    newdata[e.target.id] = e.target.value;
    setAdata(newdata);
  }

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);

    try {
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const url = `/api/product/orgcode=${orgCode}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProduct([...response.data]);
      const vehicles = await axios.get(
        `/api/vehicle/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
 
      setLoader(false);
      setVehicle(vehicles.data);
      const trans = await axios.get( `/api/accounts/staff/orgid=${JSON.parse(localStorage.getItem("data")).orgcode
    }`, {
        headers: { Authorization: `Bearer ${token}` },
      });
   
      console.log(trans);
   
      setDeliveryagentdetails(trans.data);
      setVehicle(vehicles.data);
      const response1 = await axios.get(
        `/api/OpeningstockDo/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${currentDate}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    
      setOpenstock(response1.data.prodDo);
      console.log(response1.data);
    
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching data");
    }
    setLoader(false);
  };
  useEffect(() => {
    fetchInitialSetupData();
    getData();
  }, [isModalOpen]);
 
  const onClickhandler = async (e, id) => {
    setLoading(id);
    const checked = e.target.checked;
    const token = JSON.parse(localStorage.getItem("data")).token;

    try {
      await axios.get(`/api/product/productUpdate/id=${id}/value=${checked}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLoading(null);
      toast.success("Product status updated");
      getData();

    } catch (error) {
      handleError(error);
    }
  };
  const handleAddProduct = () => {
    setIsModalOpen(true);
    setProduct([]);


  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleProductEdit = async (productId) => {
    setSelectedProductId(productId);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/ProductPrice/productid=${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPriceHistory(response.data);
      setIsProductEditModalOpen(true);
    } catch (error) {
    //   handleError(error);
    }
  };
 
  const closeProductEditModal = () => {
    setIsProductEditModalOpen(false);
    getData();
 
  };
  const Vehicleedit = async (id) => {
    setSelectedVehicleId(id);
    setIsVehicleEditModalOpen(true);
 
  }
  const closeVehicleEditModal = () => {
    setSelectedVehicleId(null);
 
    setIsVehicleEditModalOpen(false);
    getData();
 
  };
  const handleaddvehicle = () => {
    setIsvehicleModalOpen(true);
  };
 
  const handlevehicleCloseModal = () => {
    setIsvehicleModalOpen(false);
    getData();
  }
  const handleregistermd = () => {
    setIsdeliveryModalOpen(true);


  };

  const handledeliveryCloseModal = () => {
    setIsdeliveryModalOpen(false);
    getData();

  };
  const updateStockDateHandler = () => {

    setIsopenstockModalOpen(true);


  };

  const handleopenstockCloseModal = () => {
    setIsopenstockModalOpen(false);
    getData();
  };

  const handledashboard = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
  
    try {
      // Update initial setup data to set "activated" to true
      const response = await axios.post(`/api/initialsetup/update`, {
        id: initialSetupData.id,
        acctivated: true,
        product: initialSetupData.product,
        fleet: initialSetupData.fleet,
        staff: initialSetupData.staff,
        openstock: initialSetupData.openstock,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setInitialSetupData(response.data); // Store fetched data in state
  
      // Check conditions for navigation
      const isFleetTrue = response.data.fleet === true;
      const isProductTrue = response.data.product === true;
      const isStaffTrue = response.data.staff === true;
      const isOpenstockTrue = response.data.openstock === true;
      const isActivatedTrue = response.data.acctivated === true;
  
      if (isFleetTrue && isProductTrue && isStaffTrue && isOpenstockTrue && isActivatedTrue) {
        navigate("/users/admindashboardwithanalytics");
      } else {
        toast.error("Please ensure all conditions are met.");
      }
    } catch (error) {
      handleError(error);
    }
  };
  
  
  
  
  return (
    <div style={{ marginRight:"200px"}}>
    <br/>
            <br/>
            <br/>
            <br/>
           
    <div className="col-12 mx-xl-auto ps-xl-10">
      <div className="card card-raised">
        <div className="card-body">
          <div className="d-flex">
            <div className="card-title">Create 
            </div>{" "}
                        <br/>
                      </div>

                           {/* Stepper */}
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>   

         {/* Form sections */}                        <br></br><br></br>

         <div className="form-section">
          {activeStep === 0 && (

                       <form onSubmit={(e) => rsubmit(e)}>
                    <span style={{ marginLeft: '730px' }}></span>

<button className="btn btn-primary"onClick={handleAddProduct}>
               ADD
            </button>
             {/* Product table */}
             <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                  }} 
                >    
                 <th>Product Type</th>

                      <th> Product Name</th>
                      <th> Product Code</th>
                      <th>Weight</th>
                      <th>Sales Price</th>

                      <th>Additional Price</th>
                      <th>New Connection</th>
                      <th>Edit</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {product.length === 0 ? (
                      <tr>
                        <td colSpan="9" className="text-center">
                          No Data Available
                        </td>
                      </tr>
                    ) : (
                      product.map((product) => (
                        <tr key={product.productid}>
                          <td>{product.productType}</td>

                          <td>{product.productname}</td>
                          <td>{product.productcode}</td>
                          <td>{product.netWeight} Kg</td>
                          <td>{product.pprice.price}</td>
                          <td>{product.addPrice.additionalPrice}</td>
                          <td>{product.newConPrice.newConPrice}</td>
                          <td>
                            <button
                              onClick={() => handleProductEdit(product.productid)}
                              style={{
                                border: "none",
                                background: "white",
                                size: "150px",
                              }}
                            >
                             <BiEditAlt />
                            </button>
                          </td>
                          <td>
                            <div className="form-check form-switch">
                              {loading === product.productid && (
                                <div className="loader"></div>
                              )}
                              <input
                                className="form-check-input"
                                id="flexSwitchCheckDefault"
                                type="checkbox"
                                defaultChecked={product.productstatus}
                                onClick={(e) => onClickhandler(e, product.productid)}
                                value={product.productid}
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                <br />
                <div className="form-group d-flex justify-content-end">
                  <button className="btn btn-primary" type="button" onClick={handleNext}>
                    Next 
                  </button>
                </div>
              </form>
            )}

       {activeStep ===1 && (
       <form onSubmit={(e) => rsubmit(e)}>
                            <span style={{ marginLeft: '900px' }}></span>

         <button className="btn btn-primary float-end" onClick={handleaddvehicle}>
 
 ADD</button>
 <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th>Vehicle Number</th>
                  <th>Device</th>
                 
                  <th>Edit</th>
                  <th>Delete Vehicle</th>
                </tr>
              </thead>
              <tbody>
              {vehicle.length === 0 ? (
            <tr>
              <td colSpan="7" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            vehicle.map((vehicle) => {
                  return (
                    <tr key={vehicle.vehicleid}>
                    <td>{vehicle.truckinfo}
                   
                      </td>
                    <td>{vehicle.deviceid}</td>

                
                 
                      <td>
                        <button
                          onClick={() => Vehicleedit(vehicle.vehicleid)}
                          style={{
                            border: "none",
                            background: "white",
                            size: "150px",
                          }}
                        >
                          <BiEditAlt />
                        </button>
                      </td>
                      <td>
                        <button
                          style={{
                            border: "none",
                            background: "white",
                            color: "grey",
                            position: "relative",
                            left: "20px",
                            fontSize: "30px",
                          }}
                          onClick={(e) => {
                            onClickhandlerdelete(
                              e,
                              vehicle.vehicleid,
                              vehicle.truckinfo,
                              vehicle.licenceNo
                            );
                          }}
                        >
                          <MdDeleteForever />
                        </button>{" "}
                   
                      </td>
                    </tr>
                  );
                }))}
              </tbody>
            </table>
                     

                          <br></br>
                          <div className="form-group d-flex align-items-center justify-content-between">
                          <input
                      className="btn btn-primary"
                      type="button"
                      onClick={handleBack}
                      value="Back"
                    />
                         
                          
<input
  className="btn btn-primary"
  type="button"
  onClick={handleNext}
  value="Next ->"
/>
</div>

                          </form>
      )}
                          {activeStep ===2 && (
       <form onSubmit={(e) => rsubmit(e)}>
                         <button className="btn btn-primary float-end" onClick={handleregistermd}>
               ADD
            </button>
            <br/>
            <br/>
            <Row>
            {Deliveryagentdetails.map((accd) => (
              
            <Col key={accd.id} md={4} className="mb-3">
               <div className="card card-raised h-100">
                 <div className="card-body">
            <Card>
                <Card.Body>
                {/* <Card.Text>Id: {accd.id}</Card.Text> */}

                  <Card.Text>UserName: {accd.username}</Card.Text>
                  <Card.Text>Name: {accd.name}</Card.Text>
                  <Card.Text>Contact: {accd.phNo}</Card.Text>
                  <Card.Text>Role: {accd.role}</Card.Text>
                  <Card.Text>Status:
                          {loading == accd.id && (
                            <div
                              className="loader"
                              style={{
                                position: "relative",
                                left: "55px",
                                top: "8px",
                              }}
                            ></div>
                          )}{" "}
                          <input
                            className="form-check-input"
                            id="flexSwitchCheckDefault"
                            type="checkbox"
                            defaultChecked={!accd.disabled}
                            onClick={(e) => onClickhandler(e, accd.id, !accd.disabled)}
                            value={accd.id}
                          />
                                           </Card.Text>
                  <div className="d-flex justify-content-between">
                   
                    <MdDeleteForever
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={() => handleDeleteAccount(accd.id)}
                      title="Delete Account"
                    />
                  </div>
                </Card.Body>
              </Card>
              </div>
              </div>
              
              </Col>
              
          ))}
                </Row> 
                          <br/>  
                              
                                    
                  <div className="form-group d-flex align-items-center justify-content-between">
                      <input
                      className="btn btn-primary"
                      type="button"
                      onClick={handleBack}
                      value="Back"
                    />
                    <input
  className="btn btn-primary"
  type="button"
  onClick={handleNext}
  value="Next ->"
/>
  
                     
                    </div>
        </form>
      )}
       {activeStep === 3 && (
  <div>
     
                 
                    {/* <span style={{ marginLeft: '65px' }}></span> */}

                              
             <div style={{ position: "relative", marginLeft: "75%" }}>
             
                <button className="btn btn-primary" onClick={(e) => updateStockDateHandler(e, searchDate)}>Update Stock</button>
              
            </div>
            
            <br />
            
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th id="date">Product</th>

                  <th id="full">Full</th>

                  <th id="empty">Empty</th>
                  <th id="defective">Defective</th>
                </tr>{" "}
              </thead>
              <tbody>
              {openstock.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
                
                  openstock.map((products) => {
                    return (
                      <tr>
                        <th>{products.product.productname}</th>
                        {products.pdo.map((ps, index) => {
                          return <th key={index}>{ps.qty}</th>;
                        })}
                      </tr>
                    );
                  }))}
              </tbody>
            </table>
            
    <br></br>
    <br></br>
                          <div className="form-group d-flex align-items-center justify-content-between">
                          <input
                      className="btn btn-primary"
                      type="button"
                      onClick={handleBack}
                      value="Back"
                    />

<input
  className="btn btn-primary"
  type="button"
  onClick={handleNext}
  value="Next ->"
/>   

</div>
  </div>
)}
      {activeStep === 4 && (
  <div>
    <h2>Success</h2>
   
    <br></br>
    <br></br>
                          <div className="form-group d-flex align-items-center justify-content-between">
                          <input
                      className="btn btn-primary"
                      type="button"
                      onClick={handleBack}
                      value="Back"
                    />

                <input  onClick={handledashboard}
                      className="btn btn-primary"
                      type="submit"
                      value=" Go To Dashboard"
                    />          
                          

</div>
  </div>
)}
      
      </div>
                    </div>
                  </div>
                  {isProductEditModalOpen && (
              <ProducteditModalPopup
                isOpen={isProductEditModalOpen}
                onRequestClose={closeProductEditModal}
                productId={selectedProductId}
              />
            )}
            {isModalOpen && (
           <AddproductModal isOpen={isModalOpen} onRequestClose={handleCloseModal} />
            )}
           {isVehicleEditModalOpen && (
           <VehicleeditsModal
        isOpen={isVehicleEditModalOpen}
        onRequestClose={closeVehicleEditModal}
        id={selectedVehicleId} // Pass id as a parameter
        />
           )}
           {isvehicleModalOpen && (
             <AddVehiclesmodal isOpen={isvehicleModalOpen} onRequestClose={handlevehicleCloseModal} />
           )}
           {isdeliveryModalOpen && (
             <Register isOpen={isdeliveryModalOpen} onRequestClose={handledeliveryCloseModal} />
           )}
             {isopenstockModalOpen && (
             <Updateopenstockmodal isOpen={isopenstockModalOpen} onRequestClose={handleopenstockCloseModal} />
             )}

                </div>
              </div>
             
  );
}
export default Activitycheck;
