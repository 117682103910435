import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';  
import axios from "../api/baseurl";
import { toast } from "react-toastify";


const PerformModal = ({ isOpen, onRequestClose, performdate }) => {
  const [attendencedetails, setAttendencedetails] = useState(null);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [sqcDetails, setSqcDetails] = useState(null);
  const [closestocktwo, setClosestocktwo] = useState([]);
  const [openingstock, setOpeningstock] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
        
        // Fetch attendance details
        const attendanceResponse = await axios.get(
          `/api/attendance/orgcode=${orgcode}/date=${performdate}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setAttendencedetails(attendanceResponse.data);

        // Fetch transaction details
        const transactionResponse = await axios.get(
          `/api/transactionoutgoing/orgcode=${orgcode}/date=${performdate}/checkAllTransactionOutAccountedInTransactionIn`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setTransactionDetails(transactionResponse.data);

        // Fetch SQC details
        const sqcResponse = await axios.get(
          `/api/sqcform/orgcode=${orgcode}/date=${performdate}/checkAllLoadInTrucksLinkedWithSqc`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setSqcDetails(sqcResponse.data);

        const openingStockResponse = await axios.get(
          `/api/Openingstock/orgcode=${orgcode}/datetime=${performdate}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setOpeningstock(openingStockResponse.data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.response ? error.response.data.message : "An error occurred");
        setLoading(false);
      }
    };

    fetchData();
  }, [performdate]);

  // Check if all details are true
  const allDetailsTrue = attendencedetails?.attendancestatus && transactionDetails && sqcDetails;


  const onclickhandler = async (e) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("data")).token;
 
    setLoading(true);
      {
     
 
      await axios
        .post("/api/CloseStock/update", openingstock, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log(res);
          setLoading(false);
          setClosestocktwo(res.data);
          window.location.reload();
          toast.success("Transaction End and Closestock updated Successfully");
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.message);
        })
        .finally(() => {
          setLoading(false); // Set loading to false after the API call completes (both success and failure)
        });
      }
  };
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Perform Day End</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>Error: {error.message}</p>
        ) : (
          <div>
            <p>Attendance: {attendencedetails?.attendancestatus ? '✓' : '❌'}</p>
            <p>Transaction: {transactionDetails ? '✓' : '❌'}</p>
            <p>SQC Details: {sqcDetails ? '✓' : '❌'}</p>
            {allDetailsTrue && (
                <button className="btn btn-primary" onClick={(e) => onclickhandler(e)}>
                Process
               </button>
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onRequestClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PerformModal;
