import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";

import csvFormat from '../images/csvUploadFormat.png';

const CsvFormat = ({ isOpen, onRequestClose }) => {
  

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>CSV Format</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
      <img src={csvFormat} alt="CSV Format" style={{ width: '100%' }} />
      </div>
      <br/>
      <h6>
        Follow this Format for CSV upload.
      </h6>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

export default CsvFormat;
