import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
 
function ProducteditModalPopup({ isOpen, onRequestClose, productId }) {
  const [uproduct1, setUproduct1] = useState({
    id: "",
    productname: "",
    productcode: "",
    productType:"",
    netWeight:"",
    date: "",
    pprice:{
      price:0,
    },
    addPrice:{
      additionalPrice:0,
    },
    newConPrice:{
      newConPrice:0,
    },
 orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });
 
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
 
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    try {
      const response = await axios.get(`/api/product/productid=${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUproduct1(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
 
  useEffect(() => {
    if (productId) {
      getData();
    }
  }, [productId]);
 
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    axios
      .post(
        "/api/product/update",
        uproduct1,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Product updated successfully");
        onRequestClose();
        navigate("/users/productdetails");
        getData();
 
      })
      .catch((error) => {
        console.log(error);
      });
  }
 
  const rhandle = (event, field) => {
    const actualValue = event.target.value;

    if (field === 'price') {
      setUproduct1({
        ...uproduct1,
        pprice: {
          ...(uproduct1.pprice || {}),
          price: actualValue,
        },
      });
    } else if (field === 'additionalPrice') {
      setUproduct1({
        ...uproduct1,
        addPrice: {
          ...(uproduct1.addPrice || {}),
          additionalPrice: actualValue,
        },
      });
    } else if (field === 'newConPrice') {
      setUproduct1({
        ...uproduct1,
        newConPrice: {
          ...(uproduct1.newConPrice || {}),
          newConPrice: actualValue,
        },
      });
    } else {
      setUproduct1({
        ...uproduct1,
        [field]: actualValue,
      });
    }
  };
 
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Update Product</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="col-xl-10 mx-xl-auto">
        <div className="card card-raised mb-3">
          <div className="card-body p-4">
            <form onSubmit={(e) => rsubmit(e)}>
            <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="productType">Product Type</label>
                  <input
                    className="form-control"
                    id="productType"
                    type="text"
                    placeholder="product type"
                    value={uproduct1.productType}
                    onChange={(e) => rhandle(e, "productType")}
                  />
                  </div>
              </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="productname">Product Name</label>
                  <input
                    className="form-control"
                    id="productname"
                    type="text"
                    placeholder="productname"
                    value={uproduct1.productname}
                    onChange={(e) => rhandle(e, "productname")}
                  />
                  </div>
              </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="productname">Product Code</label>
                  <input
                    className="form-control"
                    id="productcode"
                    type="text"
                    placeholder="productcode"
                    value={uproduct1.productcode}
                    onChange={(e) => rhandle(e, "productcode")}
                  />
                  </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="netweight">Net Weight</label>
                  <input
                    className="form-control"
                    id="netWeight"
                    type="text"
                    placeholder="net weight"
                    value={uproduct1.netWeight}
                    onChange={(e) => rhandle(e, "netWeight")}
                    disabled={uproduct1.productType === "Consumables"}
                  />
                  </div>
                  </div>
              </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="productname">Sales  Price</label>
                  <input
                    className="form-control"
                    id="price"
                    type="number"
                    placeholder="0"
                    value={uproduct1.pprice.price}
                    onChange={(e) => rhandle(e, "price")}
                  />
                  </div>
              </div>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="additionalprice">Additional Price</label>
                  <input
                    className="form-control"
                    id="additionalPrice"
                    type="number"
                    placeholder="0"
                    value={uproduct1.addPrice.additionalPrice}
                    onChange={(e) => rhandle(e, "additionalPrice")}
                    disabled={uproduct1.productType === "Consumables"}
                  />
                  </div>
              </div>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="newconnectionprice">New Connection Price</label>
                  <input
                    className="form-control"
                    id="price"
                    type="number"
                    placeholder="0"
                    value={uproduct1.newConPrice.newConPrice}
                    onChange={(e) => rhandle(e, "newConPrice")}
                    disabled={uproduct1.productType === "Consumables"}
                  />
                  </div>
              </div>
                  <br />
                  <input
                    type="submit"
                    value="Update"
                    className="btn btn-primary"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
                
            </form>
          </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer></Modal.Footer>
  </Modal>
  );
}
 
export default ProducteditModalPopup;