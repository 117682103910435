import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../api/baseurl";
import { toast } from "react-toastify";

const ConsumableModalOpen = ({ isOpen, onRequestClose  ,productType, date }) => {
  // const date = new Date();
  // const date1 = new Date();
  // date.setDate(date.getDate());
  // date1.setDate(date1.getDate());
  // let day = date.getDate().toString().padStart(2, "0");
  // let month = (date.getMonth() + 1).toString().padStart(2, "0");
  // let year = date.getFullYear();
  // let currentDate = `${year}-${month}-${day}`;
  const initialProductState = {
    productType:"",
    productname: "",
    productcode: "",
    quantity: "",
    price: 0,
    date:date,
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    total:0
  };
  const initialProductState1 = {
    date:date,
    productType:"",
    productName: "",
    productCode: "",
    sale: "",
    newconnection: "",
    additional: "",
    exempted: "",
    onlinepay: "",
    transfer: "",
    total: 0,


    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,

  };

  const [newProduct, setNewProduct] = useState(initialProductState);
  const [newProduct1, setNewProduct1] = useState(initialProductState1);

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (isOpen) {
      fetchProducts(); // Fetch products when modal opens
    }
  }, [isOpen]);

  const fetchProducts = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;

      const response = await axios.post(
        `/api/product/getbyproducttype`, {
          orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
          productType,
          productstatus: true
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleChange = async (e) => {
    const { id, value } = e.target;
    if (id === "productname") {
      const selectedProduct = products.find((product) => product.productname === value);
      if (selectedProduct) {
        try {
          const token = JSON.parse(localStorage.getItem("data")).token;
          const orgId = JSON.parse(localStorage.getItem("data")).orgcode;
        
          let price;
  
          // Fetch price from api/officefinancial if data is present
          const financialResponse = await axios.post(`/api/officefinancial/getbyproductname`, 
          {
            orgid:orgId,
            productname:value,
            date:newProduct.date
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          });
          price = financialResponse.data.salecost;
  
          // If price is not available from api/officefinancial, fetch it from api/product
          if (!price) {
            const productResponse = await axios.get(`/api/product/orgcode=${orgId}/productcode=${selectedProduct.productcode}/date=${newProduct.date}`, {
              headers: { Authorization: `Bearer ${token}` },
            });
            price = productResponse.data.pprice.price;
          }
  
          setNewProduct((prevData) => ({
            ...prevData,
            [id]: value,
            productcode: selectedProduct.productcode,
            price: price,
            total: (value && prevData.quantity) ? parseFloat(price) * parseFloat(prevData.quantity) : 0,
          }));
        } catch (error) {
          console.error("Error fetching price:", error);
        }
      }
    } else if (id === "quantity") {
      setNewProduct((prevData) => ({
        ...prevData,
        [id]: value,
        total: (value && prevData.price) ? parseFloat(value) * parseFloat(prevData.price) : 0,
      }));
    } else {
      setNewProduct((prevData) => ({
        ...prevData,
        [id]: value,
        total: (prevData.productname && value) ? parseFloat(prevData.productname) * parseFloat(value) : 0,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.post("/api/consumableproduct/create", newProduct, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data);
      setLoading(false);
      onRequestClose();
      setNewProduct(initialProductState);
      toast.success("Office Consumable sales created successfully");
    } catch (error) {
      console.error("Error creating consumable product:", error);
      setLoading(false);
    }
  };
  const handleChange1 = async (e) => {
    const { id, value } = e.target;
    console.log("Handling change for field:", id, "with value:", value);
    
    if (id === "productName") {
        // Logic for fetching price based on the selected product name
        console.log("Product name changed. Fetching price...");
        const selectedProduct1 = products.find((product) => product.productname === value);
        console.log("Selected Product:", selectedProduct1);
        
        if (selectedProduct1) {
            try {
                const token = JSON.parse(localStorage.getItem("data")).token;
                const orgId = JSON.parse(localStorage.getItem("data")).orgcode;
               
                let price = 0;
                let additionalPrice = 0;
                let newConPrice = 0;

                // Fetch price from api/officefinancial if data is present
                const financialResponse = await axios.post(`/api/officefinancial/getbyproductname`, 
                {
                  orgid:orgId,
                  productname:value,
                  date:newProduct1.date
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                });

                console.log("Financial Response:", financialResponse.data);

                if (financialResponse.data) {
                    price = financialResponse.data. salecost|| 0;
                    additionalPrice = financialResponse.data.additionalcost|| 0;
                    newConPrice = financialResponse.data.newconection || 0;
                }

                // If price is not available from api/officefinancial, fetch it from api/product
                if (!price && !additionalPrice && !newConPrice) {
                    const productResponse = await axios.get(`/api/product/orgcode=${orgId}/productcode=${selectedProduct1.productcode}/date=${newProduct1.date}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });

                    price = productResponse.data.pprice?.price || 0;
                    additionalPrice = productResponse.data.addPrice?.additionalPrice || 0;
                    newConPrice = productResponse.data.newConPrice?.newConPrice || 0;
                }

                console.log("Prices:", { price, additionalPrice, newConPrice });

                setNewProduct1((prevData) => ({
                    ...prevData,
                    [id]: value,
                    productCode: selectedProduct1.productcode,
                    price: price,
                    additionalPrice: additionalPrice,
                    newConPrice: newConPrice,
                    total: (
                      (value && prevData.sale && prevData.additional && prevData.newconnection) ?
                      (parseFloat(prevData.sale) * parseFloat(price) + parseFloat(prevData.additional) * parseFloat(additionalPrice) + parseFloat(prevData.newconnection) * parseFloat(newConPrice)) :
                      0
                  ),
              }));
            } catch (error) {
                console.error("Error fetching price:", error);
            }
        }
    } else {
        // Logic for updating other fields and total calculation
        console.log("Other field changed. Updating state...");
        setNewProduct1((prevData) => {
          const sale = id === "sale" ? (value ? parseFloat(value) : 0) : prevData.sale || 0;
          const additional = id === "additional" ? (value ? parseFloat(value) : 0) : prevData.additional || 0;
          const newconnection = id === "newconnection" ? (value ? parseFloat(value) : 0) : prevData.newconnection || 0;
          const exempted = id === "exempted" ? (value ? parseFloat(value) : 0) : prevData.exempted || 0;
          const onlinepay = id === "onlinepay" ? (value ? parseFloat(value) : 0) : prevData.onlinepay || 0;
          const transfer = id === "transfer" ? (value ? parseFloat(value) : 0) : prevData.transfer || 0;
            const total = (sale * prevData.price) + (additional * prevData.additionalPrice) + (newconnection * prevData.newConPrice);

            return {
                ...prevData,
                [id]: value,
                sale:sale,
                additional:additional,
                newconnection:newconnection,
                exempted: exempted,
                onlinepay: onlinepay,
                transfer: transfer,
                total: total,
            };
        });
    }
};
  const handleSubmit1 = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.post("/api/refillproduct/create", newProduct1, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data);
      setLoading(false);
      onRequestClose();
      setNewProduct1(initialProductState1);
      toast.success("Office Refill sales created successfully");
    } catch (error) {
      console.error("Error creating Refill product:", error);
      setLoading(false);
    }
  };

  
  return (
    <>
    {productType === "Consumables" ? (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Create Consumable Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                <div className="row mb-3">
                  <div className="form-floating-sm">
                  <div className="row mb-3">
                  <div className="form-floating-sm">
                    <label htmlFor="date">Date</label>
                    <input
                      className="form-control"
                      id="date"
                      type="date"
                      placeholder="date"
                      value={newProduct.date}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                    <label htmlFor="productname">Product Name</label>
                    <select
                      className="form-select"
                      id="productname"
                      value={newProduct.productname}
                      onChange={handleChange}
                    >
                      <option value="">Select Product Name</option>
                      {products.map((product) => (
                        <option key={product.id} value={product.productname}>
                          {product.productname}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="form-floating-sm">
                    <label htmlFor="productcode">Product Code</label>
                    <input
                      className="form-control"
                      id="productcode"
                      placeholder="Product Code"
                      value={newProduct.productcode}
                     // onChange={handleChange}
                    />
                     
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="form-floating-sm">
                    <label htmlFor="quantity">Quantity</label>
                    <input
                      className="form-control"
                      id="quantity"
                      type="text"
                      placeholder="Quantity"
                      value={newProduct.quantity}
                      onChange={handleChange}
                    />
                  </div>
                </div>
               

                <div className="row mb-3">
                  <div className="form-floating-sm">
                    <label htmlFor="price">Price</label>
                    <input
                      className="form-control"
                      id="price"
                      type="number"
                      placeholder="Price"
                      value={newProduct.price}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="form-floating-sm">
                    <label htmlFor="total">Total</label>
                    <input
                      className="form-control"
                      id="total"
                      type="text"
                      placeholder="total"
                      value={newProduct.total}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col">
                    <Button variant="primary" type="submit" disabled={loading}>Save</Button>
                    <Button variant="secondary" onClick={onRequestClose} className="ms-2">Close</Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
       
      </Modal.Footer>
    </Modal>
    ) : (
     <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
     <Modal.Header closeButton>
       <Modal.Title>Create Refill Product</Modal.Title>
     </Modal.Header>
     <Modal.Body>
       <div className="col-xl-10 mx-xl-auto">
         <div className="card card-raised mb-3">
           <div className="card-body p-4">
             <form onSubmit={handleSubmit1}>
               <div className="row mb-3">
                 <div className="form-floating-sm">

                 <div className="row mb-3">
                  <div className="form-floating-sm">
                    <label htmlFor="date">Date</label>
                    <input
                      className="form-control"
                      id="date"
                      type="date"
                      placeholder="date"
                      value={newProduct1.date}
                      onChange={handleChange1}
                    />
                  </div>
                </div>
                   <label htmlFor="productname">Product Name</label>
                   <select
                     className="form-select"
                     id="productName"
                     value={newProduct1.productName}
                     onChange={handleChange1}
                   >
                     <option value="">Select Product Name</option>
                     {products.map((product) => (
                       <option key={product.id} value={product.productname}>
                         {product.productname}
                       </option>
                     ))}
                   </select>
                 </div>
               </div>

               <div className="row mb-3">
                 <div className="form-floating-sm">
                   <label htmlFor="productcode">Product Code</label>
                   <input
                     className="form-control"
                     id="productCode"
                     placeholder="Product Code"
                     value={newProduct1.productCode}
                    // onChange={handleChange1}
                   />
                    
                 </div>
               </div>
             

               <div className="row mb-3">
                 <div className="form-floating-sm">
                   <label htmlFor="sale">Sale</label>
                   <input
                     className="form-control"
                     id="sale"
                     type="text"
                     placeholder="0"
                     value={newProduct1.sale}
                     onChange={handleChange1}
                   />
                 </div>
               </div>
               
               <div className="row mb-3">
                 <div className="form-floating-sm">
                   <label htmlFor="newconnection">New Connection</label>
                   <input
                     className="form-control"
                     id="newconnection"
                     type="text"
                     placeholder="0"
                     value={newProduct1.newconnection}
                     onChange={handleChange1}
                   />
                 </div>
               </div>

               <div className="row mb-3">
                 <div className="form-floating-sm">
                   <label htmlFor="additional">Additional</label>
                   <input
                     className="form-control"
                     id="additional"
                     type="text"
                     placeholder="0"
                     value={newProduct1.additional}
                     onChange={handleChange1}
                   />
                 </div>
               </div>
               <div className="row mb-3">
                 <div className="form-floating-sm">
                   <label htmlFor="exempted">Exempted</label>
                   <input
                     className="form-control"
                     id="exempted"
                     type="text"
                     placeholder="0"
                     value={newProduct1.exempted}
                     onChange={handleChange1}
                   />
                 </div>
               </div>
               <div className="row mb-3">
                 <div className="form-floating-sm">
                   <label htmlFor="onlinepay">Onlinepay</label>
                   <input
                     className="form-control"
                     id="onlinepay"
                     type="text"
                     placeholder="0"
                     value={newProduct1.onlinepay}
                     onChange={handleChange1}
                   />
                 </div>
               </div>
               <div className="row mb-3">
                 <div className="form-floating-sm">
                   <label htmlFor="transfer">Transfer</label>
                   <input
                     className="form-control"
                     id="transfer"
                     type="text"
                     placeholder="0"
                     value={newProduct1.transfer}
                     onChange={handleChange1}
                   />
                 </div>
               </div>
               <div className="row mb-3">
                 <div className="form-floating-sm">
                   <label htmlFor="total">Total</label>
                   <input
                     className="form-control"
                     id="total"
                     type="text"
                     placeholder="total"
                     value={newProduct1.total}
                    // onChange={handleChange1}
                   />
                 </div>
               </div>

               <div className="row mt-4">
                 <div className="col">
                   <Button variant="primary" type="submit" disabled={loading}>Save</Button>
                   <Button variant="secondary" onClick={onRequestClose} className="ms-2">Close</Button>
                 </div>
               </div>
             </form>
           </div>
         </div>
       </div>
     </Modal.Body>
     <Modal.Footer>
      
     </Modal.Footer>
   </Modal>
   )}
   </>
 );
};

export default ConsumableModalOpen;
