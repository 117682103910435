import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import UserProfile from "./userProfile";
import './pagination.css'
import "./Loader.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDeleteForever } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
function Accountsdetails() {
  const [accountsdetails, setAccountsdetails] = useState([]);
  const [userProfileModalIsOpen, setUserProfileModalIsOpen] = useState(false);
  const [userProfileDetails, setUserProfileDetails] = useState("");
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    try{
    const trans = await axios.get("/api/authority", {
      headers: { Authorization: `Bearer ${token}` },
    });

    console.log(trans);

    setAccountsdetails(trans.data);
    setLoading(false);
  }catch(error){
    setLoading(false);
    handleError(error);
  }
  };

  const openUserProfileModal = async (userid) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try{
    const bio = await axios.get(
      `/api/account-bio/userid=${userid}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setUserProfileDetails(bio.data);
    navigate(`/users/userProfile/${userid}`);
  }catch(error){
    handleError(error);
  }

  };

  

  const handleDeleteAccount = async (id) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      await axios.delete(`/api/accounts/delete/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      toast.success("Deleted successfully");
      getData();
    } catch (error) {
      handleError(error);
    }
  };

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  

  const [itemsPerPage, setItemsPerPage] = useState(10); // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);

  // Function to change the current page
  const changePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Function to change the number of items per page
  const changeItemsPerPage = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to the first page when changing items per page
  };

  // Calculate the index of the first and last items of the current page
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;

  // Get the current page's data using array slicing
  const CurrentAccountsdetails = accountsdetails.slice(firstIndex, lastIndex);

  // Calculate the total number of pages
  const totalPages = Math.ceil(accountsdetails.length / itemsPerPage);

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div>
        <Dashboard />
      </div>
      <div>
        <div className="col-11  mx-xl-auto ps-xl-10 mt-10">
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="card-title">Accounts Details</div>
              <br />
              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: "blue",
                      color: "white",
                    }}
                  >
                    <th>User Name </th>
                    <th>Role</th>
                    <th>Status</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {CurrentAccountsdetails.map((accd) => {
                    return (
                      <tr key={accd.id}>
                        <td  style={{ cursor: 'pointer' }}
                        onClick={() => openUserProfileModal(accd.username)}
                        title="View Profile">{accd.username}</td>

                        <td>{accd.role}</td>

                        <td>
                          <button className="btn btn-link" onClick={() => openUserProfileModal(accd.userid)}>
                            View Profile
                          </button>
                        </td>
                        <td className="text-center" style={{fontSize:"24px"}}  onClick={() => handleDeleteAccount(accd.userid)}>
                          <MdDeleteForever/>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            </div>
            <div className="pagination-container">
        {/* Previous arrow */}
        {currentPage > 1 && (
          <button className="btn btn-primary" onClick={() => changePage(currentPage - 1)}>
            Previous
          </button>
        )}

        {/* Current page */}
        <div className="pagination-current">
          &lt;&lt;{currentPage}&gt;&gt;
        </div>

        {/* Next arrow */}
        {currentPage < Math.ceil(accountsdetails.length / itemsPerPage) && (
          <button className="btn btn-primary" onClick={() => changePage(currentPage + 1)}>
            Next
          </button>
        )}
        <div style={{marginLeft:"5%"}}>
        <label>Rows per page</label>
        <select value={itemsPerPage} onChange={changeItemsPerPage}>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20 </option>
          <option value={25}>25 </option>
          <option value={30}>30 </option>
          {/* Add more options if needed */}
        </select>
        </div>
        </div>
            
         
        </div>
       
      </div>
      {loading && <div className="loading"></div>}
    </div>
  );
}

export default Accountsdetails;
