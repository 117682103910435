import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import "./Spinner.css";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
function Viewcsv() {
    const location = useLocation();
    const { date, orgcode, area } = location.state || {};
  
    const [consumerDetails, setConsumerDetails] = useState([]);
    const [deliveryDetails, setDeliveryDetails] = useState([]);
    const [filteredDeliveryDetails, setFilteredDeliveryDetails] = useState([]);
    const [loader, setLoader] = useState(false);
    const [selectedStatusFilter, setSelectedStatusFilter] = useState("");

    
    const getData = async () => {
        const token = JSON.parse(localStorage.getItem("data")).token;
        setLoader(true);

        try {
            const url = `/api/getconsumerdetails`;

            const entity = {
              orgcode: orgcode,
              date: date,
              areaName: area,
          };
      
          const response = await axios.post(
              url, entity, {
                headers: { Authorization: `Bearer ${token}` },
            })
          
            setConsumerDetails(response.data.consumerDetails);
            setDeliveryDetails(response.data.deliveryDetails);
            setFilteredDeliveryDetails(response.data.deliveryDetails);
            setLoader(false);
        } catch (error) {
            handleError(error);
        }
    };

    const handleError = (error) => {
        if (error.response && error.response.status === 404) {
            toast.error(error.response.data.message);
        }
        setLoader(false);
    };
    const handleFilterChange = (e) => {
        const status = e.target.value;
        setSelectedStatusFilter(status);
        if (status === "") {
            setFilteredDeliveryDetails(deliveryDetails);
        } else {
            const filteredDetails = deliveryDetails.filter(
                (product) => product.deliverystatus.toLowerCase() === status.toLowerCase()
            );
            setFilteredDeliveryDetails(filteredDetails);
        }
    };

   

    useEffect(() => {
        getData();
    }, [orgcode, date]);

    return (
      <div>
          <div>
              <AdminDashboard />
          </div>
          <br />
          <br />
          <br />
          <br />

            {loader && <div className="loading"></div>}
            <div className="col-12  mx-xl-auto ps-xl-10">
                <div className="card card-raised">
                    <div className="card-body">
                        <div className="card-title form-group d-flex align-items-center justify-content-between">
                           Customer Details
                        </div>
                        <br/>
                        <div className="d-flex align-items-center" style={{ gap: '20px' }}>
              <span style={{ fontSize: "17px" }}>Date: {date}</span>
              <span style={{ fontSize: "17px" }}>Area Name: {area}</span>
              <div className="filter-dropdown" style={{marginLeft:"100px"}}>
              <label>FILTER BY STATUS :</label>
              <select 
                                    value={selectedStatusFilter}
                                    onChange={handleFilterChange}
                                >
                                    <option value="">ALL</option>
                                    <option value="pending">PENDING</option>
                                    <option value="completed">COMPLETED</option>
                                    <option value="cancelled">CANCELLED</option>
                                </select>
              </div>
            </div>

                        <br />
                        <table className="table table-bordered">
                            <thead>
                                <tr
                                    style={{
                                        fontSize: "17px",
                                        backgroundColor: "blue",
                                        color: "white",
                                    }}
                                >
                                    <th>Consumer Number</th>
                                    <th>Consumer Name</th>
                                    <th>Phone Number</th>
                                    <th>Address1</th>
                                    <th>Address2</th>
                                    <th>Longitude</th>
                                    <th>Latitude</th>
                                    <th>Delivery Status</th>
                                  
                                </tr>
                            </thead>
                            <tbody>
                            {filteredDeliveryDetails.length === 0 ? (
                                    <tr>
                                        <td colSpan="8" style={{ textAlign: "center" }}>No Data Available</td>
                                    </tr>
                                ) : (
                  filteredDeliveryDetails.map((product) => (
                      <tr key={product.consumernumber}>
                        <td>{product.consumernumber}</td>
                        <td>{product.consumerDetails.consumername}</td>
                        <td>{product.consumerDetails.phone}</td>
                        <td>{product.consumerDetails.address1}</td>
                        <td>{product.consumerDetails.address2}</td>
                        <td>{product.consumerDetails.longitude}</td>
                        <td>{product.consumerDetails.latitude}</td>
                        <td>{product.deliverystatus}</td>
                                             
                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Viewcsv;