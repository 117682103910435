import React, { useEffect, useState, useReducer } from "react";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import Sidebar from "./adminsidebar";
import { BsCheckCircle } from "react-icons/bs";
import { BsXCircle } from "react-icons/bs";
import Search from "./search";
import { Link, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./approval.css";
import "./Spinner.css";
import "./Loader.css";

function TransactionOutgoingApproval({ selectedDate }) {
  const [approvals, setApprovals] = useState([]);
  const [product, setProduct] = useState([]);
  const [rapprovals, setRapprovals] = useState([]);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [searchbydate, setSearchbydate] = useState(false);

  const [filterVehicleId, setFilterVehicleId] = useState("");
  const [filterStatus, setFilterStatus] = useState("");

  const [outgoingCurrentPage, setOutgoingCurrentPage] = useState(1);
  const [incomingCurrentPage, setIncomingCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items to show per page

  const applyFilters = () => {
    let filteredApprovals = [...approvals];
    if (filterVehicleId) {
      filteredApprovals = filteredApprovals.filter(approval => approval.vehicle === filterVehicleId);
    }
    if (filterStatus) {
      filteredApprovals = filteredApprovals.filter(approval => approval.approvalStatus === filterStatus);
    }
    return filteredApprovals;
  };

  const handleFilterChange = () => {
    setOutgoingCurrentPage(1);
  };

  const handleOutgoingPrevPage = () => {
    if (outgoingCurrentPage > 1) {
      setOutgoingCurrentPage(outgoingCurrentPage - 1);
    }
  };

  const handleOutgoingNextPage = () => {
    if (outgoingCurrentPage < Math.ceil(applyFilters().length / itemsPerPage)) {
      setOutgoingCurrentPage(outgoingCurrentPage + 1);
    }
  };

  const filteredApprovals = applyFilters();
  const indexOfLastItem = outgoingCurrentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentApprovals = filteredApprovals.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(approvals.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
 
   const getData = async () => {
    if (!selectedDate) return;
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);

    try {
      const [outgoingResponse, productResponse] = await Promise.all([
        axios.get(`/api/vehicleTransactionDetails/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${selectedDate}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`/api/product/productstatus=true/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
      ]);
      setProduct(productResponse.data);
      setApprovals(outgoingResponse.data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoader(false);
    }
  };
 

  useEffect(() => {
    getData();
  }, [selectedDate,reducerValue]);

  function rsubmit(e, ids, status) {
    e.preventDefault();
    setLoading(ids);
  
    const token = JSON.parse(localStorage.getItem("data")).token;
  
    const entitiesToUpdate = ids.map(id => ({
      id: Number(id),
      approvalstatus: status,
    }));
  
    axios
      .post(
        "/api/transactionoutgoing/update-approval-status",
        entitiesToUpdate,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response.data);
        setLoading(null);
        forceUpdate();
      })
      .catch((error) => {
       handleError(error);
      });
  }
 
  const navigate = useNavigate();
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }
  
  return (
  
    <div className="d-flex align-items-right ">
        <div className="col-12  align-items-center mx-xl-auto  ps-xl-10">
       <div className="card card-raised mb-5" style={{ marginTop:'-50px' }}>
            <div className="card-body p-5"> 
               <div className="card-title">
                Transaction Outgoing Approval Details

                {loader && (
                <div
                  className="loader"
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "10px",
                  }}
                ></div>
              )}{" "} 
              </div>
              <br />
              <div className="mb-4 d-flex align-items-center">
  <label htmlFor="vehicleId" className="me-2">FILTER BY VEHICLE ID:</label>
  <select
    id="vehicleId"
    value={filterVehicleId}
    onChange={(e) => setFilterVehicleId(e.target.value)}
  >
    <option value="">ALL</option>
    {/* Add options dynamically based on available vehicle IDs */}
    {Array.from(new Set(approvals.map(approval => approval.vehicle))).map(vehicleId => (
                  <option key={vehicleId} value={vehicleId}>
                    {vehicleId}
                  </option>
                ))}
  </select>

  <label htmlFor="status" className="ms-6 me-2">FILTER BY STATUS:</label>
  <select
    id="status"
    value={filterStatus}
    onChange={(e) => setFilterStatus(e.target.value)}
  >
    <option value="">ALL</option>
    <option value="APPROVED">APPROVED</option>
    <option value="REQUESTED">REQUESTED</option>
    <option value="REJECTED">REJECTED</option>
  </select>
</div>
            <table className="table table-bordered">
              <thead>
                <tr style={{ fontSize: "17px", backgroundColor: "#6200ea", color: "white", textAlign: "center" }}>
                  <th>Vehicle Id</th>
                  {product.map((ps, index) => (
                    <th key={index}>{ps.productname}</th>
                  ))}
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {currentApprovals.length === 0 ? (
                  <tr>
                    <td colSpan={product.length + 4} className="text-center">
                      NO DATA AVAILABLE
                    </td>
                  </tr>
                ) : (
                  currentApprovals.map((approval) => {
               return (
                 <tr>
                 <th>{approval.vehicle}</th>
                 {product.map((ps, pIndex) => {
            const productQty = approval.outgoing.find(item => item.productname === ps.productname);
            return (
              <th key={pIndex}>
                {productQty ? productQty.quantity : 0}
              </th>
            );
          })}
                {approval.approvalStatus === "REQUESTED" ? (
            <td id="icon" value={approval.approvalstatus}>
              &nbsp;
              {approval.approvalStatus} &nbsp;
              {loading && (
                <div
                  className="loader"
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "10px",
                  }}
                ></div>
              )}{" "}
              <BsCheckCircle
                className="icon"
                id="approval.approvalstatus"
                style={{ backgroundColor: "green" }}
                value="APPROVED"
                onClick={(e) =>
                  rsubmit(
                    e,
                    approval.outgoing.map((out) => out.id),
                    "APPROVED",
                    approval.orgcode,
                    approval.quantity
                  )
                }
              />
              &ensp;
              <BsXCircle
                className="icon"
                value="REJECTED"
                style={{ backgroundColor: "red" }}
                id="approval.approvalstatus"
                onClick={(e) =>
                  rsubmit(
                    e,
                    approval.outgoing.map((out) => out.id),
                    "REJECTED",
                    approval.orgcode,
                    approval.quantity
                  )
                }
              />
            </td>
          ) : (
            <td id="icon" value={approval.approvalstatus}>
              {" "}
              {approval.approvalStatus}
            </td>
          )}

               </tr>
               )
   
                 }))
                 }
         
        
  </tbody>
</table> 
 {/* Pagination controls */}
<nav>
<ul className="pagination justify-content-center">
{pageNumbers.map((number) => (
  <li
    key={number}
    className={`page-item ${number === outgoingCurrentPage ? "active" : ""}`}
  >
   
  </li>
))}
</ul>

<ul className="pagination justify-content-center">
    <li className={`page-item ${outgoingCurrentPage === 1 ? "disabled" : ""}`}>
      <button className="page-link" onClick={handleOutgoingPrevPage}>
        Previous
      </button>
    </li>
    {pageNumbers.map((number) => (
      <li
        key={number}
        className={`page-item ${number === outgoingCurrentPage ? "active" : ""}`}
      >
        <button className="page-link" onClick={() => setOutgoingCurrentPage(number)}>
          {number}
        </button>
      </li>
    ))}
    <li className={`page-item ${outgoingCurrentPage === pageNumbers.length ? "disabled" : ""}`}>
      <button className="page-link" onClick={handleOutgoingNextPage }>
        Next
      </button>
    </li>
  </ul>
  </nav>

   
       
      </div>
            </div>
            </div>
            </div>


);
}

export default TransactionOutgoingApproval;
