import React, { useState, useEffect, useReducer } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import "./Spinner.css";
  
    const AddExemptedcategory= ({ isOpen, onRequestClose }) => {
        const token = JSON.parse(localStorage.getItem("data")).token;

        <div className="card card-raised mb-5"></div>;
        const [loading, setLoading] = useState(false);
      
        const [exemptedcustomercategory1, setexemptedcustomercategory1] = useState({
          categoryname: "",
          orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
          disable:false,
        });
      
        const navigate = useNavigate();
      
        const handleChange = (event, field) => {
          let actualValue = event.target.value;
          setexemptedcustomercategory1({
            ...exemptedcustomercategory1,
            [field]: actualValue,
          });
        };
      
        const handleSubmit = (event) => {
          event.preventDefault();
          setLoading(true);
      
          axios
            .post(
              "/api/exemptedcustomercategories/create",
              exemptedcustomercategory1,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((response) => {
              //    navigate("/users/Deviceusermapping");
              toast.success("Exempted Customer Categories added");
              onRequestClose(); 
              setLoading(false);
              setexemptedcustomercategory1({  // Resetting state to clear previous data
                categoryname: "",
                orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
                disable: false,
              });
            })
            .catch((error) => {
              setLoading(false);
              handleError(error);
            });
        };
       
      
       
        useEffect(() => {
        }, []);
      
      
        function handleError(error) {
          if (error.response && error.response.status === 401) {
            navigate(`/signin`);
            toast.error("Your session has expired. Please login.");
          } else {
            if (error.response && error.response.data && error.response.data.message) {
              toast.error(error.response.data.message);
            } else {
              toast.error('An error occurred.');
            }
          }
        }
       
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Exempted Category Creation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
            <form onSubmit={handleSubmit}>
               
                  <div className="form-floating-sm mb-2">
                    <label for="categoryname ">Category Name </label>
                    <input
                      type="text"
                      className="form-control"
                      id="categoryname"
                      value={exemptedcustomercategory1.categoryname}
                      onChange={(e) => handleChange(e, "categoryname")}
                    />
                  </div>
                 
                 
                  <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}

                />
                 &nbsp;&nbsp;&nbsp;&nbsp;

                <Button variant="secondary" onClick={onRequestClose}>
               Close
               </Button>  

              </form>
                 
            </div>
          </div>
        </div>

      </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
};

export default AddExemptedcategory;