import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import { BiEditAlt } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form';
import "./Spinner.css";
import $ from 'jquery';
import AreamasterdetailsModal from "./Addareamaster";
import { FiInfo } from "react-icons/fi";
import AreaeditModalPopup from "./Updateareamaster";

function Areamaster() {
  
  const [isModalOpen, setIsModalOpen] = useState(false);
   
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const navigate = useNavigate();
 

  

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);

    try {
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const url = `/api/AreaMaster/orgcode=${orgCode}`;

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProduct([...response.data]);
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching data");
    }
    setLoader(false);
  };
  useEffect(() => {
    getData();
  }, [isModalOpen]);
  const onClickhandler = async (e, id) => { 
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(id);
    try{
      await axios({
      url: "/api/AreaMaster",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`,
       
        orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
      },
    });
    toast.success("deleted successfully");
    setLoading(false); 
    getData();
  } catch (error) {
    setLoading(false); 
  }
};
  

  const handleAddProduct = () => {
    setIsModalOpen(true);
    setProduct([]);


  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleEdit = async (Id) => {
    setSelectedProductId(Id);
 
      setIsEditModalOpen(true);
   
  };
  const closeEditModal = () => {
    setIsEditModalOpen(false);
    getData();
 
  };
  const [itemsPerPage, setItemsPerPage] = useState(10); // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);

  // Function to change the current page
  const changePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Function to change the number of items per page
  const changeItemsPerPage = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to the first page when changing items per page
  };

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;

  const Currentproduct = product.slice(firstIndex, lastIndex);

  const totalPages = Math.ceil(product.length / itemsPerPage);


  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />
      
      {loader && <div className="loading"></div>} 
      <div className="col-12  mx-xl-auto ps-xl-10 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title form-group d-flex align-items-center justify-content-between">Area Master Details
             
              <button className="btn btn-primary"onClick={handleAddProduct}>
               ADD
            </button>
            </div>
  
            <br />
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >
                   <th>Area Name</th>

                  <th>Area Code</th>
                  <th>Action</th>


                </tr>
              </thead>
              <tbody>
              {Currentproduct.length === 0 ? (
            <tr>
              <td colSpan="8" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (

            Currentproduct.map((product) => {
                  return (
                    <tr key={product.id}>
                     <td>{product.areaname}</td>

                      <td>{product.areacode}</td>
                      
                      <td> 
                     <button
                      onClick={() => handleEdit(product.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                     <button
                          style={{
                            border: "none",
                            background: "white",
                            color: "grey",
                            position: "relative",
                            left: "20px",
                            fontSize: "30px",
                          }}
                          onClick={(e) => {
                            onClickhandler(
                              e,
                              product.id,
                              product.areaname,
                              product.areacode
                            );
                          }}
                        >
                          <MdDeleteForever />
                        </button>{" "}
                        {/* {loading == vehicle.vehicleid && (
                          <div
                            className="loader"
                            style={{
                              position: "relative",
                              left: "40px",
                              top: "8px",
                            }}
                          ></div>
                        )}{" "} */}
                      
                   </td>
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>
            <div>
           
           <AreamasterdetailsModal isOpen={isModalOpen} onRequestClose={handleCloseModal} />
           {isEditModalOpen && (
              <AreaeditModalPopup
                isOpen={isEditModalOpen}
                onRequestClose={closeEditModal}
                Id={selectedProductId}
              />
            )}
      </div>


         
        <div className="pagination-container">
        {/* Previous arrow */}
        {currentPage > 1 && (
          <button className="btn btn-primary" onClick={() => changePage(currentPage - 1)}>
            Previous
          </button>
        )}

        {/* Current page */}
        <div className="pagination-current">
          &lt;&lt;{currentPage}&gt;&gt;
        </div>

        {/* Next arrow */}
        {currentPage < Math.ceil(product.length / itemsPerPage) && (
          <button className="btn btn-primary" onClick={() => changePage(currentPage + 1)}>
            Next
          </button>
        )}
        <div style={{marginLeft:"5%"}}>
        <label>Rows per page</label>
        <select value={itemsPerPage} onChange={changeItemsPerPage}>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20 </option>
          <option value={25}>25 </option>
          <option value={30}>30 </option>
        </select>
        </div>
        </div>
      </div>
    </div>   
    </div>
        </div>
  );
}

export default Areamaster;
