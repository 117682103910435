import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';  
import axios from "../api/baseurl";

const DaDenominations = ({ isOpen, onRequestClose, denominations }) => {
 
  useEffect(() => {
  
  }, []);

  return (
    <>
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Denominations</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    

      <table className="table table-bordered ">
        <thead>
        <tr>
        <th>Facevalue</th>
        <th>Denomination Number</th>
        <th>Total</th>
                
              </tr>
            </thead>
            <tbody>
            {denominations.map((d, index) => (
            <tr key={index}>
                <td>{d.facevalue}</td>
                    <td>{d.denominationnumber}</td>
                    <td>
                    {d.facevalue * d.denominationnumber}
                    </td>
                   </tr>
            ))}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onRequestClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
</>
      );
    }
export default DaDenominations;
