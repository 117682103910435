import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import "./Spinner.css";
import AdminDashboard from "./admindashboard";
import { Link, useParams, useNavigate } from "react-router-dom";
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}
function Insurance(props) {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  let day = date.getDate();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [insurance, setInsurance] = useState({
    policynumber: "",
    year: "",
    phonenumber: "",
    agent: "",
    startdate: "",
    enddate: "",
    effective: "",
    vehicleid: props.params.truckinfo,
    file: null,
    filesize: "",
  });
  const [loading, setLoading] = useState(false);
  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {
        const [metadata, content] = reader.result.split(",");

        resolve({ metadata, content });
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  const handleFileChange = (e) => {
    const actualvalue = e.target.files[0];
    if (e.target.files) {
      setInsurance({ ...insurance, file: actualvalue });
    }
  };
  const handleUploadClick = (e) => {
    if (!insurance.file) {
      return;
    }
  };

  const navigate = useNavigate();
  function rsubmit(e, ifile) {
    e.preventDefault();
    setLoading(true);

    // if(!sub.status)

    //{

    //setSub(true)

    // } if (pdfFile !== null) {

    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        "/api/Insurance/create",
        insurance,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("insurance updated successfully");
        navigate(`/users/vehicledocuments/${props.params.truckinfo}`);
      })

      .catch((error) => {
        console.log(error);
      });
  }
  // const handleImageChange = async (event) => {
  //   const convertedImage = await getBase64FromFile(event.target.files[0]);

  //   const imageObject = {
  //     ...profile,

  //     imageMeta: convertedImage.metadata,

  //     image: convertedImage.content,
  //   };

  //   setProfile(imageObject);
  // };
  const fileType = ["application/pdf"];
  const handlePdfFileChange = async (e) => {
    const convertedPdf = await getBase64FromFile(e.target.files[0]);
    const pdfObject = {
      ...insurance,

      filesize: convertedPdf.metadata,

      file: convertedPdf.content,
    };

    setInsurance(pdfObject);
  };

  console.log("file2 is", insurance.file);
  function rhandle(e) {
    const newdata = { ...insurance };
    newdata[e.target.id] = e.target.value;
    setInsurance(newdata);
  }
  // const handlePdfFileSubmit = (e) => {
  //   e.preventDefault();

  // };

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="col-8  mx-xl-auto px-xl-5 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title">Insurance Details</div>
            <br />

            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e, insurance.file)}>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="policynumber">policynumber</label>
                        <input
                          className="form-control"
                          id="policynumber"
                          type="text"
                          placeholder="policynumber"
                          value={insurance.policynumber}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="vehicleid">vehicleid</label>
                        <input
                          className="form-control"
                          id="vehicleid"
                          type="text"
                          placeholder="vehicleid"
                          value={`${props.params.truckinfo}`}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="year">year</label>
                        <input
                          className="form-control"
                          id="year"
                          type="text"
                          placeholder="year"
                          value={insurance.year}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="phonenumber">phonenumber</label>
                        <input
                          className="form-control"
                          id="phonenumber"
                          type="text"
                          placeholder="phonenumber"
                          value={insurance.phonenumber}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="agent">agent</label>
                        <input
                          className="form-control"
                          id="agent"
                          type="text"
                          placeholder="agent"
                          value={insurance.agent}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="startdate">startdate</label>
                        <input
                          className="form-control"
                          id="startdate"
                          type="date"
                          placeholder="startdate"
                          value={insurance.startdate}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="agent">enddate</label>
                        <input
                          className="form-control"
                          id="enddate"
                          type="date"
                          placeholder="enddate"
                          value={insurance.enddate}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="effective">effective</label>
                        <input
                          className="form-control"
                          id="effective"
                          type="text"
                          placeholder="effective"
                          value={insurance.effective}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="file">file</label>
                        <input
                          className="form-control"
                          id="file"
                          type="file"
                          placeholder="upload document"
                          // value={insurance.file}
                          onChange={(e) => handlePdfFileChange(e)}
                        />
                      </div>
                    </div>

                    <input
                      type="submit"
                      value="SAVE"
                      disabled={loading}
                      className="btn btn-primary"
                    />
                  </form>
                  {loading && <div className="loading"></div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Insurance);
