import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDeleteForever } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import SettingseditModalPopup from "./Settingsedit";
import AddSettings from "./AddSettings";

 
 
function Settings() {
  const [Deliveryagentdetails, setDeliveryagentdetails] = useState([]);
  const [isProductEditModalOpen, setIsProductEditModalOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddButtonShown, setIsAddButtonShown] = useState(true); // State to track whether the "ADD" button is shown

  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  // const onClickhandler1 = async (e, vid, vusername, vrole) => {
  //   const token = JSON.parse(localStorage.getItem("data")).token;
  //   setLoading(vid);
  //   try {
  //     const vehicles = await axios.delete(`/api/accounts/delete/${vid}`, {
  //       headers: { Authorization: `Bearer ${token}` },
  //       data: {
  //         id: vid,
  //         username: vusername,
  //         role: vrole,
  //         orgid: JSON.parse(localStorage.getItem("data")).orgcode,
  //       },
  //     });
  
  //     toast.success("Deleted successfully");
  //     getData();
  //     setLoading(null);
  //   } catch (error) {
  //     console.error("Error deleting vehicle:", error);
  //     setLoading(null);
  //     toast.error("Error deleting vehicle");
  //   }
  // };

 
  const getData = async () => {
  try {
    const token = JSON.parse(localStorage.getItem("data")).token;
 
    const trans = await axios.get( `/api/Locationtrack`, {
      headers: { Authorization: `Bearer ${token}` },
    });
 
    console.log(trans);
 
    setDeliveryagentdetails(trans.data);
  } catch (error) {
    console.error("Error fetching :", error);
  } 
};
  useEffect(() => {
    getData();
  }, []);
 
 
  const handleProductEdit = (e, id) => {
    setSelectedProductId(id);
    setIsProductEditModalOpen(true);

   
  };
 
  const closeProductEditModal = () => {
    setIsProductEditModalOpen(false);
    getData();
 
  };
  const handleAdd = () => {
    setIsModalOpen(true);
    setIsAddButtonShown(false);

  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };
  
 
  return (
    <div className="attendance-page" style={{ marginTop: "100px" }}>
      <div className="dashboard-container">
        <Dashboard />
      </div>
      <br />
      <br />
      <br />
      <div className="col-12  mx-xl-auto ps-xl-30">
        <div className="card card-raised ">
          <div className="card-body ">
            <div className="card-title">List</div>
            <span style={{ marginRight: '900px' }}></span>
            {isAddButtonShown && (
            <button className="btn btn-primary " onClick={handleAdd}>
              ADD
            </button>
            )}
            <div>
              {Deliveryagentdetails.map((accd) => (
                <div key={accd.id} className="mb-3">
                  {/* <label>User ID:</label>
                  <input
                    type="text"
                    value={accd.userid}
                    readOnly
                    className="form-control"
                  /> */}
                  <label>API Key:</label>
                  <input
                    type="text"
                    value={accd.apikey}
                    readOnly
                    className="form-control"
                  />
                  <label>App Name:</label>
                  <input
                    type="text"
                    value={accd.appname}
                    readOnly
                    className="form-control"
                  />
                  <label> Public URL:</label>
                  <input
                    type="text"
                    value={accd.url}
                    readOnly
                    className="form-control"
                  />
                  <button
                    onClick={(e) => handleProductEdit(e,accd.id)}
                    className="btn btn-primary mt-2"
                  >
                    Edit
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {isProductEditModalOpen && (
        <SettingseditModalPopup
          isOpen={isProductEditModalOpen}
          onRequestClose={closeProductEditModal}
          Id={selectedProductId}
        />
      )}

      <AddSettings isOpen={isModalOpen} onRequestClose={handleCloseModal} />
    </div>
  );
}

export default Settings;