import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const AreamasterdetailsModal = ({ isOpen, onRequestClose }) => {
  const initialProductState = {
    areaname: "",
    areacode: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  };

  const [newProduct, setNewProduct] = useState(initialProductState);
  const [loading, setLoading] = useState(false);

  const url = "/api/AreaMaster/create";
  const navigate = useNavigate();

  const rhandle = (e) => {
    const { id, value } = e.target;
    const newdata = { ...newProduct, [id]: value };
    setNewProduct(newdata);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (!newProduct.areaname.trim() || !newProduct.areacode.trim()) {
    //   toast.error("Area name and area code are required");
    //   return;
    // }

    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.post(url, newProduct, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data);
      setLoading(false);
      toast.success("Area Added successfully");
      onRequestClose();
      setNewProduct(initialProductState);
    } catch (error) {
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else if (error.response && error.response.status === 400) {
      toast.error(error.response.data.code);
    } else {
      toast.error("An error occurred while creating the product");
    }
    setLoading(false);
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add Area</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="areaname">Area Name</label>
                    <input
                      className="form-control"
                      id="areaname"
                      type="text"
                      placeholder="Area Name"
                      value={newProduct.areaname}
                      onChange={(e) => rhandle(e)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="areacode">Area Code</label>
                    <input
                      className="form-control"
                      id="areacode"
                      type="text"
                      placeholder="Area Code"
                      value={newProduct.areacode}
                      onChange={(e) => rhandle(e)}
                    />
                  </div>
                </div>
                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AreamasterdetailsModal;
