import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import { Link, useNavigate } from "react-router-dom";
import "./Spinner.css";
function AddCustomerdetails() {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  let day = date.getDate();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [AddCustomerdetails, setAddCustomerdetails] = useState({
    id: 0,
    orgcode: "",
    date: "",
    customername: "",
    customernumber: 0,
    customertype: "",
    distance:0,
    tariff:0,
    


    orgid: JSON.parse(localStorage.getItem("data")).orgcode,
  });
  const [loading, setLoading] = useState(false);
  const url = "/api/CustomerDetails/create";
  const navigate = useNavigate();
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);

    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        url,
        AddCustomerdetails,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Customer Details added successfully");
        navigate("/users/CustomerDetails");
      })

      .catch((error) => {
        handleError(error);
      });
  }

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  function rhandle(e) {
    const newdata1 = { ...AddCustomerdetails };
    newdata1[e.target.id] = e.target.value;
    setAddCustomerdetails(newdata1);
  }
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="col-8  mx-xl-auto px-xl-5 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title">Customer details</div>
            <br />

            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                    
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="id">Id</label>
                        <input
                          className="form-control"
                          id="id"
                          type="text"
                          placeholder="id"
                          value={AddCustomerdetails.id}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="orgcode">Orgcode</label>
                        <input
                          className="form-control"
                          id="orgcode"
                          type="text"
                          placeholder="orgcode"
                          value={AddCustomerdetails.orgcode}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="date">Date</label>
                        <input
                          className="form-control"
                          id="date"
                          type="text"
                          placeholder="date"
                          value={AddCustomerdetails.date}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="customername">Customer Name</label>
                        <input
                          className="form-control"
                          id="customername"
                          type="text"
                          placeholder="customername"
                          value={AddCustomerdetails.customername}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="customernumber">Customer Number</label>
                        <input
                          className="form-control"
                          id="customernumber"
                          type="text"
                          placeholder="customernumber"
                          value={AddCustomerdetails.customernumber}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="quantity">Customer type</label>
                        <input
                          className="form-control"
                          id="customertype"
                          type="text"
                          placeholder="customertype"
                          value={AddCustomerdetails.customertype}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="dueamount">Distance</label>
                        <input
                          className="form-control"
                          id="distance"
                          type="text"
                          placeholder="distance"
                          value={AddCustomerdetails.distance}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="modeofpaymentid">Tariff</label>
                        <input
                          className="form-control"
                          id="tariff"
                          type="text"
                          placeholder="tariff"
                          value={AddCustomerdetails.tariff}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>




                    <input
                      type="submit"
                      value="SAVE"
                      className="btn btn-primary"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      {loading && <div className="loading"></div>}
    </div>
  );
}

export default AddCustomerdetails;