import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";

import AdminDashboard from "./admindashboard";
import { Link, useNavigate } from "react-router-dom";
import "./Spinner.css";
const ProductdetailsModal = ({ isOpen, onRequestClose }) => {
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;      
  const initialProductState = {
    productname: "",
    productcode: "",
    date: currentDate,
    productstatus: "",
    netWeight: 0,
    pprice: {
      price: 0,
    },
    addPrice: {
      additionalPrice: 0,
    },
    newConPrice: {
      newConPrice: 0,
    },
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  };

  const [newProduct, setNewProduct] = useState(initialProductState);
  const [loading, setLoading] = useState(false);
  const [productTypes, setProductTypes] = useState([]); // State to store product types
  const [selectedProductType, setSelectedProductType] = useState("");

  const url = "/api/product/create";
  const navigate = useNavigate();

  useEffect(() => {
    fetchProductTypes(); // Fetch product types when component mounts
  }, []);

  const fetchProductTypes = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get("/api/producttype", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProductTypes(response.data);
    } catch (error) {
      handleError(error);
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.post(url, newProduct, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data);
      setLoading(false);
      toast.success("Product created successfully");
      onRequestClose(); 
      setNewProduct(initialProductState);
    } catch (error) {
      handleError(error);
    }
  };
  
  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else if (error.response && error.response.status === 400) {
      toast.error(error.response.data.code);
    } else {
      toast.error("An error occurred while creating the product");
    }
    setLoading(false);
  };
  function rhandle(e) {
    const { id, value } = e.target;
    const newdata = { ...newProduct };

    if (id.startsWith("pprice") || id.startsWith("addPrice") || id.startsWith("newConPrice")) {
      const nestedKeys = id.split(".");
      newdata[nestedKeys[0]][nestedKeys[1]] = value;
    } else {
      newdata[id] = value;
      if (id === "productType") {
        setSelectedProductType(value);
        // Reset values of fields based on selected product type
        if (value === "Consumables") {
          newdata.addPrice.additionalPrice = "";
          newdata.newConPrice.newConPrice = "";
          newdata.pprice.price = "";
          newdata.netWeight=0;
        } else if (value === "Refill Sales") {
          newdata.pprice.price = "";
        }
      }
    }
    setNewProduct(newdata);
  };
  

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Create Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
            <form onSubmit={handleSubmit}>
            <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="productType">Product Type</label>
                    <select
                      className="form-control"
                      id="productType"
                      value={newProduct.productType}
                      onChange={(e) => rhandle(e)}
                    >
                      <option value="">Select Product Type</option>
                      {productTypes.map((type) => (
                        <option key={type.id} value={type.productType}>
                          {type.productType}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    
                    <label htmlFor="productname">Product Name</label>
                    <input
                      className="form-control"
                      id="productname"
                      type="text"
                      placeholder="Product Name"
                      value={newProduct.productname}
                      onChange={(e) => rhandle(e)}
                    />
                   
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="productcode">Product Code</label>
                    <input
                      className="form-control"
                      id="productcode"
                      type="text"
                      placeholder="Product Code"
                      value={newProduct.productcode}
                      onChange={(e) => rhandle(e)}
                    />
                  
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="netWeight">Net Weight</label>
                    <input
                      className="form-control"
                      id="netWeight"
                      type="text"
                      placeholder="Net  Weight"
                      value={newProduct.netWeight}
                      onChange={(e) => rhandle(e)}
                      disabled={!newProduct.productType ||selectedProductType !== "Refill Sales"}         

                    />
                    
                  </div>
                </div>

                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="price">Sales Price</label>
                    <input
                      className="form-control"
                      id="pprice.price"
                      type="number"
                      placeholder="0"
                      // value={newProduct.pprice.price}
                      onChange={(e) => rhandle(e)}
                      disabled={!newProduct.productType } // Disable if product type is "Consumable" or "One time sale"
                      />
                  </div>
                </div>
 

                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="additionalprice">Additional Price</label>
                    <input
                      className="form-control"
                      id="addPrice.additionalPrice"
                      type="number"
                      placeholder="0"
                      // value={newProduct.pprice.price}
                      onChange={(e) => rhandle(e)}
                      disabled={!newProduct.productType ||selectedProductType !== "Refill Sales"}         
                                  />
                  </div>
                </div>
                 

                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="newconnection">New Connection Price</label>
                    <input
                      className="form-control"
                      id="newConPrice.newConPrice"
                      type="number"
                      placeholder="0"
                      // value={newProduct.pprice.price}
                      onChange={(e) => rhandle(e)}
                      disabled={!newProduct.productType ||selectedProductType !== "Refill Sales"}         
                    />
                  </div>
                </div>
                 
                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}

                />
                 &nbsp;&nbsp;&nbsp;&nbsp;

                <Button variant="secondary" onClick={onRequestClose}>
               Close
               </Button>  

              </form>
            </div>
          </div>
        </div>

      </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
};

export default ProductdetailsModal;