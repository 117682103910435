import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import { BiEditAlt } from "react-icons/bi";
import "./Spinner.css";
import PriceHistoryModal from "./PriceHistoryModal";
import $ from 'jquery';
import ProductdetailsModal from "./updateproducts";
import ProducteditModalPopup from "./productedit";
import { FiInfo } from "react-icons/fi";
import AdditionalPriceHistoryModal from "./additionalPriceHistoryModal";
import NewConPriceHistoryModal from "./newConPriceHistoryModal";
import ProductPriceUpdate from "./productPriceUpdate";

function Productdetails() {
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [priceHistory, setPriceHistory] = useState([]); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [productEditModalIsOpen, setProductEditModalIsOpen] = useState(false); // corrected variable name
  const [isProductEditModalOpen, setIsProductEditModalOpen] = useState(false);
  const [isProductPriceUpdateModal, setIsProductPriceUpdateModal] = useState(false);
 
  const [selectedProductName, setSelectedProductName] = useState(null);
  const [producteditDetails, setproducteditDetails] = useState([]);
  const [isModalproducteditOpen, setIsModalproducteditOpen] = useState(false);
  const [isPriceHistoryModalOpen, setIsPriceHistoryModalOpen] = useState(false);
  const [isAdditionalPriceHistoryModalOpen, setIsAdditionalPriceHistoryModalOpen] = useState(false);
  const [isNewConPriceHistoryModalOpen, setIsNewConPriceHistoryModalOpen] = useState(false);
  const [additionalPriceHistory, setAdditionalPriceHistory] = useState([]); 
  const [newConPriceHistory, setNewConPriceHistory] = useState([]); 
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
 

  

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);

    try {
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const url = `/api/product/orgcode=${orgCode}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProduct([...response.data]);
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching data");
    }
    setLoader(false);
  };
  useEffect(() => {
    getData();
  }, [isModalOpen]);
 
  const onClickhandler = async (e, id) => {
    setLoading(id);
    const checked = e.target.checked;
    const token = JSON.parse(localStorage.getItem("data")).token;

    try {
      await axios.get(`/api/product/productUpdate/id=${id}/value=${checked}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLoading(null);
      toast.success("Product status updated");
      getData();

    } catch (error) {
      handleError(error);
    }
  };

  const handleProductEdit = async (productId) => {
    setSelectedProductId(productId);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/ProductPrice/productid=${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPriceHistory(response.data);
      setIsProductEditModalOpen(true);
    } catch (error) {
      handleError(error);
    }
  };
 
  const closeProductEditModal = () => {
    setIsProductEditModalOpen(false);
    getData();
 
  };

  const handleProductPriceUpdate = async (productId) => {
    setSelectedProductId(productId);
   setIsProductPriceUpdateModal(true);
  };
 
  const closeProductPriceUpdateModal = () => {
    setIsProductPriceUpdateModal(false);
    getData();
 
  };
 
  const openPriceHistoryModal = async (productId) => {
    setSelectedProductId(productId);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/ProductPrice/productid=${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPriceHistory(response.data);
    } catch (error) {
      handleError(error);
    }
    setIsPriceHistoryModalOpen(true);
  };
 
  const closePriceHistoryModal = () => {
    setSelectedProductId(null);
    setIsPriceHistoryModalOpen(false);
  };

  const openAdditionalPriceHistoryModal = async (productId) => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/additionalprice/productid=${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAdditionalPriceHistory(response.data);
    } catch (error) {
      handleError(error);
    }
    setIsAdditionalPriceHistoryModalOpen(true);
  };
 
  const closeAdditionalPriceHistoryModal = () => {
    setIsAdditionalPriceHistoryModalOpen(false);
  };

  const openNewConPriceHistoryModal = async (productId) => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/newconnectionprice/productid=${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setNewConPriceHistory(response.data);
    } catch (error) {
      handleError(error);
    }
    setIsNewConPriceHistoryModalOpen(true);
  };
 
  const closeNewConPriceHistoryModal = () => {
    setIsNewConPriceHistoryModalOpen(false);
  };

  const handleAddProduct = () => {
    setIsModalOpen(true);
    setProduct([]);


  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />
      
      {loader && <div className="loading"></div>} 
      <div className="col-12  mx-xl-auto ps-xl-10 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title form-group d-flex align-items-center justify-content-between">Product Details
             
              <button className="btn btn-primary"onClick={handleAddProduct}>
               ADD
            </button>
            </div>
  
            <br />
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >
                   <th>Product Type</th>

                  <th>Product Name</th>
                  <th>Product Code</th>
                  <th>Net weight</th>
                  <th>Sales Price</th> 
                  <th>Additional Price</th> 
                  <th>New Connection Price</th> 
                  <th>Edit</th>
                  <th>Status</th>
                  <th>Action</th>

                </tr>
              </thead>
              <tbody>
              {product.length === 0 ? (
            <tr>
              <td colSpan="10" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (

                product.map((product) => {
                  return (
                    <tr key={product.productid}>
                     <td>{product.productType}</td>

                      <td>{product.productname}</td>
                      <td>{product.productcode}</td>
                      <td>{product.netWeight} Kg</td>
                     <td>{product.pprice.price}
                      <span style={{ marginLeft: '40px' }}>
                        <FiInfo onClick={() => openPriceHistoryModal(product.productid)} />
                      </span>
                      </td>
                      <td>{product.addPrice.additionalPrice}
                      <span style={{ marginLeft: '40px' }}>
                        <FiInfo onClick={() => openAdditionalPriceHistoryModal(product.productid)} />
                      </span>
                      </td>
                      <td>{product.newConPrice.newConPrice}
                      <span style={{ marginLeft: '40px' }}>
                        <FiInfo onClick={() => openNewConPriceHistoryModal(product.productid)} />
                      </span>
                      </td>
                      <td> 
                     <button
                      onClick={() => handleProductEdit(product.productid)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                   </td>
                      <td>
                        <div className="form-check form-switch">
                          {loading == product.productid && (
                            <div
                              className="loader"
                              style={{
                                position: "relative",
                                left: "55px",
                                top: "8px",
                              }}
                            ></div>
                          )}{" "}
                          
                          <input
                            className="form-check-input"
                            id="flexSwitchCheckDefault"
                            type="checkbox"
                            defaultChecked={product.productstatus}
                            onClick={(e) => onClickhandler(e, product.productid)}
                            value={product.productid}
                          />
                        </div>
                      </td>
                      <td>
      <button className="btn btn-primary btn-sm" onClick={() => handleProductPriceUpdate(product.productid)}>
        Update Price
      </button>

              </td>
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>
            <div>
            {isPriceHistoryModalOpen && (
              <PriceHistoryModal
                onClose={closePriceHistoryModal}
                initialPriceHistory={priceHistory}
                productId={selectedProductId}
              />
            )}
              {isAdditionalPriceHistoryModalOpen && (
              <AdditionalPriceHistoryModal
                onClose={closeAdditionalPriceHistoryModal}
                additionalPriceHistory={additionalPriceHistory}
              />
            )}
              {isNewConPriceHistoryModalOpen && (
              <NewConPriceHistoryModal
                onClose={closeNewConPriceHistoryModal}
                newConPriceHistory={newConPriceHistory}
              />
            )}
            {isProductEditModalOpen && (
              <ProducteditModalPopup
                isOpen={isProductEditModalOpen}
                onRequestClose={closeProductEditModal}
                productId={selectedProductId}
              />
            )}
           <ProductdetailsModal isOpen={isModalOpen} onRequestClose={handleCloseModal} />

           {isProductPriceUpdateModal && (
              <ProductPriceUpdate
                isOpen={isProductPriceUpdateModal}
                onRequestClose={closeProductPriceUpdateModal}
                productId={selectedProductId}
              />
            )}
      </div>


          </div>
        </div>{" "}
      </div>
    </div>   
  );
}

export default Productdetails;
