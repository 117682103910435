import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { useParams } from "react-router-dom";

import { Modal, Button } from "react-bootstrap";
 
import AdminDashboard from "./admindashboard";
import { Link, useNavigate } from "react-router-dom";
import "./Spinner.css";
const AddFamily = ({ isOpen, onRequestClose }) => {
  const { userid } = useParams();
 
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;      
  const initialProductState = {
    name: "",
    relationship: "",
    phoneNumber: "",
    gender: "",
    dob: "",
    maritalstatus:"",
    userid:userid,
   
   
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  };
 
  const [newProduct, setNewProduct] = useState(initialProductState);
  const [loading, setLoading] = useState(false);
const[gender,setGender]=useState([]);
const[relationship,setRelationship]=useState([]);
const[marital,setMarital]=useState([]);

  const url = "/api/familydetails/create";
  const navigate = useNavigate();
 
  useEffect(() => {
    fetchProductTypes();
  }, []);
 
  const fetchProductTypes = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get("/api/gender", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setGender(response.data);
      const response1 = await axios.get("/api/relationship", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRelationship(response1.data);
      const Response = await axios.get("/api/maritalstatus", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMarital(Response.data);
    } catch (error) {
      handleError(error);
    }
  };
 
 
  const handleSubmit = async (e) => {
    e.preventDefault();
   
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.post(url, newProduct, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data);
      setLoading(false);
      toast.success("Family Details created successfully");  
      onRequestClose();
      setNewProduct(initialProductState);
    } catch (error) {
      handleError(error);
    }
  };
 
 
 
 
  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else if (error.response && error.response.status === 400) {
      toast.error(error.response.data.code);
    } else {
    }
    setLoading(false);
  };
  const rhandle = (e) => {
    const { id, value } = e.target;
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      [id]: value,
    }));
  };
 
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add Family Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
            <form onSubmit={handleSubmit}>
            <div className="row">
                  <div className="form-floating-sm mb-2">
                   
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      id="name"
                      type="text"
                      placeholder="name"
                      value={newProduct.name}
                      onChange={(e) => rhandle(e)}
                    />
                   
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                   
                    <label htmlFor="dob">Date of Birth</label>
                    <input
                      className="form-control"
                      id="dob"
                      type="date"
                      placeholder="dob"
                      value={newProduct.dob}
                      onChange={(e) => rhandle(e)}
                    />
                   
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                   
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input
                      className="form-control"
                      id="phoneNumber"
                      type="number"
                      placeholder="phoneNumber"
                      value={newProduct.phoneNumber}
                      onChange={(e) => rhandle(e)}
                    />
                   
                  </div>
                </div>
            <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="gender">Gender</label>
                    <select
                      className="form-control"
                      id="gender"
                      value={newProduct.gender}
                      onChange={(e) => rhandle(e)}
                    >
                      <option value="">Select Gender</option>
                      {gender.map((type) => (
                        <option key={type.id} value={type.gender}>
                          {type.gender}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="relationship">Relationship Status</label>
                    <select
                      className="form-control"
                      id="relationship"
                      value={newProduct.relationship}
                      onChange={(e) => rhandle(e)}
                    >
                      <option value="">Select relationship</option>
                      {relationship.map((type) => (
                        <option key={type.id} value={type.relationshipStatus}>
                          {type.relationshipStatus}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="maritalstatus">Marital Status</label>
                    <select
                      className="form-control"
                      id="maritalstatus"
                      value={newProduct.maritalstatus}
                      onChange={(e) => rhandle(e)}
                    >
                      <option value="">Select </option>
                      {marital.map((type) => (
                        <option key={type.id} value={type.maritalstatus}>
                          {type.maritalstatus}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                 <br/>
                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}
 
                />
                 &nbsp;&nbsp;&nbsp;&nbsp;
 
                <Button variant="secondary" onClick={onRequestClose}>
               Close
               </Button>  
 
              </form>
            </div>
          </div>
        </div>
 
      </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
};
 
export default AddFamily;