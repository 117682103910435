import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDeleteForever } from "react-icons/md";
import RegisterMd from "./registermandd";
import { Pagination, Form, Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { FaRegUserCircle } from "react-icons/fa";
import { AiFillCheckSquare } from "react-icons/ai";
import { BiDownArrow, BiEditAlt, BiUpArrow } from "react-icons/bi";
import Overheadmodal from "./overheadmodal";
import Overheadeditmodal from "./OverheadEdit";

function OverHeadDetails() {
  const [overHeadDetails, setOverHeadDetails] = useState([]);
  const [loading, setLoading] = useState(null);
  const [isoverheadEditModalOpen, setIsoverheadEditModalOpen] = useState(false);
  const [isoverheadModalOpen, setIsoverheadModalOpen] = useState(false);
  const [selectedoverheadId, setSelectedoverheadId] = useState(null);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState('');

  const navigate = useNavigate();

 
  const getData = async () => {
    setLoading(true);
  try {
    const token = JSON.parse(localStorage.getItem("data")).token;
 
    const trans = await axios.get( `/api/overhead/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode
  }`, {
      headers: { Authorization: `Bearer ${token}` },
    });
 
    console.log(trans);
 
    setOverHeadDetails(trans.data);
    setLoading(false);
  } catch (error) {
    setLoading(false);
    handleError(error);
  } 
};

const handleFilterChange = () => {
  setCurrentPage(1); 
};

const handleAdd = () => {
  setIsoverheadModalOpen(true);


};

const handleoverheadCloseModal = () => {
  setIsoverheadModalOpen(false);
  getData();
};

const handleoverheadedit = async (id) => {
  setSelectedoverheadId(id);
 
  setIsoverheadEditModalOpen(true);
  
};

const closeoverheadEditModal = () => {
  setIsoverheadEditModalOpen(false);
  getData();

};
  useEffect(() => {
    getData();
  }, []);


  
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } 
    else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(9); // Set default items per page to 9\
  let totalItems = overHeadDetails.length;

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getCurrentItems = () => {  
  
    const filteredData = overHeadDetails.filter(oh => {
      const statusFilterPass = selectedStatusFilter === '' ||
        (selectedStatusFilter === 'true' && oh.completed ) ||
        (selectedStatusFilter === 'false' && !oh.completed );
  
      return statusFilterPass;
    });
  
    const indexOfLastItem = currentPage * itemsPerPage;
       const indexOfFirstItem = indexOfLastItem - itemsPerPage;
       return filteredData.slice(indexOfFirstItem, indexOfLastItem);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  function rsubmit(e, id, status) {
    e.preventDefault();
    setLoading(true);
  
    const token = JSON.parse(localStorage.getItem("data")).token;
  
    axios
      .post(
        "/api/overhead/update",
        {
          id: id,
          completed: status,      },
  
        { headers: { Authorization: `Bearer ${token}` } }
      )
  
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        getData();
      })
  
      .catch((error) => {
        handleError(error);
      });
  }
  
 
  return (
    <div>
      <div>
        <Dashboard />
      </div>
      {loading && <div className="loading"></div>}
      <div>
        <div className="col-13 mx-xl-auto ps-xl-10">
          <div className="card card-raised mt-10">
            <div className="card-body">
              <div className="card-title">
                Over Head Details
                <button className="btn btn-primary float-end" onClick={handleAdd}>
                  ADD
                </button>
              </div>
              <br></br>
              <div className="filter-dropdowns">
<div className="row">

  <div
        className="col-4">
  <div controlId="statusFilter">
  <label>FILTER BY STATUS :</label>
                    <select as="select" value={selectedStatusFilter}  onChange={(e) => 
        {setSelectedStatusFilter(e.target.value);
        handleFilterChange(); 
      }}>
                      <option value="">ALL</option>
                      <option value="true">COMPLETED</option>
                      <option value="false">PENDING</option>
                    </select>
   
  </div>
  </div>
  </div>
</div>
<br/>
              <Row>
                {overHeadDetails.length === 0 ? (
                  <div className="text-center">No Data Available</div>
                ) : (
                  getCurrentItems().map((product) => {
                    return (
                    <Col md={4} key={product.id} className="mb-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="form-group d-flex align-items-center justify-content-between">
                            <div>{product.vehicleid}</div>
                            <div>{product.duedate}</div>
                          </div>
                          <hr />
                          <div style={{ fontWeight: "bold" }}>{product.title}</div>
                          <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
            {product.description}
          </div>
                          <br />
                          <div style={{ cursor: "pointer" }} title="Consumer Number">
                            {product.consumerNumber}
                          </div>
                          <div style={{ cursor: "pointer" }} title="Consumer Name">
                            {product.consumerName}
                          </div>
                          <div style={{ cursor: "pointer" }} title="Phone Number">
                            {product.phoneNumber}
                          </div>
                          <div className="form-group d-flex align-items-center justify-content-between mt-2">
                            {product.completed ? (
                              <div style={{ color: "green", fontSize: "16px" }}>Completed</div>
                            ) : (
                              <div id="icon" value={product.completed}>
                                <div>
                                  <AiFillCheckSquare
                                    style={{ color: "green", fontSize: "25px", cursor: "pointer" }}
                                    title="update status"
                                    onClick={(e) => rsubmit(e, product.id, true)}
                                  />
                                </div>
                              </div>
                            )}
                            <button
                              onClick={() => handleoverheadedit(product.id)}
                              style={{
                                border: "none",
                                background: "white",
                                marginLeft: "10px",
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              title="Edit"
                            >
                              <BiEditAlt />
                            </button>
                          </div>
                          <hr />
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ cursor: "pointer" }} title="Created By">
                              {product.userrole}
                            </div>
                            <div style={{ cursor: "pointer" }} title="Product">
                              {product.productname}
                            </div>
                            <div>
                              {product.type === "Stock" ? (
                                <>
                                  <BiUpArrow
                                    style={{ color: "green", fontSize: "20px", cursor: "pointer" }}
                                    title="Taken Quantity"
                                  />{" "}
                                  {product.takenQty}
                                  <BiDownArrow
                                    style={{ color: "red", fontSize: "20px", cursor: "pointer" }}
                                    title="Given Quantity"
                                  />{" "}
                                  {product.givenQty}
                                </>
                              ) : product.type === "Finance" ? (
                                <div style={{ cursor: "pointer" }} title="Pending Amount">
                                  {product.amount} ₹
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    )}))
                    }
              </Row>
            </div>
          </div>
          <br />
          <div className="container">
            <div className="row align-items-center justify-content-center mb-3">
              <div className="col-auto">
                {/* Pagination component */}
                <Pagination>
                  <Pagination.First onClick={() => handlePageChange(1)} />
                  <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  />
                  {Array.from({ length: totalPages }, (_, index) => (
                    <Pagination.Item
                      key={index + 1}
                      active={index + 1 === currentPage}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  />
                  <Pagination.Last onClick={() => handlePageChange(totalPages)} />
                </Pagination>
              </div>
              <div className="col-auto ms-3">
                {/* Items per Page label and select box */}
                <div className="d-flex align-items-center">
                  <Form.Label className="me-2 mb-0" style={{ whiteSpace: "nowrap" }}>
                    Rows Per Page
                  </Form.Label>
                  <Form.Select
                    value={itemsPerPage}
                    onChange={(e) => {
                      setItemsPerPage(Number(e.target.value));
                      setCurrentPage(1); // Reset to first page when items per page changes
                    }}
                  >
                    <option value={5}>9</option>
                    <option value={10}>12</option>
                    <option value={15}>15</option>
                  </Form.Select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isoverheadModalOpen && (
      <Overheadmodal isOpen={isoverheadModalOpen} onRequestClose={handleoverheadCloseModal} />
      )}
      {isoverheadEditModalOpen && (
      <Overheadeditmodal
        isOpen={isoverheadEditModalOpen}
        onRequestClose={closeoverheadEditModal}
        id={selectedoverheadId}
      />
      )}
    </div>
  );
}

export default OverHeadDetails;