import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import { BiEditAlt } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form';
import "./Spinner.css";
import $ from 'jquery';
import AreamasterdetailsModal from "./Addareamaster";
import { FiInfo } from "react-icons/fi";
import AreaeditModalPopup from "./Updateareamaster";
import Search from "./search";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Superleaderboard() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reward, setReward] = useState([]);

  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [searchbydate, setSearchbydate] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  const navigate = useNavigate();

  const date = new Date();
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

    setLoader(true);

    try {
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const url = `/api/rewardpoints/orgcode=${orgcode}/date=${currentDate}`;

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setReward([...response.data]);
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };

  const searchByDate = async (date) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
    setSearchbydate(false);

    setLoading(true);
    try {
      const formattedDate = date.toISOString().split("T")[0];
      const searchResponse = await axios.get(`/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSearchResults(searchResponse.data);
      setLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  const searchByMonth = async (month, year) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
    setSearchbydate(false);

    setLoading(true);
    try {
      const searchResponse = await axios.get(`/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSearchResults(searchResponse.data);
      setLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  const searchByYear = async (year) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
    setSearchbydate(false);

    setLoading(true);
    try {
      const searchResponse = await axios.get(`/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSearchResults(searchResponse.data);
      setLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching data");
    }
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [isModalOpen]);

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />

      {loader && <div className="loading"></div>}
      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              Rewards Points Assignment
              <div style={{ position: "relative", fontSize: "14px", right: "140px" }}>
                {/* <Search searchName={searchName} />{" "} */}
                <DatePicker
                  selected={selectedDate}
                  onChange={date => {
                    setSelectedDate(date);
                    searchByDate(date);
                  }}
                  dateFormat="yyyy-MM-dd"
                />
                                    <span style={{ marginLeft: '5px' }}></span>

                <DatePicker
                  selected={new Date(selectedYear || new Date().getFullYear(), selectedMonth || new Date().getMonth())}
                  onChange={date => {
                    setSelectedYear(date.getFullYear());
                    setSelectedMonth(date.getMonth());
                    searchByMonth(date.getMonth() + 1,date.getFullYear() );
                  }}
                  showMonthYearPicker
                  dateFormat="MM/yyyy"
                />
                                    <span style={{ marginLeft: '5px' }}></span>

                <DatePicker
                  selected={new Date(selectedYear || new Date().getFullYear(), 0)}
                  onChange={date => {
                    setSelectedYear(date.getFullYear());
                    searchByYear(date.getFullYear());
                  }}
                  showYearPicker
                  dateFormat="yyyy"
                />
              </div>
            </div>

            <br />
            <table className="table table-bordered">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >
                  <th>Date</th>
                  <th>Teritory Name</th>
                  <th>Sales Area</th>
                  <th>Points</th>
                </tr>
              </thead>
              <tbody>
                {reward.length === 0 ? (
                  <tr>
                    <td colSpan="8" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  reward.map((product) => {
                    return (
                      <tr key={product.id}>
                        <td>{product.date}</td>
                        <td>{product.vehicle}</td>
                        <td>{product.name}</td>
                        <td>{product.points}</td>
                      </tr>
                    );
                  })
                )}
                {" "}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Superleaderboard;
