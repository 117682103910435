import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';  
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import AdminDashboard from "./admindashboard";
import "@material/mwc-tab-bar/mwc-tab-bar.js";
import "@material/mwc-tab/mwc-tab.js";
import "@material/mwc-textfield/mwc-textfield.js";
import { Link } from "react-router-dom";
import AddFamily from "./AddFamily";
import { FaRegUserCircle } from "react-icons/fa";
import { BiEditAlt } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import FamilyeditModalPopup from "./Updatefamily";
  function Agentprofile(props) {

  const { userid } = useParams();

  const [loader, setLoader] = useState(false);

  const[loading ,setLoading]=useState(false);
  const [password, setPassword] = useState('');
  const [member, setMember] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [userProfileDetails, setUserProfileDetails] = useState(null); // Declare and initialize userProfileDetails
  const [userProfileDetails1, setUserProfileDetails1] = useState(null);
  const [userProfileDetails2, setUserProfileDetails2] = useState(null);

  const [profile, setProfile] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    image: null,
    imageMeta:"",
    country:"",
    company:"",
    email:"",
    gender:"",
    dob:"",
    userid:userid,
    aadhaarnumber:"",
    address: {
      addressName: "",
      address1: "",
      address2: "",
      address3: "",
      addressProof: null,
      imageMeta: "",
    }
  });
  const [profile1, setProfile1] = useState({
    adharNumber: "",
    adharProof: null,
    adharMeta: "",
    panNumber: "",
    panProof:null,
    panMeta:"",
    dlNumber:"",
    dlProof:null,
    dlMeta:"",
    passportNumber:"",
    passportProof:null,
    passportMeta:"",
    passportissuingcountry:"",
    userid:userid,
   

  });
  const [profile2, setProfile2] = useState({
    bankname: "",
    branch: "",
    branchcode: "",
    accountnumber:"",
    ifsccode:"",
    micrcode:"",
    swiftcode:"",
   
    userid:userid,
   

  });
  useEffect(() => {
    setLoading(true);
    
    const fetchProfile = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/account-bio/userid=${userid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const userProfileDetails = response.data;
        setProfile({
          ...profile,
          ...userProfileDetails
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);

        console.error("Error fetching user profile:", error);
      }
    };

    fetchProfile();
    getData();
  }, [userid]);
  const getData = async () => {
    setLoading(true);

    const token = JSON.parse(localStorage.getItem("data")).token;
 
    try {
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
      const url = `/api/familydetails/userid=${userid}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMember(response.data);
      setLoading(false);

      console.log(response.data)
      setLoading(false);
    } catch (error) {
      setLoading(false);

    }
  };
  useEffect(() => {
    setLoading(true);

    const fetchProfile = async () => {
      setLoading(true);

      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/userdocuments/userid=${userid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const userProfileDetails1= response.data;
        setProfile1({
          ...profile1,
          ...userProfileDetails1
        });
        setLoading(false);

      } catch (error) {
        setLoading(false);

        console.error("Error fetching user profile:", error);
      }
    };

    fetchProfile();
    getData();

  }, [userid]);
  useEffect(() => {
    setLoading(true);

    const fetchProfile = async () => {
      setLoading(true);

      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/bankdetails/userid=${userid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const userProfileDetails2= response.data;
        setProfile2({
          ...profile2,
          ...userProfileDetails2
        });
        setLoading(false);

      } catch (error) {
        setLoading(false);

        console.error("Error fetching user profile:", error);
      }
    };

    fetchProfile();
    getData();

  }, [userid]);

  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
       const [metadata, content] = reader.result.split(",");
       resolve({ metadata, content });};
       reader.onerror = function (error) {
       reject(error);};}); }

 const handleImageChange=async(event)=>{
   const convertedImage= await getBase64FromFile(event.target.files[0]);
   const imageObject = {...profile,
    imageMeta: convertedImage.metadata,
         addressProof:convertedImage.content,};
         setProfile(imageObject);
         
         
 }
 const handleImageChange0 = async (event) => {
  const convertedImage = await getBase64FromFile(event.target.files[0]);
  const imageObject = { ...profile, addressProof: convertedImage.content, address: { ...profile.address, imageMeta: convertedImage.metadata } };
  setProfile(imageObject);
}
 const handleImageChange1=async(event)=>{
  const convertedImage= await getBase64FromFile(event.target.files[0]);
  const imageObject = {...profile1,
    adharMeta: convertedImage.metadata,
    adharProof:convertedImage.content,};
        setProfile1(imageObject);
}

const handleImageChange2=async(event)=>{
  const convertedImage= await getBase64FromFile(event.target.files[0]);
  const imageObject = {...profile1,
    panMeta: convertedImage.metadata,
        panProof:convertedImage.content,};
        setProfile1(imageObject);
}

const handleImageChange3=async(event)=>{
  const convertedImage= await getBase64FromFile(event.target.files[0]);
  const imageObject = {...profile1,
    dlMeta: convertedImage.metadata,
        dlProof:convertedImage.content,};
        setProfile1(imageObject);
}
const handleImageChange4=async(event)=>{
  const convertedImage= await getBase64FromFile(event.target.files[0]);
  const imageObject = {...profile1,
    passportMeta: convertedImage.metadata,
    passportProof:convertedImage.content,};
        setProfile1(imageObject);
}
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      await axios.post(`/api/account-bio/update`, profile, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Profile Updated Successfully");
      setLoading(false);

    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Error: Failed to update profile");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit1 = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      await axios.post(`/api/userdocuments/create`, profile1, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Identification Details  Updated Successfully");
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Error: Failed to update profile");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit2 = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      await axios.post(`/api/bankdetails/create`, profile2, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Bank Details  Updated Successfully");
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Error: Failed to update Bank details");

    } finally {
      setLoading(false);
    }
  };

  const handlePasswordSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const token = JSON.parse(localStorage.getItem("data")).token;

      const body = {
        id: userid,
        password: password,
      };

      await axios.post(`/api/accounts/update-password`, body, {
        headers: { Authorization: `Bearer ${token}` },
      });

      toast.success("Password Updated Successfully");
    } catch (error) {
      console.error("Error updating password:", error);
      toast.error("Error: Failed to update password");
    } finally {
      setLoading(false);
    }
  };


  
const handleChange = (event, field) => {
    let actualValue = event.target.value;

    setProfile({ ...profile, [field]: actualValue });
    
  };
  const handleChange1 = (event, field) => {
    let actualValue = event.target.value;

    setProfile1({ ...profile1, [field]: actualValue });
    
  };
  const handleChange2 = (event, field) => {
    let actualValue = event.target.value;

    setProfile2({ ...profile2, [field]: actualValue });
    
  };

  useEffect(() => {
    if (userProfileDetails) {
      setProfile({
        firstname: userProfileDetails.firstname || "",
        lastname: userProfileDetails.lastname || "",
        phone: userProfileDetails.phone || "",
        image: userProfileDetails.image || null,
        imageMeta: userProfileDetails.imageMeta || "",
        country: userProfileDetails.country || "",
        company: userProfileDetails.company || "",
        email: userProfileDetails.email || "",
        gender: userProfileDetails.gender || "",
        dob: userProfileDetails.dob || "",
        aadhaarnumber:userProfileDetails.aadhaarnumber || "",
        address: {
          addressName: userProfileDetails.address?.addressName || "",
          address1: userProfileDetails.address?.address1 || "",
          address2: userProfileDetails.address?.address2 || "",
          address3: userProfileDetails.address?.address3 || "",
          addressProof: userProfileDetails.address?.addressProof || null,
          imageMeta: userProfileDetails.address?.imageMeta || ""
        }
      });
    }
  }, [userProfileDetails]);
  useEffect(() => {
    if (userProfileDetails1) {
      setProfile1({
        adharNumber: userProfileDetails1.adharNumber || "",
        adharProof: userProfileDetails1.adharProof || null,
        adharMeta: userProfileDetails1.adharMeta || "",
        panNumber: userProfileDetails1.panNumber || "",
        panProof: userProfileDetails1.panProof || null,
        panMeta: userProfileDetails1.panMeta || "",
        dlNumber: userProfileDetails1.dlNumber || "",
        dlProof: userProfileDetails1.dlProof || null,
        dlMeta: userProfileDetails1.dlMeta || "",
        passportNumber: userProfileDetails1.passportNumber || "",
        passportProof: userProfileDetails1.passportProof || null,
        passportMeta: userProfileDetails1.passportMeta || "",
        passportissuingcountry: userProfileDetails1.passportissuingcountry ||"",
        // userid: userProfileDetails1.userid || "",
      });
    }
  }, [userProfileDetails1]);
  useEffect(() => {
    if (userProfileDetails2) {
      setProfile2({
        bankname: userProfileDetails2.bankname || "",
        branch: userProfileDetails2.branch || "",
        branchcode: userProfileDetails2.branchcode || "",
        accountnumber: userProfileDetails2.accountnumber || "",
        ifsccode: userProfileDetails2.ifsccode || "",
        micrcode: userProfileDetails2.micrcode || "",
        swiftcode: userProfileDetails2.swiftcode || "",
      });
    }
  }, [userProfileDetails2]);

  // Rest of the component code...
  const handleFilePreview = (fileMeta, fileContent) => {
    if (!fileMeta || !fileContent) return null;
    const fileType = fileMeta.split(":")[1].split(";")[0];
    if (fileType === "application/pdf") {
      return (
        <embed
          src={`${fileMeta},${fileContent}`}
          type="application/pdf"
          width="100%"
          height="500px"
        />
      );
    } else {
      // For images, open the full-size image in a new tab when clicked and provide a download link
      return (
        <>
          <a href={`${fileMeta},${fileContent}`} target="_blank" rel="noopener noreferrer">
            <img
              src={`${fileMeta},${fileContent}`}
              alt="uploaded-file"
              style={{ width: "60px", height: "20px" }}
            />
          </a>
          <br />
          <a href={`${fileMeta},${fileContent}`} download>
            Download
          </a>
        </>
      );
    }
  };
  const handleAddFamily = () => {
    setIsModalOpen(true);
  };
 
  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };

  const handleEdit = async (Id) => {
    setSelectedProductId(Id);
 
      setIsEditModalOpen(true);
   
  };
  const closeEditModal = () => {
    setIsEditModalOpen(false);
    getData();
 
  };
  const onClickhandler = async (e, id) => { 
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(id);
    try{
      await axios({
      url: "/api/familydetails",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`,
       
        orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
      },
    });
    toast.success("deleted successfully");
    setLoading(false); 
    getData();
  } catch (error) {
    setLoading(false); 
  }
};
  return (
    <div>
     
            
     {loading && <div className="loading"></div>} 
    
      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex">
              <div className="card-title">Profile Details</div>{" "}
              <br/>
              <br/>
              <br/>
              <div >
                {profile.image ? (
                  <img
                    className="img-fluid circular-image mb-1"
                    id="image"
                    src={`${profile?.imageMeta},${profile?.image}`}
                    alt="User Profile"
                    style={{
                      width: "150px",
                      height: "150px",
                      display: "block",
                      borderRadius: "50%", // Use 50% border radius for a circular shape
                      background: "#ccc", // Set a background color for the icon
                      // textAlign: "center",
                      lineHeight: "170px",
                      fontSize: "24px",
                      // marginright: "60%",
                      marginTop:"60px",
                    }}
                  />
                ) : (
                  <div
                    className="img-fluid circular-image mb-1"
                    id="image"
                    style={{
                      width: "150px",
                      height: "150px",
                      display: "block",
                      borderRadius: "50%", // Use 50% border radius for a circular shape
                      background: "#ccc", // Set a background color for the icon
                      // textAlign: "center",
                      lineHeight: "170px",
                      fontSize: "24px",
                      // marginright: "60%",
                      marginTop:"60px",

                    }}
                  >
                    {/* <FaRegUserCircle size={250} /> */}
                  </div>
                )}
                <input
                  className="col-7"
                  type="file"
                  id="image"
                  // style={{ marginLeft: "10%" }}
                  onChange={(event) => {
                    console.log(event.target.files[0]);
                    handleImageChange(event);
                  }}
                  accept="image/*"
                />
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row">
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="organization">First Name :</label>
                    <input
                      className="form-control"
                      id="fisrtname"
                      type="text"
                      value={profile.firstname}
                      onChange={(e) => handleChange(e, "firstname")}
                    />
                  </div>
                </div>
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="organization">Last Name :</label>
                    <input
                      className="form-control"
                      id="lastname"
                      type="text"
                      value={profile.lastname}
                      onChange={(e) => handleChange(e, "lastname")}
                    />
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="organization">Phone Number :</label>
                    <input
                      className="form-control"
                      id="phone"
                      type="text"
                      value={profile.phone}
                      onChange={(e) => {
                        const inputText = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                        const limitedText = inputText.slice(0, 10); // Limit to 10 characters
                        setProfile({ ...profile, phone: limitedText }); // Update the state with limited text
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="organization">Email ID :</label>
                    <input
                      className="form-control"
                      id="email"
                      type="email"
                      value={profile.email}
                      onChange={(e) => handleChange(e,"email")}
                    />
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="DOB">Date Of Birth :</label>
                    <input
                      className="form-control"
                      id="dob"
                      type="date"
                      value={profile.dob}
                      onChange={(e) => handleChange(e, "dob")}
                    />
                  </div>
                </div>
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="gender">Gender :</label>
                    <input
                      className="form-control"
                      id="gender"
                      type="text"
                      value={profile.gender}
                      onChange={(e) => handleChange(e, "gender")}
                    />
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-6"> 
   

                  <div className="form-floating-sm mb-2">
                    <label for="addressName">Address</label>
                    <textarea
                      className="form-control"
                      id="addressName"
                      type="text"
                      value={profile.addressName}
                      onChange={(e) => handleChange(e, "addressName")}
                      rows="3"
                    />
                  </div>
                </div>
                {/* <div className="col-md-6"> 
   

                  <div className="form-floating-sm mb-2">
                    <label for="address1">Address</label>
                    <input
                      className="form-control"
                      id="address1"
                      type="text"
                      value={profile.address1}
                      onChange={(e) => handleChange(e, "address1")}
                    />
                  </div>
                </div> */}
                <div className="col-md-6">
  <div className="form-floating-sm mb-2">
    <label htmlFor="addressProof">Upload Address Proof</label>
    {handleFilePreview(profile.imageMeta, profile.addressProof)}

    <input
      className="form-control"
      id="addressProof"
      type="file" 
      onChange={(e) => handleImageChange0(e, "addressProof")} 
      accept="image/jpeg, image/png, application/pdf" 
    />
  </div>
</div>
                
              
                </div>

                      
                
                <br />
                <input
                  type="submit"
                  value="save changes"
                  className="btn btn-primary"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <div >
              <div className="card-title">Identification Details</div>
             <br/>
              <form onSubmit={handleSubmit1}>
               
                <div className="row">
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="adharNumber">Aadhaar Number :</label>
                    <input
                      className="form-control"
                      id="adharNumber"
                      type="text"
                      value={profile1.adharNumber}
                      onChange={(e) => handleChange1(e, "adharNumber")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
  <div className="form-floating-sm mb-2">
    <label htmlFor="adharProof">Upload Aadhaar</label>
    {handleFilePreview(profile1.adharMeta, profile1.adharProof)}

    <input
      className="form-control"
      id="adharProof"
      type="file" 
      onChange={(e) =>handleImageChange1(e, "adharProof")}
      accept="image/jpeg, image/png, application/pdf" 
      />
  </div>
</div>
                </div>

                <div className="row">
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="panNumber">PAN Number :</label>
                    <input
                      className="form-control"
                      id="panNumber"
                      type="text"
                      value={profile1.panNumber}
                      onChange={(e) => handleChange1(e, "panNumber")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
  <div className="form-floating-sm mb-2">
    <label htmlFor="panProof">Upload PAN </label>
    {handleFilePreview(profile1.panMeta, profile1.panProof)}

    <input
      className="form-control"
      id="panProof"
      type="file" 
      onChange={(e) => handleImageChange2(e, "panProof")} 
      accept="image/jpeg, image/png, application/pdf" 
      />
  </div>
</div>
                </div>

                <div className="row">
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="dlNumber">Driving Licence :</label>
                    <input
                      className="form-control"
                      id="dlNumber"
                      type="text"
                      value={profile1.dlNumber}
                      onChange={(e) => handleChange1(e, "dlNumber")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
  <div className="form-floating-sm mb-2">
    <label htmlFor="dlProof">Upload Driving Licence</label>
    {handleFilePreview(profile1.dlMeta, profile1.dlProof)}

    <input
      className="form-control"
      id="dlProof"
      type="file"
      onChange={(e) => handleImageChange3(e, "dlProof")} 
      accept="image/jpeg, image/png, application/pdf" 
      />
  </div>
</div>
                </div>
                <div className="row">
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="passportNumber">Passport Number:</label>
                    <input
                      className="form-control"
                      id="passportNumber"
                      type="text"
                      value={profile1.passportNumber}
                      onChange={(e) => handleChange1(e, "passportNumber")}
                    />
                  </div>
                </div>
                <div className="col-md-6">

<div className="form-floating-sm mb-2">
  <label for="passportissuingcountry">Passport Issuing Country:</label>
  <input
    className="form-control"
    id="passportissuingcountry"
    type="text"
    value={profile1.passportissuingcountry}
    onChange={(e) => handleChange1(e, "passportissuingcountry")}
  />
</div>
</div>
                
                <div className="col-md-6">
  <div className="form-floating-sm mb-2">
    <label htmlFor="passportProof">Upload Passport</label>
    {handleFilePreview(profile1.passportMeta, profile1.passportProof)}

    <input
      className="form-control"
      id="passportProof"
      type="file"
      onChange={(e) => handleImageChange4(e, "passportProof")} 
      accept="image/jpeg, image/png, application/pdf" 
      />
  </div>
</div>
                </div>

                <br />
                <input
                  type="submit"
                  value="save changes"
                  className="btn btn-primary"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <br/>
      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <div >
              <div className="card-title">Bank Details</div>
             <br/>
              <form onSubmit={handleSubmit2}>
               
                <div className="row">
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="bankname">Name of Bank :</label>
                    <input
                      className="form-control"
                      id="bankname"
                      type="text"
                      value={profile2.bankname}
                      onChange={(e) => handleChange2(e, "bankname")}
                    />
                  </div>
                </div>
                <div className="col-md-6">

<div className="form-floating-sm mb-2">
  <label for="branch">Branch :</label>
  <input
    className="form-control"
    id="branch"
    type="text"
    value={profile2.branch}
    onChange={(e) => handleChange2(e, "branch")}
  />
</div>
</div>
                
                </div>

                <div className="row">
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="branchcode">Branch Code :</label>
                    <input
                      className="form-control"
                      id="branchcode"
                      type="text"
                      value={profile2.branchcode}
                      onChange={(e) => handleChange2(e, "branchcode")}
                    />
                  </div>
                </div>
                <div className="col-md-6">

<div className="form-floating-sm mb-2">
  <label for="accountnumber">Account Number :</label>
  <input
    className="form-control"
    id="accountnumber"
    type="text"
    value={profile2.accountnumber}
    onChange={(e) => handleChange2(e, "accountnumber")}
  />
</div>
</div>
                
                </div>
                <div className="row">
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="ifsccode">IFSC Code :</label>
                    <input
                      className="form-control"
                      id="ifsccode"
                      type="text"
                      value={profile2.ifsccode}
                      onChange={(e) => handleChange2(e, "ifsccode")}
                    />
                  </div>
                </div>
                <div className="col-md-6">

<div className="form-floating-sm mb-2">
  <label for="micrcode">MICR Code :</label>
  <input
    className="form-control"
    id="micrcode"
    type="text"
    value={profile2.micrcode}
    onChange={(e) => handleChange2(e, "micrcode")}
  />
</div>
</div>
                
                </div>
                <div className="row">
                <div className="col-md-6">

                  <div className="form-floating-sm mb-2">
                    <label for="swiftcode">SWIFT Code :</label>
                    <input
                      className="form-control"
                      id="swiftcode"
                      type="text"
                      value={profile2.swiftcode}
                      onChange={(e) => handleChange2(e, "swiftcode")}
                    />
                  </div>
                </div>
              
                
                </div>

               

                <br />
                <input
                  type="submit"
                  value="save changes"
                  className="btn btn-primary"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <br/>
     
       {/* Password Update Card */}
       <div className="col-12mx-xl-auto ps-xl-10">
       <div className="card card-raised">
          <div className="card-body">
            <div className="card-title text-center mb-2">Update Password</div>
            <form className="d-flex justify-content-center" onSubmit={handlePasswordSubmit}>
            <div className="col-md-6 mt-2">
              <div className="form-floating-sm mb-2">
                <label htmlFor="currentPassword">New Password :</label>
                <input
                  className="form-control"
                  id="password"
                  type="password"
                  placeholder="Enter new Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="row justify-content-center">
          <div className="col-md-6 text-center mt-4">
            <button type="submit" className="btn btn-primary">Update</button>
          </div>
        </div>
          </form>
          </div>
        </div>
        </div>
 <br/>
 <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <div >
              <div className="card-title">Family details</div>{" "}
              <button className="btn btn-primary float-end"
              onClick={handleAddFamily} >Add</button>
              <br />
              <br/>
              <br />
              <table className="table table-bordered" style={{position:"relative"}}>
                <thead>
                  <tr style={{fontSize:"17px", backgroundColor:"#6200ea", color:"white", textAlign:"center"}}>
                    <th>Name </th>
                    <th>Date of Birth</th>
                    <th>Gender</th>
                    <th>Phone Number</th>
                    <th>Relationship Status</th>
                    <th>Martial Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {member.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
                  member.map((members, index) => {
                    return (
                      <tr key={index}>
                        <td>{members.name}</td>
                        <td>{members.dob}</td>
                        <td>{members.gender}</td>
                        <td>{members.phoneNumber}</td>
                        <td>{members.relationship}</td>
                        <td>{members.maritalstatus}</td>
                        <td> 
                     <button
                      onClick={() => handleEdit(members.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                     <button
                          style={{
                            border: "none",
                            background: "white",
                            color: "grey",
                            position: "relative",
                            left: "20px",
                            fontSize: "30px",
                          }}
                          onClick={(e) => {
                            onClickhandler(
                              e,
                              members.id,
                            );
                          }}
                        >
                          <MdDeleteForever />
                        </button>{" "}
                       
                      
                   </td>
                      </tr>
                    );
                  }))}
                </tbody>
              </table>
              </div>
              <AddFamily isOpen={isModalOpen} onRequestClose={handleCloseModal} />
              {isEditModalOpen && (
              <FamilyeditModalPopup
                isOpen={isEditModalOpen}
                onRequestClose={closeEditModal}
                Id={selectedProductId}
              />
            )}
              </div>
    </div>
    </div>
    </div>
  );
}
 
export default Agentprofile;