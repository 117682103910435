import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import { Link, useNavigate } from "react-router-dom";
import "./Spinner.css";
import CreaterecordModal from "./Createrecord";
import { MdDeleteForever } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import SQCleveleditModalPopup from "./sqcleveledit";
import "./Loader.css";
import {FaSearch } from "react-icons/fa";
import Search from "./search";



function Navigatesqc() {
  const date = new Date();
let day = date.getDate().toString().padStart(2, "0");
let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [product, setProduct] = useState([]);
  const [productsqc, setProductsqc] = useState([]);
  const [hasRecords, setHasRecords] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchbydate, setSearchbydate] = useState([]);

  const [exemptedcustomercategory, setExemptedcustomercategory] = useState([]);
  const [exemptedcustomercategory1, setexemptedcustomercategory1] =useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProductEditModalOpen, setIsProductEditModalOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [associatedDevices, setAssociatedDevices] = useState([]);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const [products, setProducts] = useState([]);

  const [invoiceOptions, setInvoiceOptions] = useState([]);
  const [truckOptions, setTruckOptions] = useState([]);
  const [AddExempteddelivery, setAddExempteddelivery] = useState({
    id:"",
    invoiceNumber: "",
    truckNumber: "",
    date: currentDate,
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    freeze: false,
  });
  const onClickhandler = async (e, id) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(id);
    const vehicles = await axios({
      url: "/api/sqcleveldetails",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`,
       
      },
    }).then((res) => {
      toast.success("deleted successfully");
      fetchData();
      setLoading(null);
    });
  };

  const [loading, setLoading] = useState(false);
  const url = "/api/sqcform/create";
  const navigate = useNavigate();
  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setexemptedcustomercategory1({
      ...exemptedcustomercategory1,
      [field]: actualValue,
    });
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const token = JSON.parse(localStorage.getItem("data")).token;
      
      const url = `/api/sqcleveldetails/orgcode=${AddExempteddelivery.orgcode}/date=${currentDate}`;

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log("Response Data:", response.data);
      if (response.data.length > 0) {
        console.log("SQC ID of the first object:", response.data[0].sqcId);
      }
      setProduct(response.data);
      setLoading(false);
     
        const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
        const url1 = `/api/sqcform/orgcode=${orgCode}/date=${currentDate}`;
        const response1 = await axios.get(url1, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProductsqc(response1.data);
        setHasRecords(response1.data.length > 0);
        
          // Fetch product data
          const loadResponse = await axios.get(
            `/api/LoadinDo/orgcode=${AddExempteddelivery.orgcode}/date=${currentDate}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          console.log("Response from loadResponse API:", loadResponse.data);
          setProducts(loadResponse.data);

          const availableDevices = loadResponse.data.filter(product => {
            return !response1.data.some(vehicle => vehicle?.invoiceNumber == product?.invoiceid);
          });
    
          console.log(availableDevices);
          setAssociatedDevices(response1.data);
    
          setProducts(availableDevices);
        
      
        
      } catch (error) {
        console.error("Error fetching data:", error);
        // handleError(error);
      }
    };
    
        
  
  useEffect(() => {

    fetchData();

  }, [AddExempteddelivery.orgcode]);


function rsubmit(e) {
    e.preventDefault();
    if (!AddExempteddelivery.invoiceNumber || !AddExempteddelivery.truckNumber || !AddExempteddelivery.date) {
      toast.error("Please fill all the required fields.");
      return;
    }
    setLoading(true);
   

    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        url,
        AddExempteddelivery,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Sqc details  added successfully");
        fetchData();
        navigate("/users/addsqcnumber");
        setIsSaveClicked(true);
        setIsSaveClicked(true);

      
      setAddExempteddelivery({
        ...AddExempteddelivery,
        invoiceNumber: "",
        truckNumber: "",
        date: currentDate,
      });
    })

    .catch((error) => {
      console.log(error);
    });
}
  function rhandle(e) {
    const newdata1 = { ...AddExempteddelivery };
    newdata1[e.target.id] = e.target.value;
    setAddExempteddelivery(newdata1);
  }
  const handleDateChange = async (event) => {
    const { value } = event.target;
    setAddExempteddelivery(prevState => ({
      ...prevState,
      date: value
    }));
  
    try {
      setLoading(true);
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
  
      const loadResponse = await axios.get(`/api/LoadinDo/orgcode=${orgCode}/date=${value}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      setProducts(loadResponse.data);
      const url1 = `/api/sqcform/orgcode=${orgCode}/date=${currentDate}`;

      const response1 = await axios.get(url1, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProductsqc(response1.data);

      const availableDevices = loadResponse.data.filter(product => {
        return !response1.data.some(vehicle => vehicle?.invoiceNumber == product?.invoiceid);
      });
      setLoading(false);
      console.log(availableDevices);
      setAssociatedDevices(response1.data);

      setProducts(availableDevices);
    } catch (error) {
      console.error("Error fetching product data:", error);
      // Handle error
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "date") { // Check if the input field is the date field
      // Update the AddExempteddelivery state with the new date value
      setAddExempteddelivery(prevState => ({
        ...prevState,
        date: value,
      }));
    } else if (id === "invoiceNumber") {
      const selectedProduct = products.find(product => product.invoiceid === parseInt(value));
      if (selectedProduct) {
        setAddExempteddelivery(prevState => ({
          ...prevState,
          [id]: value,
          truckNumber: selectedProduct.truckinfo 
        }));
      } else {
        setAddExempteddelivery(prevState => ({
          ...prevState,
          [id]: value,
          truckNumber: "" 
        }));
      }
    } else {
      setAddExempteddelivery(prevState => ({
        ...prevState,
        [id]: id === "invoiceid" ? value : value.trim(),
      }));
    }
  };
  
  
  
  
  
  
  
  
  


  const handlecreaterecord = (date) => {
    setSelectedDate(date);
    setIsModalOpen(true);


  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchData();
  };
  const handleEdit = (productId) => {
    setSelectedProductId(productId);
    setIsProductEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsProductEditModalOpen(false);
    fetchData();
    
  };
  function searchName(text) {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchbydate(false);
    setLoader(true);
    axios
    .get(`/api/sqcleveldetails/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${text}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setProduct(res.data);
        setLoader(false);
      })
      
  }
 
  
  
  
  return (
    <div className="attendance-page" style={{marginTop:'100px'}}>
    <div className="dashboard-container">
        <AdminDashboard />
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div >

      <div className="col-6 mx-xl-auto px-xl-30 "
            style={{ position: 'relative' , right: '240px' }}
            >
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title">Create</div>
            <br />

            <div className="col-xl-10 mx-xl-auto">
              <div>
                <div>
                  <form onSubmit={rsubmit}>
                  <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label htmlFor="date">Date</label>
                        <input
                          className="form-control"
                          id="date"
                          type="date"
                          value={AddExempteddelivery.date}
                          onChange={handleDateChange}
                        />
                      </div>
                    </div>
                  <div className="row mb-2">
  <div className="form-floating-sm">
    <label htmlFor="invoiceNumber">Invoice Number</label>
    <select
      className="form-select"
      id="invoiceNumber"
      value={AddExempteddelivery.invoiceNumber}
      onChange={handleInputChange}
    >
      <option value="">Select Invoice Number </option>
      {products.map((product, index) => (
        <option key={index} value={product.invoiceid }>
          {product.invoiceid}
        </option>
      ))}
    </select>
  </div>
</div>
                    
                 
<div className="row mb-2">
  <div className="form-floating-sm">
    <label htmlFor="truckNumber">Truck Number</label>
    <select
      className="form-select"
      id="truckNumber"
      value={AddExempteddelivery.truckNumber}
      onChange={handleInputChange}
    >
      <option value="">Select Truck Number </option>
      {products.map((product, index) => (
        <option key={index} value={product.truckinfo }>
          {product.truckinfo}
        </option>
      ))}
    </select>
  </div>
</div>
                    
                    <br/>
                    <input
                      type="submit"
                      value="SAVE"
                      className="btn btn-primary"
                    />
                     &nbsp;
             
                     <Link  to="/users/sqcnumber" style={{textDecoration:"none", position: "absolute", left: "190px", top: "280px"}}>
 <button className="btn btn-secondary" type="button">Back</button>
  </Link>
                  </form>
                  
        {/* {hasRecords && ( */}
        { isSaveClicked && (
   <button
   className="btn btn-primary float-end"
  
   style={{  fontSize: '9px',
   padding: '10px 5px',textDecoration: "none", position: "absolute", left: "500px", top: "280px" }}
   onClick={() => handlecreaterecord(AddExempteddelivery.date)} // Pass AddExempteddelivery.date to handlecreaterecord
 >
  Create Record
 </button>
)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <CreaterecordModal isOpen={isModalOpen} onRequestClose={handleCloseModal} />

      </div>{" "}
      
      {loading && <div className="loading"></div>}

      <br />
      <br/>
      <br />
      <div>
      <div >
      <div className="col-15 mx-xl-auto px-xl-30 "
                  // style={{ position: 'relative' , right: '240px' }}
                  >
      <div className="card card-raised">
          <div className="card-body">
        <div className="d-flex">
          <div className="card-title">Create record Details</div>
             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

              <div style={{position: "relative",right:"620px" , display: "flex",bottom:"1px" }}>
        <Search searchName={searchName} />
      </div>
              
        </div>
        <br />
        <div className="table-responsive">
            <table className="table table-bordered horizontal-table" >     
                 <thead>
            <tr
             style={{
              fontSize: "17px",
              backgroundColor: "blue",
              color: "white",
            }}>
              <th>SQC Id</th>
              <th>Product Name</th>
              <th>Product Code</th>
              <th>Serial Number</th>
              <th>Seal Intact</th>
              <th>Date</th>
              <th>Gross weight</th>            
              <th>Tare Weight</th>
              <th>Remarks</th>
              <th>Returned product</th>
              <th>Erv Number</th>
               <th>Erv Date</th>
               <th>Actual Weight in kg</th>
               <th>Variation in grams</th>
               <th>Action</th>




            </tr>
          </thead>
          <tbody>
            { searchbydate && product.length === 0 ? (
              <tr>
                <td colSpan="15" className="text-center">
                  No Data Available
                </td>
              </tr>
            ) : (
              searchbydate &&
              product.map((product) => {
                return (
                  <tr key={product.id}>
                  <td>{product.sqcId}</td>
                    <td>{product.productName}</td>
                    <td>{product.productCode}</td>
                    <td>{product.serialNumber}</td>
                    <td>{product.sealIntact}</td>
                    <td>{product.date}</td>
                    <td>{product.grossWeight}</td>
                    <td>{product.tareWeight}</td>
                    <td>{product.remarks}</td>
                    <td>{product.productReturned}</td>
                    <td>{product.ervNumber}</td>

                    <td>{product.ervDate}</td>
                    <td>{product.actualWeight}</td>

                    <td>{product.difference}</td>

                    <td>
                     
                     <button
                                   disabled={product.freeze}

                      onClick={() => handleEdit(product.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                     
                        <button
                         disabled={product.freeze}

                          style={{
                            border: "none",
                            background: "white",
                            color: "grey",
                            position: "relative",
                            left: "20px",
                            fontSize: "30px",
                          }}
                          onClick={(e) => {
                            onClickhandler(
                              e,
                              product.id
                             
                            );
                          }}
                        >
                          <MdDeleteForever />
                        </button>{" "}
                        {loading == product.id && (
                          <div
                            className="loader"
                            style={{
                              position: "relative",
                              left: "40px",
                              top: "8px",
                            }}
                          ></div>
                        )}{" "}
                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                         <button
              className="btn btn-primary"
              disabled={product.freeze}
              style={{ backgroundColor: product.freeze ? "red" : "green" }}
            >
              Freeze
            </button>
                     

                   </td>
                   
                  </tr>
                );
              })
            )}
             {!searchbydate && product.length === 0 ? (
              <tr>
                <td colSpan="15" className="text-center">
                  No Data Available
                </td>
              </tr>
            ) : (
              !searchbydate &&
              product.map((product) => {
                return (
                  <tr key={product.id}>
                  <td>{product.sqcId}</td>
                    <td>{product.productName}</td>
                    <td>{product.productCode}</td>
                    <td>{product.serialNumber}</td>
                    <td>{product.sealIntact}</td>
                    <td>{product.date}</td>
                    <td>{product.grossWeight}</td>
                    <td>{product.tareWeight}</td>
                    <td>{product.remarks}</td>
                    <td>{product.productReturned}</td>
                    <td>{product.ervNumber}</td>

                    <td>{product.ervDate}</td>
                    <td>{product.actualWeight}</td>

                    <td>{product.difference}</td>

                    <td>
                     
                     <button
                                   disabled={product.freeze}

                      onClick={() => handleEdit(product.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                     
                        <button
                         disabled={product.freeze}

                          style={{
                            border: "none",
                            background: "white",
                            color: "grey",
                            position: "relative",
                            left: "20px",
                            fontSize: "30px",
                          }}
                          onClick={(e) => {
                            onClickhandler(
                              e,
                              product.id
                             
                            );
                          }}
                        >
                          <MdDeleteForever />
                        </button>{" "}
                        {loading == product.id && (
                          <div
                            className="loader"
                            style={{
                              position: "relative",
                              left: "40px",
                              top: "8px",
                            }}
                          ></div>
                        )}{" "}
                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                         <button
              className="btn btn-primary"
              disabled={product.freeze}
              style={{ backgroundColor: product.freeze ? "red" : "green" }}
            >
              Freeze
            </button>
                     

                   </td>
                   
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        </div>
        </div>
        </div>
      </div>
      {isProductEditModalOpen && (
              <SQCleveleditModalPopup
                isOpen={isProductEditModalOpen}
                onRequestClose={closeEditModal}
                productId={selectedProductId}
              />
            )}
</div>

    </div>
    </div>
    </div>
  );
}

export default Navigatesqc;
