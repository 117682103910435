import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Spinner.css";
import { BiEditAlt } from "react-icons/bi";
import { Modal,Button } from "react-bootstrap";
import Vehicledetails from "./vehicleDetails";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let params = useParams();
 
    return <Component {...props} params={params} />;
  }
  return ComponentWithRouterProp;
}
function VehicleeditsModalPopup({ isOpen, onRequestClose , params }) {
 
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [updateVehicle, setUpdateVehicle] = useState({
   
    id:params.id,
    truckinfo: "",
    licenceNo:"",
    deviceid:"",
    orgid: JSON.parse(localStorage.getItem("data")).orgcode,
  });
  console.log(updateVehicle);
  const [loading, setLoading] = useState(false);
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [associatedDevices, setAssociatedDevices] = useState([]);
  const [api1, setapi1] = useState([]);

  const [api, setapi] = useState({});
 
  const navigate = useNavigate();
  const deviceApiUrl = process.env.REACT_APP_CONVOIZ_URL;

 useEffect(() => {
  setLoading(true);
    const fetchData = async () => {
      const token = JSON.parse(localStorage.getItem("data")).token;
      try {
        const response1 = await axios.get(
          `/api/Locationtrack/userid=${JSON.parse(localStorage.getItem("data")).userid}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setapi(response1.data);
        
        const response2 = await axios.get(`/api/vehicle/${params.id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUpdateVehicle(response2.data);

        // Fetch vehicles data
        const vehicleresponse = await axios.get(`/api/vehicle/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setapi1(vehicleresponse.data);
        console.log(vehicleresponse.data);
           // Fetch device data
        console.log("AppName1:", response1.data.appname); 
        console.log("APIKey2:",response1.data.apikey); 

        const deviceResponse = await axios.get(`${deviceApiUrl}/api/devicevehicle/apptoken`, {
          headers: {
            'AppName': `${response1.data.appname}`,
            'APIKey': `${response1.data.apikey}`,
          },
        });
      
      
        const filteredDevices = deviceResponse.data.filter(device => !vehicleresponse.data.some(vehicle => vehicle?.deviceid == device?.deviceid));
        console.log("filteredDevices:", filteredDevices);
        setAssociatedDevices(vehicleresponse.data);   
             console.log(associatedDevices)

        setDeviceOptions(filteredDevices);   
           console.log( "filteredDevices:",filteredDevices.data);

      
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
 
    fetchData();
  }, [params.id]);

  useEffect(() => {


  }, [deviceOptions, associatedDevices]);
 
  const rsubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
 
    axios
      .post("/api/vehicle/update", updateVehicle, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Vehicle updated successfully");
        navigate("/users/vehicledetail");
        onRequestClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };
 

  const rhandle = (event, field) => {
    const actualValue = event.target.value;
    const newValue = field === "deviceid" ? actualValue.trim() : actualValue;
    setUpdateVehicle({ ...updateVehicle, [field]: newValue });
  };

  console.log("Update Vehicle State:", updateVehicle);

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Update Vehicle</Modal.Title>
    </Modal.Header>
    <Modal.Body>
     
 
            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                  {/* <label htmlFor="productname">Id</label>

                    <input
                      class="form-control"
                      id="id"
                      type="text"
                      placeholder="id"
                      value={updateVehicle.id}
                      readOnly
                    /> */}
                    <br />
                    <label htmlFor="productname">Vehicle</label>

                    <input
                      className="form-control"
                      id="truckinfo"
                      type="text"
                      placeholder="truckinfo"
                      value={updateVehicle.truckinfo}
                      onChange={(e) => rhandle(e, "truckinfo")}
                    />
                    <br />
                    {/* <label htmlFor="productname">Licence No</label>

                   
                    <input
                      className="form-control"
                      id="licenceNo"
                      type="text"
                      placeholder="licenceNo"
                      value={updateVehicle.licenceNo}
                      onChange={(e) => rhandle(e, "licenceNo")}
                    /><br /> */}
                     <label htmlFor="deviceid">Device Id</label>

                     <select
                          className="form-select"
                          id="deviceid"
                      type="text"
                      placeholder="deviceid"
                      value={updateVehicle.deviceid}
                      onChange={(e) => rhandle(e, "deviceid")}
                    >
                        <option value="">Select Device</option>
  {deviceOptions.map((device) => (
    <option key={device.deviceid} value={device.deviceid}>
      {device.deviceid}
    </option>
                          ))}
                        </select>
                    <br />
                   
                    <input
                      type="submit"
                      value="Update"
                      className="btn btn-primary"
                    />
                     &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
                  </form>
                </div>
              </div>
            </div>
 
         </Modal.Body>
    <Modal.Footer></Modal.Footer>
  </Modal>
  );
}
 
export default VehicleeditsModalPopup;