import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';  
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import AdminDashboard from "./admindashboard";
import "@material/mwc-tab-bar/mwc-tab-bar.js";
import "@material/mwc-tab/mwc-tab.js";
import "@material/mwc-textfield/mwc-textfield.js";
import { Link } from "react-router-dom";

  function Employment(props) {

  const { userid } = useParams();


  const[loading ,setLoading]=useState(false);
const[type,setType] = useState([]);
  const [userProfileDetails, setUserProfileDetails] = useState(null); // Declare and initialize userProfileDetails
  const [userProfileDetails1, setUserProfileDetails1] = useState(null);
  const [profile, setProfile] = useState({
    dateOfJoining: "",
    dateOfLeaving: "",
    userid: userid,
    employmenttype:"",
    uannumber:"",
    pfaccountnumber:"",
    esinumber:"",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });
 
  useEffect(() => {
    setLoading(true);
    const fetchProfile = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/employmentdetails/userid=${userid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const userProfileDetails = response.data;
        setProfile({
          ...profile,
          ...userProfileDetails
        });
    } catch (error) {
        console.error("Error fetching user profile:", error);
        toast.error("Error: Failed to fetch Employment details");
      } finally {
        setLoading(false);
      }
    };
    fetchProfile();
  }, [userid]);

  useEffect(() => {
    fetch();
  }, []);
 
  const fetch = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get("/api/employmenttype", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setType(response.data);
     
    } catch (error) {
    }
  };

 
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      await axios.post(`/api/employmentdetails/create`, profile, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success(" Updated Successfully");
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Error: Failed to update");
    } finally {
      setLoading(false);
    }
  };

 
  
const handleChange = (event, field) => {
    let actualValue = event.target.value;

    setProfile({ ...profile, [field]: actualValue });
    
  };
 

  useEffect(() => {
    if (userProfileDetails) {
      setProfile({
        dateOfJoining: userProfileDetails.dateOfJoining || "",
        dateOfLeaving: userProfileDetails.dateOfLeaving || "",
        employmenttype: userProfileDetails.employmenttype || "",
        uannumber: userProfileDetails.uannumber || "",

        pfaccountnumber: userProfileDetails.pfaccountnumber || "",

        esinumber: userProfileDetails.esinumber || "",

       
      });
    }
  }, [userProfileDetails]);
 
  
  
  return (
    <div>
     
            
     {loading && <div className="loading"></div>} 
 
      <div className="col-12 mx-xl-auto ps-xl-10">
      <div className="card card-raised mb-3">
        <div className="card-body p-4 ">
            <div >
              <div className="card-title">Employment Details</div>{" "}
              <br/>
              <br/>
              <br/>
             
              <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-4">
                    <label for="employmenttype">Employment Type:</label>
                    <select
                      className="form-control"
                      id="employmenttype"
                      type="text"
                      value={profile.employmenttype}
                      onChange={(e) => handleChange(e, "employmenttype")}
                      >
                      <option value="">Select</option>
                      {type.map((type) => (
                        <option key={type.id} value={type.employmenttype}>
                          {type.employmenttype}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              <div className="row">
                <div className="col-md-4">
                    <label for="dateOfJoining">Date of Joining:</label>
                    <input
                      className="form-control"
                      id="dateOfJoining"
                      type="date"
                      value={profile.dateOfJoining}
                      onChange={(e) => handleChange(e, "dateOfJoining")}
                    />
                  </div>
                </div>
                <div className="row">
                <div className="col-md-4">
                    <label for="dateOfLeaving">Date of Leaving</label>
                    <input
                      className="form-control"
                      id="dateOfLeaving"
                      type="date"
                      value={profile.dateOfLeaving}
                      onChange={(e) => handleChange(e, "dateOfLeaving")}
                    />
                  </div>
                </div>
                <div className="row">
                <div className="col-md-4">
                    <label for="uannumber">UAN Number</label>
                    <input
                      className="form-control"
                      id="uannumber"
                      type="number"
                      value={profile.uannumber}
                      onChange={(e) => handleChange(e, "uannumber")}
                    />
                  </div>
                </div>
                <div className="row">
                <div className="col-md-4">
                    <label for="pfaccountnumber">PF Number</label>
                    <input
                      className="form-control"
                      id="pfaccountnumber"
                      type="number"
                      value={profile.pfaccountnumber}
                      onChange={(e) => handleChange(e, "pfaccountnumber")}
                    />
                  </div>
                </div>
                <div className="row">
                <div className="col-md-4">
                    <label for="esinumber">ESI Number</label>
                    <input
                      className="form-control"
                      id="esinumber"
                      type="number"
                      value={profile.esinumber}
                      onChange={(e) => handleChange(e, "esinumber")}
                    />
                  </div>
                </div>
              
               
               

                      
                
                <br />
                <input
                  type="submit"
                  value="save changes"
                  className="btn btn-primary"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <br />
     
 
 
    
    </div>
  );
}
 
export default Employment;