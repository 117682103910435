import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Spinner.css";

const VehicleAreamap = ({ isOpen, onRequestClose }) => {
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;      

  const initialProductState = {
    vehicleid: "",
    areamas: [{ areaname: "" }],
    date: currentDate,
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  };

  const [newProduct, setNewProduct] = useState(initialProductState);
  const [loading, setLoading] = useState(false);
  const [vehicle, setVehicle] = useState([]);
  const [area, setArea] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);

  const url = "/api/VehicleAreaMapping/create";
  const navigate = useNavigate();

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      setLoading(true);
      const vehicles = await axios.get(
        `/api/vehicle/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setVehicle(vehicles.data);
      const Area = await axios.get(
        `/api/AreaMaster/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setArea(Area.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      console.log("Selected Areas:", selectedAreas); // Log selectedAreas

      // Construct the areamas array with objects containing areaname
      const areamasData = selectedAreas.map(area => ({ areaname: area }));
  
      // Create a copy of the newProduct state
      const updatedProduct = { ...newProduct };
      
      // Update the areamas property of the copied state
      updatedProduct.areamas = areamasData;
      console.log("Updated Product:", updatedProduct);

      // Update the newProduct state
      setNewProduct(updatedProduct);
    
      // Send the request with updated newProduct
      const response = await axios.post(
        url,
        updatedProduct,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      console.log(response.data);
      setLoading(false);
      toast.success("Vehicle Area Mapped successfully");  
      onRequestClose(); 
      setNewProduct(initialProductState);
      setSelectedAreas([]);
    } catch (error) {
      handleError(error);
    }
  };
  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else if (error.response && error.response.status === 400) {
      toast.error(error.response.data.code);
    } else {
      toast.error("An unexpected error occurred");
    }
    setLoading(false);
  };

  const rhandle = (e) => {
    const { id, value, type, checked } = e.target;
    if (type === "checkbox") {
      const area = value;
      setSelectedAreas((prevSelectedAreas) =>
        checked
          ? [...prevSelectedAreas, area]
          : prevSelectedAreas.filter((selectedArea) => selectedArea !== area)
      );
    } else {
      setNewProduct((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    }
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Vehicle Area Mapping</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
              <form onSubmit={handleSubmit}>
              <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="date">Date</label>
                    <input
                      className="form-control"
                      id="date"
                      type="date"
                      placeholder="date"
                      value={newProduct.date}
                      onChange={(e) => rhandle(e)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="vehicleid">Vehicle</label>
                    <select
                      className="form-select"
                      id="vehicleid"
                      type="text"
                      placeholder="vehicleid"
                      value={newProduct.vehicleid}        
                      onChange={(e) => rhandle(e)}
                    >
                      <option>select vehicle</option>
                      {vehicle.map((vehicle) => (
                        <option key={vehicle.truckinfo} value={vehicle.truckinfo}>
                          {vehicle.truckinfo}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label>Area Name</label>
                    {area.map((area) => (
                      <div key={area.areaname} className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={area.areaname}
                          id={area.areaname}
                          onChange={rhandle}
                          checked={selectedAreas.includes(area.areaname)}
                        />
                        <label className="form-check-label" htmlFor={`area-${area.areaname}`}>
                          {area.areaname}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
               
                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VehicleAreamap;
