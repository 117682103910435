import React, { useState, useEffect } from "react";
import "./Loader.css";
import "@material/mwc-tab-bar/mwc-tab-bar.js";

import "@material/mwc-tab/mwc-tab.js";

import axios from "../api/baseurl";

import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Pro() {
  const url = "/api/user/profile";

  const [image, setImage] = useState("");

  const [profile, setProfile] = useState({
    userId: "",

    name: "",

    lastname: "",

    phone: "",

    email: "",

    company: "",

    country: "",

    image: null,

    imageMeta: "",
    firstname: "",
  });
  const [loading, setLoading] = useState(false);
  
  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {
        const [metadata, content] = reader.result.split(",");

        resolve({ metadata, content });
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  const handleImageChange = async (event) => {
    const convertedImage = await getBase64FromFile(event.target.files[0]);

    const imageObject = {
      ...profile,

      imageMeta: convertedImage.metadata,

      image: convertedImage.content,
    };

    setProfile(imageObject);
  };

  const getData = async () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setLoading(false);
    setProfile(data);
  };

  useEffect(() => {
    getData();
  }, []);

  const navigate = useNavigate();

  const handleChange = (event, field) => {
    let actualValue = event.target.value;

    setProfile({ ...profile, [field]: actualValue });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    console.log(profile.image);

    // const token = JSON.parse(localStorage.getItem("data")).token;

    //const convertedImage = await getBase64FromFile(profile.image);

    //console.log(convertedImage);

    console.log(profile);

    const token = JSON.parse(localStorage.getItem("data")).token;

    // const imageObject = {

    //   ...profile,

    //   imageMeta: convertedImage.metadata   ,

    //      image:convertedImage.content,

    // };

    //submit the data to server to generate token

    const res1 = axios

      .post("/api/user/profile/update", profile, {
        headers: { Authorization: `Bearer ${token}` },
      })

      .then((response) => response.data)

      .then((result) => {
        console.log(result);
        setLoading(false);
        localStorage.setItem("profile", JSON.stringify(result));
        toast.success("Profile Updated");

        navigate("/users/adminprofile");
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          toast.error(error.response.data.message);
        } else if (error.response.status === 404) {
          toast.error(error.response.data.message);
        } else {
          toast.error("something went wrong on server !!");
        }
        setLoading(false);
      });
  };

  return (
    <div>
      <div id="layoutDrawer_content">
        <main>
          <header className="bg-dark">
            <div className="container-xl px-5">
              <h1 className="text-white py-3 mb-0 display-6">
                Account Settings - Profile
              </h1>
            </div>
          </header>
          <div className="container-xl p-5 ps-xl-10">
            <div className="row gx-5">
              <div className="col-xl-4">
                <div className="card card-raised mb-5">
                  <div className="card-body p-5">
                    <div className="card-title">Profile Image</div>

                    <div className="card-subtitle mb-4">
                      This image will be publicly visible to other users.
                    </div>

                    <div className="text-center">
                      {profile.image && (
                        <img
                          className="img-fluid rounded-circle mb-1"
                          id="image"
                          src={`${profile?.imageMeta},${profile?.image}`}
                          alt="..."
                          style={{ maxWidth: "150px", maxHeight: "150px" }}
                        />
                      )}

                      <div className="caption fst-italic text-muted mb-4">
                        JPG or PNG no larger than 5 MB
                      </div>

                      <input
                        className="col-7"
                        type="file"
                        id="image"
                        onChange={(event) => {
                          console.log(event.target.files[0]); //setProfile({...profile, image: event.target.files[0] });
                          handleImageChange(event);
                        }}
                        accept="image/*"
                      ></input>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-8 ps-xl-10">
                <div className="card card-raised mb-5">
                  {loading && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "145px",
                        top: "20px",
                      }}
                    ></div>
                  )}
                  <div className="card-body p-5">
                    <div className="card-title">Account Details</div>

                    <div className="card-subtitle mb-4"></div>

                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div class="form-floating-sm mb-2">
                          <label for="Userid">Userid</label>

                          <input
                            class="form-control"
                            id="userId"
                            type="text"
                            placeholder="Userid"
                            value={profile.userId}
                            onChange={(e) => handleChange(e, "userId")}
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div class="form-floating-sm mb-2">
                          <label for="Firstname">Firstname</label>

                          <input
                            class="form-control"
                            id="name"
                            type="text"
                            placeholder="Firstname"
                            value={profile.name}
                            onChange={(e) => handleChange(e, "name")}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-floating-sm mb-2">
                          <label for="Lastname">Lastname</label>

                          <input
                            className="form-control"
                            id="lastname"
                            type="text"
                            placeholder="Lastname"
                            value={profile.lastname}
                            onChange={(e) => handleChange(e, "lastname")}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div class="form-floating-sm mb-2">
                          <label for="email">email</label>

                          <input
                            class="form-control"
                            id="email"
                            type="email"
                            placeholder="Email"
                            value={profile.email}
                            onChange={(e) => handleChange(e, "email")}
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div class="form-floating-sm mb-2">
                          <label for="company">company</label>

                          <input
                            class="form-control"
                            id="company"
                            type="text"
                            placeholder="Company"
                            value={profile.company}
                            onChange={(e) => handleChange(e, "company")}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div class="form-floating-sm mb-2">
                          <label for="country">country</label>

                          <select
            className="form-select"
            id="country"
            value={profile.country}
            onChange={(e) => handleChange(e, "country")}
          >
            <option value="">Select Country</option>
            <option value="USA">India</option>

            <option value="USA">USA</option>
            <option value="Canada">Canada</option>
            <option value="UK">UK</option>
          </select>
                        </div>
                      </div>

                      <div className="row">
                        <div class="form-floating-sm mb-2">
                          <label for="phnor">Phone Number</label>

                          <input
                            className="form-control"
                            id="phone"
                            type="number"
                            placeholder="Phone Number"
                            value={profile.phone}
                            onChange={(e) => handleChange(e, "phone")}
                          />
                        </div>
                      </div>
                      <div className="text-end">
                        <input
                          className="btn btn-primary"
                          type="submit"
                          value="Save Changes"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
export default Pro;
