import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import "./a4shett.css";
import cynefianlogo from "../images/cynefianlogo.png";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let params = useParams();

    return <Component {...props} params={params} />;
  }
  return ComponentWithRouterProp;
}

const convertCurrencyValToNum = (value) => {
  if (!value) return 0;
  return Number(value?.toString()?.replace(/[^0-9.-]+/g, ""));
};

function Addamount(props) {
//   const [amount, setAmount] = useState([]);
//   const [costs, setCost] = useState({});
//   const [order, setOrder] = useState({});
//   const [serialnumber, setSerialnumber] = useState(1);
//   const [username, setUsername] = useState(""); //Declare and initialize the username state
// const [data , setdata]= useState([]);



//   const date = new Date();

//   let day = date.getDate();
//   let month = (date.getMonth() + 1).toString().padStart(2, "0");
//   let year = date.getFullYear();
//   let currentDate = `${day}-${month}-${year}`;
//   const [invoice, setInvoice] = useState(
//     "IN" - currentDate - "xxx" - serialnumber
//   );
//   const navigate = useNavigate();
//   const [parsedData, setParsedData] = useState(null); // Declare and initialize parsedData state
//   const userid = JSON.parse(localStorage.getItem("data"))?.userid;
//   const handlePayment = async (e, totalamount) => {
//     const ta = Number(totalamount.toFixed(2));
//     const orderId = order.orderId;

//     await axios
//       .post("/api/payment/createOrder", { 
//         amount: ta,
//         currency: "INR",
//         orderId: orderId,
//       })
//       .then((response) => {
//         setOrder(response.data);

//         var options = {
//           key: "rzp_test_gZezGE8BQpR73R",
//           key_secret: "ciqzuuE2DJoUj9uE4xA2dABR",
//           amount: (totalamount * 100).toFixed(0),
//           currency: "INR",
//           name: "cynefian",
//           orderId: response.data.orderId,
//           description: "for testing purpose",

//           handler: function (response) {
//             console.log(response, "aaa");

//             var values = {
//               razorpay_payment_id: response.razorpay_payment_id,
//               amount: ta,
//               paymentStatus: response.paymentStatus,
//               currency: "INR",
//               razorpay_order_id: response.razorpay_order_id,
//             };

//             var sub = {
              
//               userid:userid,
//               username: username,
//               orgcode: JSON.parse(localStorage.getItem("RegistrationData"))
//                 .orgcode,
//                 subModelId:props.params.id,
//               payment: {
//                 username: username,
//                 id: response.id,
//                 razorpay_payment_id: response.razorpay_payment_id,
//                 amount: ta,
//                 paymentStatus: response.paymentStatus,
//                 currency: "INR",
//                 razorpay_order_id: response.razorpay_order_id,
//               },
//             };

//             axios.post("/api/payment/processPayment", values).then((res) => {
//               alert("Payment successful");
//             });

//             axios.post("/api/user/subscription/create", sub).then((res) => {
//               alert("Payment successful");
//               navigate(`/invoice/${props.params.id}/${res.data.id}`);
//             });
//           },
//         };

//         const pay = new window.Razorpay(options);
//         pay.open();
//       })
//       .catch((e) => console.log(e));
//   };

//   const getData = async () => {
//     await axios.get(`/api/paymentgatewaytax`).then((response) => {
//       setAmount(response.data);
//     });

//     const id = `${props.params.id}`;
//     await axios.get(`/api/subscription-model/${id}`).then((response) => {
//       setCost(response.data);
//     });
//   };

//   const handleChange = (event) => {
//     const { value } = event.target;
//     setUsername(value);
//   };
//   // const storedData = localStorage.getItem("RegisterationDatat").username;

//   useEffect(() => {
//     // Retrieve username from local storage
   
//     setUsername(JSON.parse(localStorage.getItem("RegistrationData")).username);
//     getData();
//   }, []);



//   return (
//     <div>
//       <div id="layoutDrawer_content">
//         <main>
//           <div className="a4-sheet">
//             <header className="bg-primary">
//               <div className="container-xl px-5">
//                 <h1 class="text-white py-3 mb-0 display-6"></h1>
//               </div>
//             </header>
//             <br />
//             <br /> <br />
//             <form>
//               {amount.map((amount) => {
//                 const totalcost = +`${costs.cost}`;
//                 const gsttax = +`${amount.gsttax}`;
//                 const gatewaytax = +`${amount.gatewaytax}`;
//                 const Taxamount1 = (gsttax / 100) * totalcost + totalcost;
//                 const Taxamount3 = (gsttax / 100) * totalcost;
//                 const Taxamount2 = (gatewaytax / 100) * Taxamount1;
//                 const Tax1 = (gsttax / 100) * Taxamount2;
//                 const TotalTax = Taxamount2 + Tax1;
//                 const cgst = (gsttax / 2 / 100) * totalcost;
//                 const sgst = (gsttax / 2 / 100) * totalcost;

//                 const totalamount = Taxamount1 + TotalTax;
//                 const totaltransactioncost = Tax1 + Taxamount2;
//                 return (
//                   <div>
//                     <div className="d-flex">
//                       <div>
//                         <h6>
//                           <b>Cynefian</b>{" "}
//                         </h6>{" "}
//                         <h6> 1010/27 Mysore Road</h6>{" "}
//                         <h6>Gundlupet , Karnataka</h6>
//                         <h6> PIN 571111 </h6>
//                         <h6>GSTIN- 29AAJCC7045D1ZM </h6>
//                       </div>
//                       <div>
//                         &nbsp;&nbsp;&nbsp;
//                         &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
//                         &nbsp;&nbsp;&nbsp;
//                         &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
//                         &nbsp;&nbsp;&nbsp;
//                         &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
//                         &nbsp;&nbsp;&nbsp;
//                         &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                         &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                         &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                         &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                         &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                         &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                         &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                         &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                         &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                         &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                         &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                         &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                         <img src={cynefianlogo} width="125px" />
//                       </div>
//                     </div>
//                     <br />
//                     <div>
//                       {" "}
//                       <h6> 
//                         <b>To</b>
//                       </h6>
//                       <h6>{`${username}`}</h6>
                      
//                       <h6>{data.addressLine1}</h6>
//                       <h6>GSTIN-{data.gstin}</h6>

                      
//                     </div>
//                     <hr style={{ height: "8px", color: "black" }} />
//                     <div className="d-flex">
//                       {" "}
//                       <h2>Total Amount</h2>{" "}
//                       &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
//                       <h2>{totalamount.toFixed(2)}</h2>
//                     </div>

//                     <hr style={{ height: "8px", backgroundColor: "black" }} />
//                     <div className="d-flex">
//                       <div></div>&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &nbsp;&nbsp;&nbsp;
//                       &nbsp;&nbsp;&nbsp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &nbsp;&nbsp;&nbsp;
//                       &nbsp;&nbsp;&nbsp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &nbsp;&nbsp;&nbsp;
//                       &nbsp;&nbsp;&nbsp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                       &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;
//                       <div>
//                         &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;{" "}
//                         &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;{" "}
//                         &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;{" "}
//                         &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;{" "}
//                         &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;{" "}
//                         &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;{" "}
//                         <div>
//                           {" "}
//                           <h6>Amount: {totalcost}</h6>
//                           <h6>CGST:{cgst.toFixed(2)}</h6>
//                           <h6>SGST:{sgst.toFixed(2)}</h6>
//                           <h6>
//                             Transaction Cost:{totaltransactioncost.toFixed(2)}
//                           </h6>
//                         </div>
//                         <h2 style={{ color: "black" }}>
//                           Total Amount: {totalamount.toFixed(2)}
//                         </h2>
//                       </div>
//                     </div>
//                     <br />
//                     <div className="text-end d-flex">
//                       &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
//                       &nbsp;&nbsp;&nbsp;&ensp;&nbsp; &nbsp;&nbsp;&nbsp;
//                       &nbsp;&nbsp;&nbsp;&ensp;&nbsp; &nbsp;&nbsp;&nbsp;
//                       &nbsp;&nbsp;&nbsp;&ensp;&nbsp; &nbsp;&nbsp;&nbsp;
//                       <button
//                         onClick={(e) => handlePayment(e, totalamount)}
//                         className="btn btn-primary"
//                       >
//                         Proceed
//                       </button>
                     
                      
//                     </div>
//                   </div>
//                 );
//               })}
//             </form>
//           </div>
//         </main>
//       </div>
//     </div>
//   );
}

export default withRouter(Addamount);
